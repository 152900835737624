import React from 'react'
import { shallowEqual, useSelector } from 'react-redux';
import Modals from "views/commons/Modals/Modals"
import { selectors as modalsSelectors } from "state/ducks/client/modals";

const ModalsContainer = (props) => {

  const modals = useSelector(
    (state) => modalsSelectors.getModals(state),
    shallowEqual
  );

  return (
    <Modals modals={modals} />
  )
}

export default ModalsContainer;
