import React from "react";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";

const Menu = (props) => {

  return (
    <Container>
      <div className="hor-scroll">
        <ul className="item-grid">
          <li >
            <NavLink activeClassName="active" to="/compte/devis">
              Commandes en cours
            </NavLink>
          </li>
          <li >
            <NavLink activeClassName="active" to="/compte/factures">
              Factures
            </NavLink >
          </li>
          <li >
            <NavLink activeClassName="active" to="/compte/modification">
              Coordonnées
            </NavLink >
          </li>
        </ul>
      </div>

    </Container>
  );

};

const Container = styled.div`
    background-color:  ${(props) => props.theme.colors.primary};
    box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.10);
    -moz-box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.10);
    -webkit-box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.10);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    .hor-scroll {
      overflow-x: auto;
      @media screen and (max-width: $small + 1){
        overflow-x: scroll;
      }
    }
    .item-grid {
      white-space: nowrap;
    }
    ul{
      text-align: center;
      margin: 0;
      padding: 0;
      list-style: none;
      li{
        display: inline-block;
        margin-left: -4px;
        border-right: solid 1px rgba(255,255,255,0.2);
        &:first-of-type{
          border-left: solid 1px rgba(255,255,255,0.2);
        }
        a{
          display: block;
          padding: 1rem 1.5rem 1rem;
          color: white;
          font-family: '${props => props.theme.fonts.family.main}';
          font-size: ${(props) => props.theme.fonts.size.s};
          text-transform: uppercase;
          font-weight: bold;
          text-align: center;
          text-decoration: none;
          &.active, &:hover, .active{
            color: white;
            background-color:  ${(props) => props.theme.colors.darkGreen};
          }
        }
      }
    }
    .divider{
      border-right: 1px rgba(255,255,255,0.3) solid;
    }
`;

export default Menu;
