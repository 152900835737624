import * as actions from './actions'
import { operations as notificationsOperations } from 'state/ducks/api/notifications';
import { operations as modalsOperations } from 'state/ducks/client/modals';

const sendContact = (fullname, phone, message, email) => ( dispatch ) => {
  return dispatch(actions.postLead({fullname: fullname, phone: phone, message: message, type: 'contact', email: email}))
  .then(succes => {
    // GTM
    window.dataLayer.push({
      event: 'contact',
      name: 'noOffer'
    });
    dispatch(notificationsOperations.setNotificationSuccess("Merci pour votre message, nous vous recontactons très prochainement !"));
    dispatch(modalsOperations.setModalStatusContact(false))
  });
}

export {
  sendContact
};
