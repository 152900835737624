import React from "react";
import { Container, Row, Col, Button } from 'react-bootstrap'
import { CALL_FOOTER } from "config/constants"
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import PropTypes from "prop-types";

// Bandeau composé d'élement marketing
const CallFooter = (props) => {

  const {
    handleContactModal
  } = props

  return (
    <ContainerBtp>
      <Row>
        <Col>
          <Style>
            <h3>{CALL_FOOTER.name}</h3>
            <p>{CALL_FOOTER.sub}</p>
            <Button variant="primary" size="lg" onClick={() => handleContactModal()}>
              <span>Rappelez-moi !</span>
              <div><FontAwesomeIcon icon={faPhone} /> {CALL_FOOTER.button}</div>
            </Button>
          </Style>
        </Col>
      </Row>
    </ContainerBtp>
  );

};

const ContainerBtp = styled(Container)`
  margin: 2rem auto 3.0rem;
  @media (max-width: 767px){
    margin: 0.5rem auto 3.0rem;
  }
`
const Style = styled.div`
  text-align:center;
  h3{
    color: ${props => props.theme.colors.primary};
    font-family: '${props => props.theme.fonts.family.script}';
    font-size: ${props => props.theme.fonts.size.l};
    @media (max-width: 767px){
      font-size: ${props => props.theme.fonts.size.m};
    }
  }
  p{
    color: ${props => props.theme.colors.primary};
    font-size: ${props => props.theme.fonts.size.s};
    @media (max-width: 767px){
      font-size: ${props => props.theme.fonts.size.s};
    }
  }
  button{
    font-size: ${props => props.theme.fonts.size.m};
    padding: 0.5rem 1.5rem;
    @media (max-width: 767px){
      padding: 0.5rem 1.0rem;
      font-size: ${props => props.theme.fonts.size.sm};
    }
    :hover{
      span{
        left: auto;
        right: auto;
        opacity: 0;
        transform: scale(.75);
      }
      div{
        left: 0;
        right: auto;
        opacity: 1;
        transform: scale(1);
      }
    }
    span{
      left: auto;
      right: auto;
      opacity: 1;
      transform: scale(1);
      transition: opacity .3s ease,transform .3s ease;
    }
    div{
      opacity: 0;
      left: 0;
      right: auto;
      transform: scale(1.5);
      transition: opacity .3s ease,transform .3s ease;
      top: 50%;
      margin-top: -1.6rem;
    }
  }
`;

CallFooter.propTypes = {
  handleContactModal: PropTypes.func
};

export default CallFooter;
