import React, {useEffect} from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { operations as notificationOperations, selectors as notificationSelectors } from "state/ducks/api/notifications";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NotificationContainer = (props) => {

  const dispatch = useDispatch();
  const notification = useSelector(
    (state) => notificationSelectors.getNotification(state),
    shallowEqual
  );

  useEffect(() => {
    (notification.toDisplay && notification.error != null && notification.message != null) ?
      toast.error(notification.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      : notification.toDisplay && notification.error != null && !notification.message ?
      toast.error("Nous rencontrons un problème, merci de recharger cette page.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      :
      toast.success(notification.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      dispatch(notificationOperations.clearNotification())
  }, [notification.message, dispatch, notification.error, notification.toDisplay]);

  return (
    <ToastContainer />
  )
}

export default NotificationContainer;
