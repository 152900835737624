import React from "react";
import PropTypes from "prop-types";
import styled from '@emotion/styled'
import MetaInfo from "./Partials/MetaInfo"
import NoCart from "./Partials/NoCart"
import Article from "./Partials/Article"
import Options from "./Partials/Options"
import Silverwares from "./Partials/Silverwares"
import moment from "moment";

// Panier version mini
const MiniCart = (props) => {

  const {
    type,
    cart,
    silverwares,
    handleChangeArticleQuantity,
    handleChangeOption,
    handleChangeSilverwares,
    isLoading,
    swPriceHt
  } = props

  return (
      cart != null ?
        <Cart>
          <InfoEvent> Pour votre évènement du <b>{moment(cart.deliveryInfo.deliveryDate).format('DD-MM-YYYY')}</b> au<br/><b>{cart?.deliveryAddress?.address?.complete ? cart.deliveryAddress.address.complete : null}</b></InfoEvent>
          {cart.options &&
            <Options options={cart.options} handleChangeOption={handleChangeOption} type={type}/>
          }
          {(silverwares && silverwares.length > 0) &&
            <Silverwares silverwares={silverwares} handleChange={handleChangeSilverwares} type={type} isDisable={isLoading} />
          }
          <MaxHeight>
            {cart.productRanges.map((productRange, index) =>
              <div key={index}>
                <ArticleRange>{productRange.name}</ArticleRange>
                <Articles>
                  {productRange.articles && productRange.articles.map((article, index) =>
                    <Article
                      range={productRange}
                      handleChangeArticleQuantity={handleChangeArticleQuantity}
                      article={article}
                      key={index}
                      isDisable={isLoading}
                      />
                  )}
                </Articles>
              </div>
            )}
          </MaxHeight>

          {swPriceHt > 0 &&
            <MetaInfo text={"Couverts"} price={swPriceHt} />
          }
          <MetaInfo text={"Livraison"} price={cart.deliveryInfo.deliveryCostHt} />
          <MetaInfo text={"TOTAL"} price={cart.priceInfo.totalPriceHt} priceTtc={cart.priceInfo.totalPriceTtc} big/>

        </Cart>
        :
        <NoCart />

  );

};

const Cart = styled.div`
  background-color: white;
  border-radius: 6px;
  height: 100%;
`
const MaxHeight = styled.div`

`

const InfoEvent = styled.div`
  background-color: ${props => props.theme.colors.primary};
  text-align: center;
  padding: 0.5rem 0.5rem;
  border-radius: 6px 6px 0 0;
  color:  white;
  font-size: ${props => props.theme.fonts.size.xs};
`;

const ArticleRange = styled.div`
  border-top: 1px #ddd solid;
  font-weight: bold;
  padding: 0.3rem 0.5rem;
  color: ${props => props.theme.colors.inherit};
  font-family: '${props => props.theme.fonts.family.main}';
  text-align: center;
  background-color: white;
`

const Articles = styled.div`
  border-top: 1px #ddd solid;
  padding-right: 0.5rem;
  background-color: #ececec;
`

MiniCart.propTypes = {
  isOpen: PropTypes.bool,
  handleStateCartChange: PropTypes.func,
  cart : PropTypes.object,
  silverwares: PropTypes.array,
  handleChangeArticleQuantity: PropTypes.func,
  handleChangeOption: PropTypes.func,
  handleChangeSilverwares: PropTypes.func,
  isLoading: PropTypes.bool,
  swPriceHt: PropTypes.number
};

export default MiniCart;
