let cartModel = {};
cartModel.customerInfo = {
  lastName       : null,
  firstName      : null,
  phone          : null,
  company        : null,
  fullAddress    : null,
  moreInformation: null
};
cartModel.deliveryInfo = {
  deliveryDate   : null,
  lastName       : null,
  firstName      : null,
  phone          : null,
  company        : null,
  fullAddress    : null,
  moreInformation: null,
  lat            : null,
  lng            : null
};
cartModel.billingInfo = {
  lastName       : null,
  firstName      : null,
  phone          : null,
  company        : null,
  fullAddress    : null,
  moreInformation: null
};
cartModel.productRanges = [];

export default cartModel;
