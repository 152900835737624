import React from "react";
import HeaderContainer from "./Header/HeaderContainer"
import FooterContainer from "./Footer/FooterContainer"
import CartContainer from "views/commons/Cart/CartContainer"
import PropTypes from "prop-types";
import ModalsContainer from "views/commons/Modals/ModalsContainer"
import SliderPartners from "views/commons/Blocks/SliderPartners"
import Review from "views/commons/Blocks/Review"
import NotificationContainer from "views/commons/Notification/NotificationContainer"
import CallFooterContainer from "views/commons/Blocks/CallFooter/CallFooterContainer"
import { selectors as customerSelectors } from "state/ducks/api/customer";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { operations as modalsOperations } from 'state/ducks/client/modals';
import styled from "@emotion/styled";

// Structure contenant un header et un footer à qui on passe les données en constante
const Layout = (props) => {

  const dispatch = useDispatch();

  const {
    cart
  } = props

  // On récupere le customer pour voir si revendeur et affiche message global
  const customer = useSelector(
    (state) => customerSelectors.getCustomer(state),
    shallowEqual
  );

  return (
    <div>
      <CartContainer cart={cart}/>
      {customer && customer.distributor &&
        <div className={"alert-danger text-center p-3 h6 mb-0"}>
          <p className="mb-0">
            <b>Compte revendeur</b>, votre <b>remise de -10%</b> sur les Supercookies et les <b>frais de livraison offerts</b> à partir de 300 € HT s'appliqueront automatiquement <b>après votre commande</b>.
          </p>
        </div>
      }
      {/* Temporaire pour offre cocktail */}
      {/* <Banner className={"alert-info custom text-center p-3 h6 mb-0"}>
        <p className="mb-0">
          À commander dès aujourd'hui : notre offre "cocktail festif". (disponible du 1er décembre au 31 janvier). 
          <span onClick={() => dispatch(modalsOperations.setModalStatusContact(true, "Un cocktail ? Quelle bonne idée !", "Sophie et Julie vous recontactent pour l'organisation de votre événement."))}>
              <u>En savoir plus</u>
          </span>
        </p>
      </Banner> */}
      <HeaderContainer />
      <main id="page-wrap">{props.children}</main>
      <CallFooterContainer />
      <SliderPartners />
      <Review />
      <FooterContainer />
      <ModalsContainer />
      <NotificationContainer />
    </div>
  );
};

const Banner = styled.div`
  background-color: #EFD5B2;
  color: black;
  span{
    cursor: pointer;
  }
`;

Layout.propTypes = {
  cart: PropTypes.object
};

export default Layout;
