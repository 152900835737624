import React from "react";
import Login from "./Login"
import { selectors as customerSelectors } from "state/ducks/api/customer";
import { useSelector, shallowEqual } from "react-redux";
import { Redirect } from "react-router-dom"

const LoginContainer = (props) => {

  const isAuthenticated = useSelector(
    (state) => customerSelectors.isAuthenticated(state),
    shallowEqual
  );

  if ( isAuthenticated ) {
    return <Redirect to="/compte/devis" />
  }

  return (
    <Login />
  );
};

export default LoginContainer;
