import * as actionTypes from './types'
import { createReducer } from '../../../utils'

const initialState = {
  isLoading: false,
  lastLead: {}
};

const reducers = createReducer( initialState )( {

  [actionTypes.POST_LEAD]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.POST_LEAD_COMPLETED]: (state, action) => {
    return {
      ...state,
      isLoading: false,
      lastLead: action.payload.result,
    };
  },
  [actionTypes.POST_LEAD_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },

});

export default reducers;
