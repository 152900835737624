import React from 'react'
import PropTypes from 'prop-types'
import Button from "styles/Button"
import styled from '@emotion/styled'

const ClearCart = (props) => {

  const {
    handleClearCart,
    handleRefuseClearCart
  } = props

  return (
    <div className="text-center">
      <Title>Désolé, nos différentes gammes ne peuvent pas être prises ensemble.<br/><br/>Une bonne occasion de repasser commande pour tester toutes nos gammes</Title>

      <Button onClick={handleClearCart} padding="0.5rem 2.0rem;" margin="1.0rem 0 0 0" type="submit">Démarrer une nouvelle commande</Button>

      <Button color="#111" onClick={handleRefuseClearCart} padding="0.5rem 2.0rem;" margin="1.0rem 0 0 0"type="submit">Garder mon panier</Button>

    </div>
  )
}

const Title = styled.h3`
  text-align: center;
`;

ClearCart.propTypes = {
  handleClearCart: PropTypes.func,
  handleRefuseClearCart: PropTypes.func
};

export default ClearCart;
