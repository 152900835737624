import React from "react";
import { Container, Row, Col } from 'react-bootstrap'
import styled from "@emotion/styled";
import star from "assets/images/reassurance/star.svg"
import esus from "assets/images/labels/label-esus.png"
import equitable from "assets/images/labels/label-ville-lyon-economie-durable.png"
import ab50 from "assets/images/labels/AB50.png"

// Bandeau composé d'élement marketing
const Reassurance = (props) => {

  return (
    <ContainerBtp>
      <Row className="align-items-center">

      <Col>
        <Row noGutters className="align-items-center">
          <Col className={"text-center"}>
            <a href="https://lemoulin.fr/nos-valeurs/" target="_blank" rel="noreferrer">
              <img src={ab50} alt={"Certifié AB50%"} className={"mb-1"}/><br/>
              <Name>Certifié AB50%</Name>
            </a>
          </Col>
        </Row>
      </Col>

        <Col>
          <Row noGutters className="align-items-center">
            <Col className={"text-center"}>
              <a href="https://lemoulin.fr/nos-valeurs/" target="_blank" rel="noreferrer">
                <img src={esus} alt={"ECO-RESPONSABLE"} className={"mb-1"}/><br/>
                <Name>Eco-responsable</Name>
              </a>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row noGutters className="align-items-center">
            <Col className={"text-center"}>
              <a href="https://lemoulin.fr/nos-valeurs/" target="_blank" rel="noreferrer">
                <img src={equitable} alt={"Equipe lyonnaise engagée"} className={"mb-1"}/><br/>
                <Name>Equipe lyonnaise engagée</Name>
              </a>
            </Col>
          </Row>
        </Col>

        <Col>
          <Row noGutters className="align-items-center">
            <Col className={"text-center"}>
              <a href="https://www.google.com/maps/place/Le+Moulin+Traiteur/@45.7754602,4.8003949,17z/data=!3m2!4b1!5s0x47f4eb6e3062ea75:0x383028a3a5ad30f6!4m5!3m4!1s0x47f4ebc691792225:0xa1d4a49c6cf9bb29!8m2!3d45.7754565!4d4.8025889" target="_blank" rel="noreferrer">
                <img src={star} alt={"Vous parlez de nous"} className={"mb-1 min"}/><br/>
                <Name>Vous parlez de nous</Name>
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </ContainerBtp>
  );

};

const ContainerBtp = styled(Container)`
  margin: 1rem auto;
  @media (max-width: 767px){
    margin: 0.5rem auto;
  }
  img{
    max-width: 80px;
    height: 60px;
    &.min{
      max-width: 50px;
    }
  }
  a{
    color: black;
    img{
      transition: 300ms;
    }
    :hover{
      img{
        scale: 1.05;
      }
    }
  }
`
const Name = styled.div`
  font-weight: bold;
  margin-top: 0.5rem;
  font-family: '${props => props.theme.fonts.family.main}';
  font-size: ${props => props.theme.fonts.size.sm};
  @media (max-width: 767px){
    font-size: ${props => props.theme.fonts.size.xxs};
  }
`;

export default Reassurance;
