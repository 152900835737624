import * as types from './types'
import { createReducer } from '../../../utils'

/* State shape
{
  connexion: [ boolean ],
  contact: [ boolean ],
  deliveryInput: [ object ],
  clearCart: [ boolean ],
  minAmount: [ object ],
  dateChangeProduct: [ boolean ],
}
*/
const initialState = {
  connexion: false,
  contact: {isOpen: false, title: null, text: null},
  deliveryInput: {isOpen: false, text: null},
  clearCart: false,
  minAmount: {isOpen: false, text: null},
  dateChangeProduct: false,
  generic: {isOpen: false, text: null, title: null}
};

const modalsReducer = createReducer( initialState )( {
    [ types.SET_MODAL_STATUS_CONNEXION ]: ( state, action ) => {
      const { isOpen } = action.payload;
      return { ...state, connexion: isOpen }
    },
    [ types.SET_MODAL_STATUS_CONTACT ]: ( state, action ) => {
      const {isOpen, title, description} = action.payload;
      return { ...state, contact: {isOpen, title, description} }
    },
    [ types.SET_MODAL_STATUS_DELIVERY_INPUT ]: ( state, action ) => {
      const { isOpen, text } = action.payload;
      return { ...state, deliveryInput: {isOpen: isOpen, text: text} }
    },
    [ types.SET_MODAL_STATUS_MIN_AMOUNT ]: ( state, action ) => {
      const { isOpen, text } = action.payload;
      return { ...state, minAmount: {isOpen: isOpen, text: text} }
    },
    [ types.SET_MODAL_STATUS_CLEAR_CART ]: ( state, action ) => {
      const { isOpen} = action.payload;
      return { ...state, clearCart: isOpen }
    },
    [ types.SET_MODAL_STATUS_DATE_CHANGE_PRODUCT ]: ( state, action ) => {
      const { isOpen} = action.payload;
      return { ...state, dateChangeProduct: isOpen }
    },
    [ types.SET_MODAL_STATUS_GENERIC ]: ( state, action ) => {
      const { isOpen, description, title } = action.payload;
      return { ...state, generic: {isOpen, description, title} }
    },
} );

export default modalsReducer;
