import React, {useState} from "react";
import { HEADER } from "config/constants"
import HeaderDesktop from "./HeaderDesktop"
import HeaderMobile from "./HeaderMobile"
import { operations as modalsOperations } from 'state/ducks/client/modals'
import { selectors as customerSelectors, operations as customerOperations } from 'state/ducks/api/customer'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useWindowDimensions } from 'utils/WindowDimensionsProvider'

const HeaderContainer = (props) => {

  const dispatch = useDispatch();

  const { width } = useWindowDimensions();

  const [isOpenMenuMobile, setIsOpenMenuMobile] = useState(false);

  const isAuthenticated = useSelector(
    (state) => customerSelectors.isAuthenticated(state),
    shallowEqual
  );

  const handleStateMenuMobile = (state) => {
    setIsOpenMenuMobile(state.isOpen)
  }

  const handleOpenMenuMobile = () => {
    setIsOpenMenuMobile(!isOpenMenuMobile)
  }

  const handleLoginModal = () => {
    dispatch(modalsOperations.setModalStatusConnexion(true))
  }

  const handleContactModal = (title, description) => {
    dispatch(modalsOperations.setModalStatusContact(true, title, description))
  }

  const handleLogout = () => {
    dispatch(customerOperations.logout())
  }

  return (
    width > 1050 ?
      <HeaderDesktop
        data={HEADER}
        handleLoginModal={handleLoginModal}
        handleContactModal={handleContactModal}
        isAuthenticated={isAuthenticated}
        handleLogout={handleLogout}
      />
      :
      <HeaderMobile
        data={HEADER}
        handleLoginModal={handleLoginModal}
        handleContactModal={handleContactModal}
        isOpenMenuMobile={isOpenMenuMobile}
        handleOpenMenuMobile={handleOpenMenuMobile}
        handleStateMenuMobile={handleStateMenuMobile}
        isAuthenticated={isAuthenticated}
        handleLogout={handleLogout}
      />
  );
};

export default HeaderContainer;
