import React from 'react'
import {Button as Btn} from 'react-bootstrap'
import styled from '@emotion/styled'
import PropTypes from "prop-types";
import Tippy from '@tippyjs/react';
import Loader from "react-loader-spinner";

const Validate = (props) => {

  const {
    handleValideCart,
    text,
    isOpen,
    isLoading,
    closeModalMinAmount
  } = props

  return (
    <Tippy
      content={
        <>
          <p className="mb-1">{text}</p>
        </>
      }
      visible={isOpen}
      interactive={true}
      placement="top"
      className="tippy-error"
      onClickOutside={(instance, event) => closeModalMinAmount()}
    >
      <Button onClick={handleValideCart} disabled={isLoading}>
        {isLoading ?
          <Loader
            type="ThreeDots"
            color="#ffffff"
            height={20}
            width={20}
          />
        :
          "Etape suivante"
        }

      </Button>
    </Tippy>
  )
}

const Button = styled(Btn)`
  text-transform: uppercase;
  background-color: ${props => props.theme.colors.favorite};
  font-size: ${props => props.theme.fonts.size.xs};
  width: 100%;
  border: none;
  font-family: '${props => props.theme.fonts.family.main}';
  font-size: ${props => props.theme.fonts.size.xs};
  font-weight: bold;
  padding: 1rem 0.5rem;
  span{
    color: ${props => props.theme.colors.black}
  }
  :hover, :active, :focus{
    border-color: ${props => props.theme.colors.darkfavorite} !important;
    background-color: ${props => props.theme.colors.darkfavorite} !important;
  }
`;

Validate.propTypes = {
  handleValideCart: PropTypes.func,
  text: PropTypes.string,
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  closeModalMinAmount: PropTypes.func
};

export default Validate;
