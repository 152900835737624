export const LOGIN = 'api/LOGIN';
export const LOGIN_COMPLETED = 'api/LOGIN_COMPLETED';
export const LOGIN_FAILED = 'api/LOGIN_FAILED';

export const LOGOUT = 'api/LOGOUT';

export const POST_CUSTOMER = 'api/POST_CUSTOMER';
export const POST_CUSTOMER_COMPLETED = 'api/POST_CUSTOMER_COMPLETED';
export const POST_CUSTOMER_FAILED = 'api/POST_CUSTOMER_FAILED';

export const FORGOT_PASSWORD = 'api/FORGOT_PASSWORD';
export const FORGOT_PASSWORD_COMPLETED = 'api/FORGOT_PASSWORD_COMPLETED';
export const FORGOT_PASSWORD_FAILED = 'api/FORGOT_PASSWORD_FAILED';

export const UPDATE_PASSWORD = 'api/UPDATE_PASSWORD';
export const UPDATE_PASSWORD_COMPLETED = 'api/UPDATE_PASSWORD_COMPLETED';
export const UPDATE_PASSWORD_FAILED = 'api/UPDATE_PASSWORD_FAILED';

export const RESET_PASSWORD = 'api/RESET_PASSWORD';
export const RESET_PASSWORD_COMPLETED = 'api/RESET_PASSWORD_COMPLETED';
export const RESET_PASSWORD_FAILED = 'api/RESET_PASSWORD_FAILED';

export const ME = 'api/ME';
export const ME_COMPLETED = 'api/ME_COMPLETED';
export const ME_FAILED = 'api/ME_FAILED';

export const ME_UPDATE = 'api/ME_UPDATE';
export const ME_UPDATE_COMPLETED = 'api/ME_UPDATE_COMPLETED';
export const ME_UPDATE_FAILED = 'api/ME_UPDATE_FAILED';

export const GET_ORDERS = 'api/GET_ORDERS';
export const GET_ORDERS_COMPLETED = 'api/GET_ORDERS_COMPLETED';
export const GET_ORDERS_FAILED = 'api/GET_ORDERS_FAILED';

export const PUT_ORDER = 'api/PUT_ORDER';
export const PUT_ORDER_COMPLETED = 'api/PUT_ORDER_COMPLETED';
export const PUT_ORDER_FAILED = 'api/PUT_ORDER_FAILED';

export const GET_ORDER_BY_TOKEN = 'api/GET_ORDER_BY_TOKEN';
export const GET_ORDER_BY_TOKEN_COMPLETED = 'api/GET_ORDER_BY_TOKEN_COMPLETED';
export const GET_ORDER_BY_TOKEN_FAILED = 'api/GET_ORDER_BY_TOKEN_FAILED';
