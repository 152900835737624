import * as types from './types'
import { createReducer } from '../../../utils'

const initialState = { error: null, message:null, toDisplay:false };

const reducers = createReducer( initialState )( {
    [ types.SET_NOTIFICATION ]: ( state, action ) => {
      const error   = action?.payload?.data?.error;
      const message = action?.payload?.data?.message;
      return { ...state,
        error : error,
        message: message,
        toDisplay: true
      }
    },

    [ types.CLEAR_NOTIFICATION ]: ( state, action ) => {
      return { error: null, message:null, toDisplay:false }
    },

    [ types.SET_NOTIFICATION_SUCCESS ]: ( state, action ) => {
      const message = action.payload.message;
      return { ...state,
        message: message,
        toDisplay: true
      }
    },

    [ types.CLEAR_NOTIFICATION_SUCCESS ]: ( state, action ) => {
      return { initialState }
    },
} );

export default reducers;
