import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Ranges from "views/pages/Mealtray/Ranges/Ranges"
import { operations as rangeOperations, selectors as rangeSelectors } from "state/ducks/api/productRange";
import { selectors as cartSelectors } from "state/ducks/client/cart";
import { operations as modalsOperations } from 'state/ducks/client/modals';

const RangesContainer = (props) => {

  const dispatch = useDispatch();

  const cart = useSelector(
    (state) => cartSelectors.getCart(state),
    shallowEqual
  );

  const productRanges = useSelector(
    (state) => rangeSelectors.getRanges(state),
    shallowEqual
  );

  const isLoading = useSelector(
    (state) => rangeSelectors.isLoading(state),
    shallowEqual
  );

  useEffect(() => {
    if (productRanges === null) {
      dispatch(rangeOperations.initialize());
    }
  }, [dispatch, productRanges]);

  // si au moins une gamme est dispo
  let unlessOneRangeAvailable = false;
  if (productRanges) {
    unlessOneRangeAvailable = productRanges.find(element => {
      if (element?.availableDates && element.availableDates.length > 0) {
        return true;
      }else{
        return false;
      }
    })
    unlessOneRangeAvailable = (unlessOneRangeAvailable === undefined) ? false : true;
  }

  // Si ancre detecté alors on ouvre popup contact
  if(props.location.hash === "#contact"){
    dispatch(modalsOperations.setModalStatusContact(true))
  };

  if(props.location.hash === "#cocktail"){
    dispatch(modalsOperations.setModalStatusContact(true, "Un cocktail ? Quelle bonne idée !", "Sophie et Julie vous recontactent pour l'organisation de votre événement."))
  };

  if(props.location.hash === "#petit-dejeuner"){
    dispatch(modalsOperations.setModalStatusContact(true, "Commençons la journée ensemble !", "Sophie et Julie vous accompagnent dans l’organisation de votre petit-déjeuner : bonne journée assurée !"))
  };

  return (
    <Ranges
      cart={cart}
      ranges={productRanges}
      unlessOneRangeAvailable={unlessOneRangeAvailable}
      isLoading={isLoading}
    />
  );

};

export default RangesContainer;
