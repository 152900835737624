import * as actionTypes from './types'

export const clean = () => ({
    type: actionTypes.CLEAN,
    payload: {},
});

export const setIsOpen = ( status ) => ( {
    type: actionTypes.SET_IS_OPEN,
    payload: {
        status
    },
} );

export const chooseDeliveryAddress = (address, available) => ({
  type: actionTypes.UPDATE_DELIVERY_ADDRESS,
  payload: {
    address:address,
    available:available
  },
});

export const addArticle = (article, range) => ({
  type: actionTypes.ADD_ARTICLE,
  payload: {
    article:article,
    range:range
  },
});

export const removeArticle = (article, range) => ({
  type: actionTypes.REMOVE_ARTICLE,
  payload: {
    article:article,
    range:range
  },
});

export const changeQuantityArticle = (article, range, quantity) => ({
  type: actionTypes.CHANGE_QUANTITY_ARTICLE,
  payload: {
    article:article,
    range:range,
    quantity:quantity
  },
});

export const resetArticles = () => ({
  type: actionTypes.RESET_ARTICLES,
  payload: {},
});

export const updateTotal = (totals) => ({
  type: actionTypes.UPDATE_TOTAL,
  payload: totals,
});

export const updateSilverwareInfo = (info) => ({
  type: actionTypes.UPDATE_SILVERWARE_INFO,
  payload: info,
});

export const changeSilverwareValue = (swId, value) => ({
  type: actionTypes.CHANGE_SILVERWARE_VALUE,
  payload: {silverwareId:swId, value:value}
});

export const updateCustomerInfo = (info) => ({
  type: actionTypes.UPDATE_CUSTOMER_INFO,
  payload: info
});

export const updateDeliveryInfo = (info) => ({
  type: actionTypes.UPDATE_DELIVERY_INFO,
  payload: info
});

export const updateBillingInfo = (info) => ({
  type: actionTypes.UPDATE_BILLING_INFO,
  payload: info
});
