import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Item from "./Item"
import { operations as customerOperations } from "state/ducks/api/customer";
import { operations as modalsOperations } from 'state/ducks/client/modals'
import { operations as notificationsOperations } from 'state/ducks/api/notifications';

const ItemContainer = (props) => {

  const {
    item
  } = props

  const dispatch = useDispatch();

  const handleEditOrder = (title, description) => {
    dispatch(modalsOperations.setModalStatusContact(true, title, description))
  }

  const handleCancelOrder = (orderType, orderId) => {
    dispatch(customerOperations.cancelOrder(orderType, orderId)).then(
      (success) => {
        dispatch(notificationsOperations.setNotificationSuccess("Votre commande a bien été annulée"));
      }
    );
  }

  const handleValidateQuotation = (orderType, orderId) => {
    dispatch(customerOperations.confirmOrder(orderType, orderId)).then(
      (success) => {
        dispatch(notificationsOperations.setNotificationSuccess("Votre commande a bien été validée."));
      }
    );
  }

  const handleGenerateQuotation = (orderType, orderId) => {
    dispatch(customerOperations.generateQuotation(orderType, orderId)).then(
      (success) => {
        dispatch(notificationsOperations.setNotificationSuccess("Votre document a bien été généré, vous pouvez le consulter dès maintenant."));
      }
    );
  }

  return (
    <Item
      item={item}
      handleCancelOrder={handleCancelOrder}
      handleEditOrder={handleEditOrder}
      handleValidateQuotation={handleValidateQuotation}
      handleGenerateQuotation={handleGenerateQuotation}
    />
  );

};

ItemContainer.propTypes = {
  item: PropTypes.object
};

export default ItemContainer;
