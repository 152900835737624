import React from "react";
import styled from "@emotion/styled";
import { Container, Row, Col } from 'react-bootstrap'
import {REVIEWS} from "config/constants"
import Slider from "react-slick";
import review from "assets/images/review.png"

// Bandeau des avis
const Review = (props) => {

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 8000,
    autoplay: true,
    responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            autoplaySpeed: 8000,
            autoplay: true,
          }
        },
      ]
  };

  return (
    <Background>
      <Container>
        <Row>
          <Col xs={12} sm={6}>
            <Title>
              Vous en parlez mieux que nous, merci pour l’ énergie que vous nous donnez !
              <br/><a href="https://www.google.com/maps/place/Le+Moulin+Traiteur/@45.7754565,4.8025889,17z/data=!4m7!3m6!1s0x47f4ebc691792225:0xa1d4a49c6cf9bb29!8m2!3d45.7754565!4d4.8025889!9m1!1b1" target="_blank" rel="noreferrer"><img src={review} alt={"Nos avis !"} /></a>
            </Title>
          </Col>
          <Col xs={12} sm={6}>
            <Slider {...settings}>
              {REVIEWS.map((review, index) =>
              <div key={index}>

                <p>
                  <b>{review.title}</b><br/>
                  <i>
                    « {review.description} »
                  </i>
                </p>
                <p><span>{review.author}</span></p>
              </div>
              )}
            </Slider>
          </Col>
        </Row>
      </Container>
    </Background>
  );

};

const Background = styled.div`
  padding: 4rem 1rem;
  @media (max-width: 767px){
    padding: 2rem 0.5rem;
  }
  p{
    font-size: ${props => props.theme.fonts.size.m};
    text-align: left;
    @media (max-width: 767px){
      text-align: center;
      font-size: ${props => props.theme.fonts.size.s};
    }
    i{
      font-size: ${props => props.theme.fonts.size.s};
    }
    span{
      font-size: ${props => props.theme.fonts.size.s};
    }
  }
  img{
    padding: 0.5rem 0.2rem;
    width: 245px;
    max-width: 100%;
  }
`;

const Title = styled.h3`
  font-weight: bold;
  margin-top: 0rem;
  text-align: right;
  @media (max-width: 767px){
    text-align: center;
  }
`;

export default Review;
