import cartModel from './cartModel'

export const getIsLoading                = state => state.api.cart.isLoading;
export const getOrderId                  = state => state.api.cart.order?.id;
export const getInvoiceUrl               = state => state.api.cart.invoiceUrl;
export const getIsLoadingDeliveryAddress = state => state.api.cart.isLoadingDeliveryAddress;
export const cartIsChecking              = state => state.api.cart.isChecking;
export const getPerson                   = state => state?.api?.cart?.data?.deliveryInfo?.person;

export const getCart = (state) => {
  if (state?.api?.cart?.data) {
    return state.api.cart.data;
  }
  else {
    return cartModel;
  }
}
