import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Link, NavLink } from "react-router-dom";
import { Row, Col, Button, DropdownButton, Dropdown } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser, faPhone, faUserCheck, faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { CALL_FOOTER } from "config/constants"

const HeaderDesktop = (props) => {

  const {
    data,
    handleLoginModal,
    handleContactModal,
    isAuthenticated,
    handleLogout
  } = props

  return (
    <Background>
        <Back href="https://lemoulin.fr"><FontAwesomeIcon icon={faArrowLeft} /> Retour sur notre site</Back>
        <Row className="align-items-center" noGutters>
          {data.leftNav &&
            <MarketLink>
              <nav className="main">
                <ul className="text-left">
                  {data.leftNav.map((link, index) =>
                    <li key={index}><NavLink activeClassName={"active"} to={link.url}>{link.name}</NavLink></li>
                  )}
                  <li><div className="popup" onClick={() => handleContactModal("Un cocktail ? Quelle bonne idée !", "Sophie et Julie vous recontactent pour l'organisation de votre événement.")}>Cocktails</div></li>
                  <li><div className="popup" onClick={() => handleContactModal("Commençons la journée ensemble !", "Sophie et Julie vous accompagnent dans l’organisation de votre petit-déjeuner : bonne journée assurée !")}>Petit-déjeuner</div></li>
                </ul>
              </nav>
            </MarketLink>
          }
          <Logo>
            <Link to="/"><img src={data.logo.img} alt={data.logo.name} /></Link>
          </Logo>
            <MarketLink>
              <nav>
                <ul className="text-right">
                  <li>
                    <a href="https://lemoulin.fr/nos-valeurs/" target="_blank" rel="noreferrer">Nos valeurs</a>
                  </li>
                  <li>
                    {isAuthenticated ?
                      <DropdownButton id="dropdown-basic-button" title={<FontAwesomeIcon icon={faUserCheck} />}>
                        <Dropdown.Item as={Link} to={"/compte/devis"}>Commandes en cours</Dropdown.Item >
                        <Dropdown.Item as={Link} to={"/compte/factures"}>Factures</Dropdown.Item>
                        <Dropdown.Item as={Link} to={"/compte/modification"}>Coordonnées</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleLogout()}>Se déconnecter</Dropdown.Item>
                      </DropdownButton>
                      :
                      <div
                        className="popup mr-2"
                        onClick={() => handleLoginModal()}
                      >
                        <FontAwesomeIcon icon={faUser} />
                      </div>
                    }
                  </li>
                  <li>
                    <Style>
                      <Button variant="primary" onClick={() => handleContactModal()}>
                        <span>Rappelez-moi !</span>
                        <div><FontAwesomeIcon icon={faPhone} /> {CALL_FOOTER.button}</div>
                      </Button>
                    </Style>
                  </li>
                </ul>
              </nav>
            </MarketLink>

        </Row>
    </Background>
  );
};

const Logo = styled(Col)`
  max-width: 100px;
`
const MarketLink = styled(Col)`
  nav{
    ul{
      padding-left: 0;
      margin-bottom: 0rem;
      li{
        font-family: '${props => props.theme.fonts.family.main}';
        display: inline-block;
        padding: 0 0.5rem;
        text-transform: uppercase;
        font-weight: bold;
        font-size: ${(props) => props.theme.fonts.size.s};
        @media (max-width: 1170px){
          font-size: ${(props) => props.theme.fonts.size.xs};
        }
        a, .popup{
          cursor: pointer;
          color: #111;
          font-family: '${props => props.theme.fonts.family.main}';
          border-bottom: 1px solid white;
          &:hover, &.active{
            text-decoration: none;
            color: ${(props) => props.theme.colors.primary};
            border-bottom: 1px solid ${(props) => props.theme.colors.primary};
          }
        }
      }
    }
  }
  button{
    font-size: ${(props) => props.theme.fonts.size.xs};
    @media (max-width: 1170px){
      font-size: ${(props) => props.theme.fonts.size.xs};
    }
  }
  .dropdown{
    button{
      background-color: transparent !important;
      border: none !important;
      color: ${(props) => props.theme.colors.inherit} !important;
      text-transform: uppercase;
      font-family: '${props => props.theme.fonts.family.main}';
      font-weight: bold;
      font-size: ${(props) => props.theme.fonts.size.s};
      @media (max-width: 1170px){
        font-size: ${(props) => props.theme.fonts.size.xs};
      }
      &:hover, &:focus{
        background-color: transparent;
        color: ${(props) => props.theme.colors.primary}  !important;
        box-shadow: none  !important;
      }
    }
    .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
      background-color: transparent;
      color: ${(props) => props.theme.colors.primary};
      box-shadow: none;
    }
  }
`
const Background = styled.header`
  background-color: white;
  padding: 1.0rem 2%;
  color: ${(props) => props.theme.colors.inherit};
  @media (max-width: 1170px){
    padding: 1.0rem 1%;
  }
  .logo{
    max-width: 170px;
  }
`;

const Style = styled.div`
  button{
    font-size: ${props => props.theme.fonts.size.s};
    @media (max-width: 767px){
      padding: 0.5rem 1.0rem;
      font-size: ${props => props.theme.fonts.size.sm};
    }
    :hover{
      span{
        left: auto;
        right: auto;
        opacity: 0;
        transform: scale(.75);
      }
      div{
        left: 0;
        right: auto;
        opacity: 1;
        transform: scale(1);
      }
    }
    span{
      left: auto;
      right: auto;
      opacity: 1;
      transform: scale(1);
      transition: opacity .3s ease,transform .3s ease;
    }
    div{
      opacity: 0;
      left: 0;
      right: auto;
      transform: scale(1.5);
      transition: opacity .3s ease,transform .3s ease;
      top: 50%;
      margin-top: -1.6rem;
    }
  }
`;

const Back = styled.a`
  position: absolute;
  color: #bbb;
  font-size: ${props => props.theme.fonts.size.xs};
  padding-left: 0.5rem;
`

HeaderDesktop.propTypes = {
  data: PropTypes.object, // {logo, url}
  handleLoginModal: PropTypes.func,
  handleContactModal: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  handleLogout: PropTypes.func
};

export default HeaderDesktop;
