import * as types from './types'

export const setModalStatusConnexion = (isOpen) => ( {
    type: types.SET_MODAL_STATUS_CONNEXION,
    payload: {
      isOpen
    }
} );

export const setModalStatusContact = (isOpen, title, description) => ( {
    type: types.SET_MODAL_STATUS_CONTACT,
    payload: {
      isOpen,
      title,
      description
    }
} );

export const setModalStatusDeliveryInput = (isOpen, text) => ( {
    type: types.SET_MODAL_STATUS_DELIVERY_INPUT,
    payload: {
      isOpen,
      text
    }
} );

export const setModalStatusMinAmount = (isOpen, text) => ( {
    type: types.SET_MODAL_STATUS_MIN_AMOUNT,
    payload: {
      isOpen,
      text
    }
} );

export const setModalStatusClearCart = (isOpen) => ( {
    type: types.SET_MODAL_STATUS_CLEAR_CART,
    payload: {
      isOpen
    }
} );

export const setModalStatusDateChangeProduct = (isOpen) => ( {
    type: types.SET_MODAL_STATUS_DATE_CHANGE_PRODUCT,
    payload: {
      isOpen
    }
} );

export const setModalStatusGeneric = (isOpen, description, title) => ( {
    type: types.SET_MODAL_STATUS_GENERIC,
    payload: {
      isOpen,
      description,
      title
    }
} );
