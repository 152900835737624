import * as actions from './actions'
import * as selectors from './selectors'
import * as utils from './utils'
import * as modalsActions from '../modals/actions'
import * as cartApiActions from 'state/ducks/api/cart/actions'
import * as cartApiOperations from 'state/ducks/api/cart/operations'
import * as cartApiSelectors from 'state/ducks/api/cart/selectors'
import * as customerSelectors from 'state/ducks/api/customer/selectors'
import * as deliveryCostSelectors from 'state/ducks/api/deliveryCost/selectors'
import * as otherUtils from "utils/utils"
import { animateScroll as scroll } from 'react-scroll'

const setIsOpen          = actions.setIsOpen;
const removeArticle      = actions.removeArticle;
const resetArticles      = actions.resetArticles;
const updateCustomerInfo = actions.updateCustomerInfo;
const clean              = actions.clean;

/**
 * Permet de changer la date sans autre action, utile pour l'initialisation des commandes
 */
const setDeliveryDate = (date) => (dispatch, getState) => {
  let cart = selectors.getCart(getState());
  return dispatch(actions.updateDeliveryInfo({...cart.deliveryInfo, deliveryDate:date}));
}

const updateBillingInfo = (info) => (dispatch, getState) => {
  dispatch(actions.updateBillingInfo(info));
  let cart = selectors.getCart(getState());
  return dispatch(updateCartFromData({cart:cart}));
}

/**
 * Operation utile pour la page de confirmation
 */
const updateBillingAndDeliveryInfo = (deliveryInfo, billingInfo) => (dispatch, getState) => {
  dispatch(actions.updateDeliveryInfo(deliveryInfo));
  dispatch(actions.updateBillingInfo(billingInfo));
  let cart = selectors.getCart(getState());
  return dispatch(updateCartFromData({cart:cart}));
}

/**
 * Met à jour la valeur d'un couvert + maj du total + maj api
 */
const changeSilverwareValue = (swId, value) => (dispatch, getState) => {
  // Met à jour le total
  dispatch(actions.changeSilverwareValue(swId, value));
  let totals = utils.updateTotal(selectors.getCart(getState()));
  dispatch(actions.updateTotal(totals));
  dispatch(updateCartFromStore());
}

/**
 * Créé ou met à jour le panier en fonction du store actuel
 */
const updateCartFromStore = () => (dispatch, getState) => {
  let cart               = selectors.getCart(getState());
  let toSubmit           = {...cart};
  let quantitySilverware = 0;

  if (cart?.productRanges) {
    delete toSubmit.productRanges;
    toSubmit.articles = [];
    cart.productRanges.forEach(range => {
      if (range?.articles) {
        range.articles.forEach(article => {

          if (article?.type === 'group') {
            quantitySilverware += article.quantity;
          }

          toSubmit.articles.push({
            quantity       :article.quantity,
            price          :article.price,
            [article.type] :article.id,
            range          :range.id,
          })
        });
      }
    });
  }

  if (cart?.silverwareInfo) {
    toSubmit.silverwares = [];
    cart.silverwareInfo.forEach(silverware => {
      if (silverware.value === true) {
        toSubmit.silverwares.push({
          quantity :quantitySilverware,
          price    :silverware.price,
          product  :silverware.id
        })
      }
    });
  }

  const apiCart = cartApiSelectors.getCart(getState());
  if (apiCart?.id) {
    return dispatch(cartApiActions.putCart(apiCart.id, {cart:toSubmit}))
  }
  else {
    return dispatch(cartApiActions.postCart({cart:toSubmit}))
  }
}

/**
 * Créé ou met à jour le panier en fonction des données envoyées
 */
const updateCartFromData = (data, type) => (dispatch, getState) => {

  let customer = customerSelectors.getCustomer(getState());

  if (data?.cart) {
    let toSubmit = {...data.cart};

    // La quantité de couvert correspond à la quantité de groupe de produit
    let quantitySilverware = 0;

    if (data?.cart?.productRanges) {
      delete toSubmit.productRanges;
      toSubmit.articles = [];
      data.cart.productRanges.forEach(range => {

        if (range?.articles) {
          range.articles.forEach(article => {
            if (article?.type === 'group') {
              quantitySilverware += article.quantity;
            }
            toSubmit.articles.push({
              quantity       :article.quantity,
              price          :article.price,
              [article.type] :article.id,
              range          :range.id,
            })
          });
        }
      });
    }

    if (data?.cart?.silverwareInfo) {
      toSubmit.silverwares = [];
      data.cart.silverwareInfo.forEach(silverware => {
        if (silverware.value === true) {
          toSubmit.silverwares.push({
            quantity :quantitySilverware,
            price    :silverware.price,
            product  :silverware.id
          })
        }
      });
    }

    const apiCart = cartApiSelectors.getCart(getState());
    if (customer?.id) {
      toSubmit.customer = customer.id
    }
    if (apiCart?.id) {
      return dispatch(cartApiOperations.updateCart(apiCart.id, {cart:toSubmit}, type));
    }
    else {
      return dispatch(cartApiActions.postCart({cart:toSubmit}, type));
    }
  }

  return Promise.reject(new Error("Il y a eu un problème avec la mise à jour de votre panier"));
}

/**
 * Ajoute un article dans le panier, vérifie avant si l'adresse est livrable, si pas une autre gamme dans le panier
 * Créer/met à jour le panier sur l'api
 * @param {object} item
 * @param {object} range
 */
const addArticle = (item, range) => (dispatch, getState) => {

  let cart = selectors.getCart(getState());
  if (cart?.deliveryAddress?.available !== true) {
    scroll.scrollToTop();
    return new Promise((resolve, reject) => {
      dispatch(modalsActions.setModalStatusDeliveryInput(true, "Pour commencer votre panier, merci d’indiquer votre adresse et date de livraison."))
    });
  }

  if (cart?.productRanges && cart.productRanges[0] !== undefined) {
    if (range.slug !== cart.productRanges[0].slug && cart.productRanges[0].slug !== null) {
      return new Promise((resolve, reject) => {
        dispatch(modalsActions.setModalStatusClearCart(true));
      });
    }
  }

  dispatch(actions.addArticle({...item, quantity:1}, range));

  // Met à jour les couverts
  let silverwareInfo = utils.updateSilverwareInfo(selectors.getCart(getState()));
  dispatch(actions.updateSilverwareInfo(silverwareInfo));

  // Met à jour le total
  let totals = utils.updateTotal(selectors.getCart(getState()));
  dispatch(actions.updateTotal(totals));

  let deliveryDate = selectors.getDeliveryDate(getState());
  let productRanges = selectors.getProductRanges(getState());
  let cloneCart = {...cart,
    deliveryInfo: {...cart.deliveryInfo, deliveryDate:deliveryDate},
    productRanges: productRanges
  }

  return dispatch(updateCartFromData({cart:cloneCart}));
}

const changeQuantityArticle = (article, range, quantity) => (dispatch, getState) => {

  let cart = selectors.getCart(getState());
  if (cart?.deliveryAddress?.available !== true) {
    scroll.scrollToTop();
    return dispatch(modalsActions.setModalStatusDeliveryInput(true, "Pour commencer votre panier, merci d’indiquer votre adresse et date de livraison"));
  }


  dispatch(actions.changeQuantityArticle(article, range, quantity));

  let productRanges = selectors.getProductRanges(getState());

  // Met à jour les couverts
  if (quantity < 1) {
    cart = selectors.getCart(getState());
    let silverwareInfo = utils.updateSilverwareInfo(cart);
    dispatch(actions.updateSilverwareInfo(silverwareInfo));
    cart = selectors.getCart(getState());
  }

  // Met à jour le total
  let totals = utils.updateTotal(selectors.getCart(getState()));
  dispatch(actions.updateTotal(totals));

  let cloneCart = {...cart,
    productRanges: productRanges
  }
  return dispatch(updateCartFromData({cart:cloneCart}));
}

const updateDeliveryDate = (date, available) => ( dispatch, getState ) => {
  let deliveryInfo = selectors.getDeliveryInfo(getState());
  dispatch(actions.updateDeliveryInfo({...deliveryInfo, deliveryDate:date}));
  let cart = selectors.getCart(getState());
  return dispatch(updateCartFromData({cart:cart}));
}

/**
 * Met à jour l'adresse de livraison avec un object google place
 * @param  {[type]} cartId [description]
 * @param  {[type]} date   [description]
 * @return {[type]}        [description]
 */
const updateAddressFromPlace = (googlePlace, type) => ( dispatch, getState ) => {
  let newDeliveryAddress = {};
  if (googlePlace?.address_components) {
    googlePlace?.address_components.forEach(element =>
      {
        if (element?.types) {
          element?.types.forEach(e => {
            if (e === 'street_number') {
              newDeliveryAddress.streetNumber = element.long_name
            }
            if (e === 'route') {
              newDeliveryAddress.street = element.long_name
            }
            if (e === 'locality') {
              newDeliveryAddress.city = element.long_name
            }
            if (e === 'postal_code') {
              newDeliveryAddress.postalCode = element.long_name
            }
            if (e === 'country') {
              newDeliveryAddress.country = element.long_name
            }
          })
        }
      }
    );
  }
  if (googlePlace?.formatted_address) {
    newDeliveryAddress.complete = googlePlace?.formatted_address;
  }
  if (googlePlace?.geometry?.location) {
    newDeliveryAddress.lat = googlePlace?.geometry?.location.lat();
    newDeliveryAddress.lng = googlePlace?.geometry?.location.lng();
  }


  let deliveryCosts = deliveryCostSelectors.getDeliveryCosts(getState());
  let deliveryCost  = deliveryCosts.find((element) => element.postalCode === newDeliveryAddress.postalCode);

  // Supprime le message input de livraison
  dispatch(modalsActions.setModalStatusDeliveryInput(deliveryCost !== undefined ? false : true, "Désolé, notre formidable équipe de livraison ne dessert pas encore cette zone"));

  if (deliveryCost !== undefined) {
    newDeliveryAddress.deliveryCostHt  = deliveryCost ? deliveryCost.price    : null;
    newDeliveryAddress.deliveryCostTtc = deliveryCost ? deliveryCost.priceTtc : null;
    newDeliveryAddress.taxRate         = deliveryCost ? parseFloat(deliveryCost.taxRate) : null;
    newDeliveryAddress.cost            = newDeliveryAddress.deliveryCostTtc;

    let cloneCart = selectors.getCart(getState());
    // S'assure que la date est toujours envoyée
    let deliveryDate = null;
    if (cloneCart?.deliveryInfo?.deliveryDate) {
      deliveryDate = cloneCart.deliveryInfo.deliveryDate;
    }
    else {
      deliveryDate = selectors.getDeliveryDate(getState());
    }

    cloneCart = {...cloneCart, deliveryInfo: {...newDeliveryAddress, deliveryDate:deliveryDate}};
    return dispatch(updateCartFromData({cart:cloneCart}, type)).then((success) => {

      // Ajoute les infos de l'adresse
      dispatch(actions.chooseDeliveryAddress(newDeliveryAddress, true));

      // Ajout les infos de livraison dans le panier client
      const apiCart = cartApiSelectors.getCart(getState());
      dispatch(actions.updateDeliveryInfo(apiCart.deliveryInfo));

      // Met à jour le total
      let totals = utils.updateTotal(selectors.getCart(getState()));
      dispatch(actions.updateTotal(totals));
    });
  }
  else {
    // Ajoute les infos de l'adresse
    dispatch(actions.chooseDeliveryAddress(newDeliveryAddress, false));
    // Met à jour le total
    let totals = utils.updateTotal(selectors.getCart(getState()));
    return dispatch(actions.updateTotal(totals));
  }
}

const confirmCart = () => (dispatch, getState) => {
  return dispatch(updateCartFromStore()).then(() => {
    let cart = selectors.getCart(getState());
    let deliveryCostHt = cart?.deliveryInfo?.deliveryCostHt ? cart.deliveryInfo.deliveryCostHt : 0;
    if (cart.productRanges[0] === undefined ) {
      dispatch(modalsActions.setModalStatusMinAmount(true, "Le panier est vide."));
      return false;
    }
    else if ((cart.priceInfo.totalPriceHt - deliveryCostHt) < cart.productRanges[0].minAmountOrder) {
      dispatch(modalsActions.setModalStatusMinAmount(true, "Le montant minimum pour cette gamme est de " + otherUtils.centToEuros(cart.productRanges[0].minAmountOrder) + " (hors frais de livraison)"));
      return false;
    }
    dispatch(modalsActions.setModalStatusMinAmount(false, null));
    return true;
  });
}

export {
    setDeliveryDate,
    setIsOpen,
    updateCustomerInfo,
    updateBillingInfo,
    updateBillingAndDeliveryInfo,
    updateCartFromData,
    updateCartFromStore,
    updateAddressFromPlace,
    addArticle,
    removeArticle,
    changeQuantityArticle,
    resetArticles,
    changeSilverwareValue,
    confirmCart,
    updateDeliveryDate,
    clean
};
