import * as actions from './actions'
import * as selectors from './selectors'
import { operations as modalsOperations } from 'state/ducks/client/modals';
import { operations as cartClientOperations } from "state/ducks/client/cart";
import { selectors as rangeSelectors } from "state/ducks/api/productRange";

const loadCart      = actions.getCart;
const createCart    = actions.postCart;
const getInvoiceUrl = actions.getInvoiceUrl;
const clean         = actions.clean;

// Si l'update echoue avec un 404, lance un post
const updateCart = (cartId, data, type) => (dispatch, getState) => {
  return dispatch(actions.putCart(cartId, data, type))
  .catch((err) => {
    if (err?.response?.status === 404) {
      dispatch(actions.postCart(data, type))
    }
  })
  ;
}


// Vérifie le panier, si problème le vide
// 03/05/22 - Retourne une promise reject si l'erreur est catch ensuite
const checkCart = (cartId) => (dispatch, getState) => {
  return dispatch(actions.checkCart(cartId)).catch((error) => {
    dispatch(modalsOperations.setModalStatusGeneric(true, "Certains produits ne sont plus disponibles pour cette date, merci de modifier votre panier", "Oups !"));
    let dates = rangeSelectors.getDates(getState());
    if (dates && dates.length > 0) {
      dispatch(actions.clean());
      dispatch(cartClientOperations.clean());
      // 06/12/2021: permet d'afficher l'offre de noel (qui demande 48h et non 24h)
      if (dates[1] !== undefined) {
        dispatch(cartClientOperations.setDeliveryDate(dates[1]));
      }
      else {
        dispatch(cartClientOperations.setDeliveryDate(dates[0]));
      }
    }
    else {
      dispatch(actions.clean());
      dispatch(cartClientOperations.clean());
    }

    if (error?.response?.data?.result?.message) {
      return Promise.reject(error.response.data.result.message);
    }
    return Promise.reject("Certains produits ne sont plus disponibles pour cette date, merci de modifier votre panier");
  });
  ;
}

const createOrder = (data) => (dispatch, getState) => {
  let apiCart = selectors.getCart(getState());
  if (apiCart) {
    return dispatch(checkCart(apiCart.id))
    .then((success) => {
      return dispatch(actions.postOrder(apiCart.id, data)).then((success) => {
        let orderId = selectors.getOrderId(getState());
        if (orderId) {
          return dispatch(actions.getInvoiceUrl(orderId)).then((success) => {
            dispatch(actions.clean());
            dispatch(cartClientOperations.clean());
            return {orderId: orderId}
          });
        }
        else {
          dispatch(actions.clean());
          dispatch(cartClientOperations.clean());
          return {orderId: null}
        }
      })
    });
  }

  // return Promise.reject(new Error("Il y a eu un problème avec votre panier"));
}

export {
    loadCart,
    createCart,
    updateCart,
    checkCart,
    createOrder,
    getInvoiceUrl,
    clean
};
