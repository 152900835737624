import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap"
import { useWindowDimensions } from 'utils/WindowDimensionsProvider'
import ReassuranceBottom from 'views/commons/Blocks/ReassuranceBottom'

const Footer = (props) => {

  const {
    handleContactModal
  } = props

  const { width } = useWindowDimensions();

  return (
    <>
      <ReassuranceBottom />
      <Background>
        {width > 1050 &&
          <Container>
            <Row>
              <Col xs={12} sm={12} md={4}>
                <h4>NOTRE OFFRE</h4>
                <ul>
                  <li><Link to={"/"}>Plateaux-repas</Link></li>
                  <li><div onClick={() => handleContactModal("Un cocktail ? Quelle bonne idée !", "Sophie et Julie vous recontactent pour l'organisation de votre événement.")}>Cocktails</div></li>
                  <li><div onClick={() => handleContactModal("Commençons la journée ensemble !", "Sophie et Julie vous accompagnent dans l’organisation de votre petit-déjeuner : bonne journée assurée !")}>Petit-déjeuner</div></li>
                </ul>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <h4>A PROPOS</h4>
                <ul>
                  <li><a href="https://lemoulin.fr/nos-valeurs/" target="_blank" rel="noreferrer">Nos valeurs</a></li>
                </ul>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <h4>CONTACT</h4>
                <ul>
                  <li><div to={"/"} onClick={() => handleContactModal()}>Nous contacter</div></li>
                  <li><a href={"https://lemoulin.fr/recrutement/"} rel="noreferrer"  target="_blank">Nous rejoindre</a></li>
                </ul>
              </Col>
            </Row>
          </Container>
        }
        <Container>
          <Row>
            <Col>
              <a href="https://lemoulin.fr/mentions-legales/" target="_blank" rel="noreferrer">Mentions légales</a> - <a href="https://lemoulin.fr/politique-de-confidentialite/" target="_blank" rel="noreferrer">Cookie</a> - <a href="https://lemoulin.fr/cgv-moulin/" target="_blank" rel="noreferrer">CGV</a>
            </Col>
          </Row>
        </Container>

      </Background>
    </>
  );
};

const Background = styled.footer`
  background-color: ${(props) => props.theme.colors.primary};
  padding: 2% 0;
  @media (max-width: 767px){
    padding: 4% 0;
  }
  color: white;
  text-align: center;
  a{
    color: white;
    font-size: ${(props) => props.theme.fonts.size.xxs};
  }
  h4{
    font-weight: bold;
    text-transform: uppercase;
    font-size: ${(props) => props.theme.fonts.size.m};
    @media (max-width: 767px){
      font-size: ${(props) => props.theme.fonts.size.sm};
    }
  }
  ul{
    padding-left: 0;
    margin-bottom: 0rem;
    @media (max-width: 767px){
      margin-bottom: 1.0rem;
    }
    li{
      list-style: none;
      padding: 0.5rem 0;
      @media (max-width: 767px){
        padding: 0.2rem 0;
      }
      a, div{
        color: white;
        cursor: pointer;
        font-size: ${(props) => props.theme.fonts.size.s};
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
`;

Footer.propTypes = {
  data: PropTypes.array,
};

export default Footer;
