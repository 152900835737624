import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Link, NavLink } from "react-router-dom";
import { Row, Col, Button, DropdownButton, Dropdown } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faUser, faPhone, faUserCheck } from "@fortawesome/free-solid-svg-icons"
import {slide as Menu} from 'react-burger-menu';

const HeaderMobile = (props) => {

  const {
    data,
    handleLoginModal,
    handleContactModal,
    handleStateMenuMobile,
    isOpenMenuMobile,
    handleOpenMenuMobile,
    isAuthenticated,
    handleLogout
  } = props

  return (
    <>
      <Menu
        id={"menu-nav"}
        left
        customBurgerIcon={ false }
        isOpen={isOpenMenuMobile}
        disableAutoFocus={true}
        onStateChange={(state) => handleStateMenuMobile(state)}
      >
          <MarketLink>

                <nav className="main">
                  <ul className="text-left">
                    <Action>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          handleContactModal();
                          handleOpenMenuMobile()
                        }}
                      >
                        <FontAwesomeIcon icon={faPhone} /> Rappelez-moi !
                      </Button>
                    </Action>
                    <br/>
                    {data?.leftNav?.map((link, index) =>
                      <li key={index}><NavLink activeClassName={"active"} to={link.url}>{link.name}</NavLink></li>
                    )}
                    <li><div className="popup" onClick={() => {
                      handleContactModal("Un cocktail ? Quelle bonne idée !", "Sophie et Julie vous recontactent pour l'organisation de votre événement.");
                      handleOpenMenuMobile()
                    }}>Cocktails</div></li>
                    <li><div className="popup" onClick={() => {
                      handleContactModal("Commençons la journée ensemble !", "Sophie et Julie vous accompagnent dans l’organisation de votre petit-déjeuner : bonne journée assurée !");
                      handleOpenMenuMobile()
                    }}>Petit-déjeuner</div></li>
                    <li><a href="https://lemoulin.fr/nos-valeurs/" target="_blank" rel="noreferrer">Nos valeurs</a></li>
                    <li><a href="https://lemoulin.fr">Retour sur notre site</a></li>
                  </ul>
                </nav>

            </MarketLink>
      </Menu>
      <Background>
        <Row className="align-items-center" noGutters>
          <Col className="text-left">
            <Link
              to="#"
              onClick={() => handleOpenMenuMobile()}
            >
              <Icon>
                <FontAwesomeIcon icon={faBars} />
              </Icon>
            </Link>
          </Col>
          <Logo>
            <Link to="/"><img src={data.logo.img} alt={data.logo.name} /></Link>
          </Logo>
          <MarketLink style={{'text-align' : 'right'}}>
            {isAuthenticated ?
              <DropdownButton id="dropdown-basic-button" title={<FontAwesomeIcon icon={faUserCheck} size="lg"/>}>
                <Dropdown.Item as={Link} to={"/compte/devis"}>Commandes en cours</Dropdown.Item >
                <Dropdown.Item as={Link} to={"/compte/factures"}>Factures</Dropdown.Item>
                <Dropdown.Item as={Link} to={"/compte/modification"}>Coordonnées</Dropdown.Item>
                <Dropdown.Item onClick={() => handleLogout()}>Se déconnecter</Dropdown.Item>
              </DropdownButton>
              :
              <div
                onClick={() => handleLoginModal()}
                className="mr-2 popup"
              >
                <FontAwesomeIcon icon={faUser} size="lg"/>
              </div>
            }
          </MarketLink>
        </Row>
      </Background>
    </>
  );
};

const Logo = styled(Col)`
  max-width: 65px;
`
const Action = styled.div`
  padding-top: 3.0rem;
  button{
    width: 100%;
    padding: 1.0rem;
  }
`

const Icon = styled.div`

  font-size: 1.4rem;
`
const MarketLink = styled(Col)`
  .popup{
    cursor: pointer;
    color: #111;
    font-family: '${props => props.theme.fonts.family.main}';
    font-size: ${(props) => props.theme.fonts.size.xs};
    &:hover, &.active{
      text-decoration: none;
      color: ${(props) => props.theme.colors.primary};
    }
  }
  nav{
    ul{
      padding-left: 0;
      margin-bottom: 0rem;
      li{
        display: inline-block;
        padding: 0.5rem 1rem;
        text-transform: uppercase;
        font-weight: bold;
        width: 100%;
        border-bottom: solid #ccc 1px;
        a{
          cursor: pointer;
          color: #111;
          font-family: '${props => props.theme.fonts.family.main}';
          font-size: ${(props) => props.theme.fonts.size.xs};
          &:hover, &.active{
            text-decoration: none;
            color: ${(props) => props.theme.colors.primary};
          }
        }
      }
    }
  }
`
const Background = styled.header`
  background-color: white;
  padding: 0.5rem 4%;
  color: ${(props) => props.theme.colors.inherit};
  .logo{
    max-width: 170px;
  }
  img{

  }
  nav{
    ul{
      padding-left: 0;
      margin-bottom: 0rem;
      li{
        display: inline-block;
        padding: 0 0.5rem;
        text-transform: uppercase;
        font-weight: bold;
        a{
          cursor: pointer;
          color: #111;
          font-family: '${props => props.theme.fonts.family.main}';
          font-size: ${(props) => props.theme.fonts.size.s};
          &:hover, &.active{
            text-decoration: none;
            color: ${(props) => props.theme.colors.primary};
            border-bottom: 1px solid ${(props) => props.theme.colors.primary};
          }
        }
      }
    }
  }
`;

HeaderMobile.propTypes = {
  data: PropTypes.object, // {logo, url}
  handleLoginModal: PropTypes.func,
  handleContactModal: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  handleLogout: PropTypes.func
};

export default HeaderMobile;
