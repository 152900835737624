import React from 'react'
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import QuantityItem from "./QuantityItem"
import PropTypes from "prop-types";
import { operations as cartOperations, selectors as cartSelectors } from "state/ducks/client/cart";
import { selectors as cartApiSelectors } from "state/ducks/api/cart";

const QuantityItemContainer = (props) => {

  const {
    range,
    item,
    color,
    article,
    able,
    onClose,
    categoryName
  } = props

  const dispatch = useDispatch();

  item.type = (item.genericId) ? 'product' : 'group';

  const cart = useSelector(
    (state) => cartSelectors.getCart(state),
    shallowEqual
  );

  const quantity = article?.quantity ? article.quantity : 0;

  // Gère l'action d'ajout/modification quantité d'un article + GTM
  const handler = (action, selectedQuantity) => {
    if(cart?.deliveryAddress?.available !== true){
      if(onClose){
        onClose();
      }
    }
    switch (action) {
      case 'add':
        dispatch(cartOperations.addArticle({...item, quantity:1, categoryName: categoryName}, range)).then((response) => {
          window.dataLayer.push({
            event : 'addToCart',
            ecommerce: {
              add: {
                actionField: {
                  list: 'QuantityItemContainer'
                }
              },
              products:[{
                name    :item.name,
                id      :item.type === 'product' ? item.genericId : item.id,
                price   :item.price / 100,
                category:categoryName,
                brand   :'Le Moulin B2B',
                variant : range.name,
                quantity: item.quantity
              }]
            }
          });
        });
        break;
      case 'change':
        dispatch(cartOperations.changeQuantityArticle(article, range, selectedQuantity.value)).then((response) => {
          let eventName, qty;
          if (selectedQuantity.value > article.quantity ) {
            eventName = 'addToCart';
            qty = selectedQuantity.value - article.quantity;
          }
          else if (selectedQuantity.value < article.quantity) {
            eventName = 'removeFromCart';
            qty = article.quantity - selectedQuantity.value;
          }
          else {
            return;
          }
          window.dataLayer.push({
            event : eventName,
            ecommerce: {
              add: {
                actionField: {
                  list: 'QuantityItemContainer'
                }
              },
              products:[{
                name    :item.name,
                id      :item.type === 'product' ? item.genericId : item.id,
                price   :item.price / 100,
                category:categoryName,
                brand   :'Le Moulin B2B',
                variant : range.name,
                quantity: qty
              }]
            }
          });
        });
        break;
      case 'sub':
        dispatch(cartOperations.changeQuantityArticle(article, range, quantity - 1)).then((response) => {
          window.dataLayer.push({
            event : 'removeFromCart',
            ecommerce: {
              add: {
                actionField: {
                  list: 'QuantityItemContainer'
                }
              },
              products:[{
                name    :item.name,
                id      :item.type === 'product' ? item.genericId : item.id,
                price   :item.price / 100,
                category:categoryName,
                brand   :'Le Moulin B2B',
                variant : range.name,
                quantity: 1
              }]
            }
          });
        });
        break;
      case 'plus':
        dispatch(cartOperations.changeQuantityArticle(article, range, quantity + 1)).then((response) => {
          window.dataLayer.push({
            event : 'addToCart',
            ecommerce: {
              add: {
                actionField: {
                  list: 'QuantityItemContainer'
                }
              },
              products:[{
                name    :article.name,
                id      :article.genericId,
                price   :article.price / 100,
                category:categoryName,
                brand   :'Le Moulin B2B',
                variant : range.name,
                quantity: 1
              }]
            }
          });
        });
        break;
      default:
      break;
    }
  }

  const isLoadingDeliveryAddress = useSelector(
    (state) => cartApiSelectors.getIsLoadingDeliveryAddress(state),
    shallowEqual
  );

  return (
    <QuantityItem item={item} quantity={article?.quantity ? article.quantity : 0} handler={handler} cart={cart} able={able} colorRange={color} isDisable={isLoadingDeliveryAddress}/>
  )
}

QuantityItemContainer.propTypes = {
  item: PropTypes.object,
  range: PropTypes.object,
  colorRange: PropTypes.string,
  able: PropTypes.bool,
  onClose: PropTypes.func,
  categoryName: PropTypes.string
};

export default QuantityItemContainer;
