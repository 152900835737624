import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import WindowDimensionsProvider from './utils/WindowDimensionsProvider'
import RouteChangeTracker from './utils/RouteChangeTracker'

import './assets/fonts/fonts.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'tippy.js/dist/tippy.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./styles/Theme.js";
// eslint-disable-next-line
import Global from "./styles/Global";

// Listing des routes
import routes from "./routes";
import { Provider } from 'react-redux' ;
import {store, persistor} from "./state/store";
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist';

// Force le scroll en haut au changement de page
import ScrollToTop from "./utils/ScrollToTop"

// Date picker en fr
import { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from 'date-fns/locale/fr';

// Hotjar
import TagManager from 'react-gtm-module'

registerLocale("fr", { ...fr, options: { ...fr.options, weekStartsOn: 1 } });
setDefaultLocale("fr");

// Reinitialisation du store au déploiement
const deployVer = process.env.REACT_APP_VERSION;
const curVersion = localStorage.getItem('version');

if(curVersion === 'undefined' || curVersion === null || curVersion !== deployVer){
  localStorage.setItem('version', deployVer);
  persistStore(store).purge();
  document.location.reload();
}

// GTM
TagManager.initialize({gtmId:process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID});

const App = (props) => {

  return (
    <WindowDimensionsProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <Router basename={process.env.REACT_APP_LEMOULIN_URL_BASENAME}>
              <ScrollToTop />
              <RouteChangeTracker />
              <Switch>
                {routes.map((route, key) => (
                  <Route
                    exact
                    path={route.path}
                    component={route.component}
                    key={key}
                  />
                ))}
              </Switch>
            </Router>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </WindowDimensionsProvider>
  );
};

export default App;
