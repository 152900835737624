import React from "react";
import PropTypes from "prop-types";
import styled from '@emotion/styled'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserAlt } from '@fortawesome/free-solid-svg-icons'

const Silverwares = (props) => {

  const {
    silverwares,
    handleChange,
    type,
    isDisable
  } = props

  let nbSilverwares = 0;

  silverwares.map(silverware => {
    return silverware.active &&
      (nbSilverwares = nbSilverwares + 1);
  })

  return (
    nbSilverwares > 0 &&
    <Row noGutters className="align-items-center">
      {type !== "mini" &&
        <Category xs={3}>
          Couverts ?
        </Category>
      }
      <Col>
        <Container>
          {silverwares.map((silverware, index) =>
            silverware.active &&
              <Option key={index}>
                <Title>
                  <Switch>
                    <input type="checkbox" checked={silverware.value && "checked"} onChange={() => handleChange(silverware.id, !silverware.value)} disabled={isDisable}/>
                    <div className="slider"></div>
                  </Switch>
                  {silverware.name}
                  {silverware.price > 0 &&
                    <Price>
                     {" - "+(silverware.price / 100).toFixed(2)+" €"} / <FontAwesomeIcon icon={faUserAlt} size="xs"/>
                    </Price>
                  }
                </Title>
                {type !== "mini" &&
                  <Descr dangerouslySetInnerHTML={{__html: silverware.description}}></Descr>
                }
              </Option>
          )}
        </Container>
      </Col>
    </Row>
  );

};

const Container = styled.div`
  background-color: white;
  padding: 0.5rem;
`

const Price = styled.span`
  font-family: '${props => props.theme.fonts.family.main}';
  font-size: ${props => props.theme.fonts.size.s};
  color: ${props => props.theme.colors.primary};
`

const Title = styled.div`
  font-family: '${props => props.theme.fonts.family.main}';
  font-size: ${props => props.theme.fonts.size.sm};
  color: ${props => props.theme.colors.inherit};
`

const Category = styled(Col)`
  font-family: '${props => props.theme.fonts.family.main}';
  font-size: ${props => props.theme.fonts.size.sm};
  color: ${props => props.theme.colors.inherit};
  text-align: center;
`

const Descr = styled.div`
  font-size: ${props => props.theme.fonts.size.xxxs};
  color: ${props => props.theme.colors.grey};
  p{
    margin-bottom: 0rem;
  }
  @media (max-width: 640px){
    display: none;
  }
`
const Option = styled.div`
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ccc;
  position: relative;
  &:last-child{
    border-bottom: none;
    margin-bottom: 0rem;
    padding-bottom: 0rem;
  }
`

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  outline: none;
  margin-bottom: 0;
  margin-right: 10px;
  input {
    position: absolute;
    top: -99999px;
    left: -99999px;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  input:checked + .slider {
    background-color: ${props => props.theme.colors.darkGreen};
  }
  input:focus + .slider {
    box-shadow: 0 0 1px ${props => props.theme.colors.darkGreen};
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }
`

Silverwares.propTypes = {
  option: PropTypes.array,
  handleChange: PropTypes.func,
  type: PropTypes.string
};

export default Silverwares;
