import React from "react";
import { Container, Row, Col } from 'react-bootstrap'
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faConciergeBell, faLock, faLeaf } from "@fortawesome/free-solid-svg-icons"

// Bandeau composé d'élement marketing
const Reassurance = (props) => {

  return (
    <Background>
      <ContainerBtp>
        <Row className="align-items-center">
        <Col>
          <Row noGutters className="align-items-center">
            <Col className={"text-center"}>
              <FontAwesomeIcon icon={faLeaf} size="2x" className={"mb-2"} />
              <Name>Livraison<br/>en transport doux</Name>
            </Col>
          </Row>
        </Col>

          <Col>
            <Row noGutters className="align-items-center">
              <Col className={"text-center"}>
                <FontAwesomeIcon icon={faConciergeBell}  size="2x" className={"mb-2"} />
                <Name>Accompagnement<br/>personnalisé</Name>
              </Col>
            </Row>
          </Col>

          <Col>
            <Row noGutters className="align-items-center">
              <Col className={"text-center"}>
                <FontAwesomeIcon icon={faLock} size="2x" className={"mb-2"} />
                <Name>Paiement<br/>sécurisé</Name>
              </Col>
            </Row>
          </Col>

        </Row>
      </ContainerBtp>
    </Background>
  );

};

const ContainerBtp = styled(Container)`
  margin: 2rem auto;
  @media (max-width: 767px){
    margin: 0.5rem auto;
  }
  img{
    max-width: 100%;
  }
`

const Background = styled.div`
  background-color: white;
  padding: 5px 0;
  marbin-bottom: 0rem;
`

const Name = styled.div`
  font-weight: bold;
  font-family: '${props => props.theme.fonts.family.main}';
  text-transform: uppercase;
  font-size: ${props => props.theme.fonts.size.sm};
  @media (max-width: 767px){
    font-size: ${props => props.theme.fonts.size.xxs};
  }
`;

export default Reassurance;
