import React from "react";
import { Col } from 'react-bootstrap'
import styled from "@emotion/styled";
import { SOURCE_URI } from "config/constants"
import noVisual from "assets/images/no-visual-big.jpg"
import PropTypes from "prop-types";

const DisplayBanner = (props) => {

  const {
    banner
  } = props

  return (
    banner?.mediaLink &&
      <ColBtp xs={6} sm={4} md={4} lg={3}>
        <Image href={banner?.link} background={banner?.mediaLink ? `${SOURCE_URI}/${banner.mediaLink}` : noVisual} target={banner?.targetBlank ? "_target" : "_self"} rel="noreferrer">
        </Image>
      </ColBtp>
  );
};

const Image = styled.a`

  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  webkit-box-pack: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  min-height: 280px;
  border-radius: 4px;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('${(props) => props.background && props.background}');
  &:hover{
    opacity: 0.7;
    transition: 300ms;
  }
`;

const ColBtp = styled(Col)`
  @media (max-width: 767px){
    padding-right: 5px;
    padding-left: 5px;
  }
  padding-bottom: 1rem;
`

DisplayBanner.propTypes = {
  banner: PropTypes.object
};

export default DisplayBanner;
