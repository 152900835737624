import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { operations as customerOperations, selectors as customerSelectors } from "state/ducks/api/customer";
import Quotations from "views/pages/Account/Quotations/Quotations"

const QuotationsContainer = (props) => {

  const dispatch = useDispatch();
  const orders = useSelector(
    (state) => customerSelectors.getQuotations(state),
    shallowEqual
  );

  const isLoading = useSelector(
    (state) => customerSelectors.isLoading(state),
    shallowEqual
  );

  useEffect(() => {
    dispatch(customerOperations.getOrders());
  }, [dispatch]);

  // Système de pagination
  const perPage = 5;
  const [offset, setOffset] = useState(perPage);
  const pageCount = orders.length / perPage;

  const handlePageClick = (e) => {
      let selectedPage = e.selected;
      let offset = (selectedPage + 1) * perPage;
      setOffset(offset);
  };

  return (
    <Quotations quotations={orders} isLoading={isLoading} handlePageClick={handlePageClick} pageCount={pageCount} perPage={perPage} offset={offset}/>
  );

};

export default QuotationsContainer;
