export const getRanges = state => state.api.productRange.data
export const getCurrentRangeSlug = state => state.api.productRange.currentRangeSlug
export const getDates = state => state.api.productRange.dates
export const isLoading = state => state.api.productRange.isLoading
export const isLoadingDate = state => state.api.productRange.isLoadingDate

/**
 * La gamme actuellement séléctionnée sur le front
 */
export const getCurrentRange = (state) => {
  let currentRange = null;
  if (state?.api?.productRange?.data) {
    currentRange = state.api.productRange.data.find((element) => {
      return state.api.productRange.currentRangeSlug === element.slug
    })
  }
  return currentRange;
}

/**
 * Retourne une gamme en fonction du slug
 */
export const getRangeBySlug = (state, slug) => {
  if (state?.api?.productRange?.data) {
    return state.api.productRange.data.find((element) => {
      return element.slug === slug
    })
  }
  return null;
}
