import * as actionTypes from './types'

export const getDisplayBanners = () => ({
  type: actionTypes.GET_DISPLAY_BANNERS,
  meta: {
    async: true,
    blocking: true,
    path: `/v3/pro/marketing/displaybanners.json`,
    method: "GET"
  },
  payload: {
  },
});
