import React from 'react'
import PropTypes from 'prop-types'
import Button from "styles/Button"
import styled from '@emotion/styled'

const DateChangeProduct = (props) => {

  const {
    handleUpdateCart,
    handleRefuseUpdateCart
  } = props

  return (
    <div className="text-center">
      <Title>Certains produits ne sont plus disponibles à cette date</Title>
      <Button onClick={handleRefuseUpdateCart} padding="0.5rem 2.0rem;" margin="1.0rem 0 0 0" type="submit">Garder mon panier et revenir à mon ancienne date</Button>
      <Button color="#111" onClick={handleUpdateCart} padding="0.5rem 2.0rem;" margin="1.0rem 0 0 0"type="submit">Mettre à jour mon panier avec les produits disponibles à cette date</Button>

    </div>
  )
}

const Title = styled.h3`
  text-align: center;
`;

DateChangeProduct.propTypes = {
  handleUpdateCart: PropTypes.func,
  handleRefuseUpdateCart: PropTypes.func
};

export default DateChangeProduct;
