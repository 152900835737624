import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/css'
import {Form, Button} from 'react-bootstrap'
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loader from "react-loader-spinner";
import traiteur from "assets/images/traiteur.jpg"
import pdj from "assets/images/petit-dejeuner.jpg"
import Slider from "react-slick";
import { Link } from "react-router-dom";

import planche from "assets/images/cocktail-festif-22/focus-planche.jpg"
import burger from "assets/images/cocktail-festif-22/focus-burger.jpg"
import wrap from "assets/images/cocktail-festif-22/focus-wrap.jpg"
import sucre from "assets/images/cocktail-festif-22/focus-sucre.jpg"
import crumble from "assets/images/cocktail-festif-22/focus-crumble.jpg"



const Contact = (props) => {

  const {
    onSubmit,
    text,
    title,
    description,
    customer,
    isLoading
  } = props

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplaySpeed: 4000,
    autoplay: true,
    centerMode: true,
    responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            autoplaySpeed: 4000,
            autoplay: true,
          }
        },
      ]
  };

  const schema = Yup.object().shape({
    lastname: Yup.string(),
    phone: Yup.string().matches(/^((\+|00)33\s?|0)[0123456789](\s?\d{2}){4}$/, 'Merci de renseigner un numéro de téléphone').required('Merci de renseigner votre numéro de téléphone'),
    email: Yup.string().email("Cet email n'est pas correct").required("Merci de renseigner votre email"),
    message: Yup.string()
  });

  return(
      <Formik
        validationSchema={schema}
        onSubmit={(values) => onSubmit(values)}
        initialValues={{
          lastname: customer?.lastname ? customer.lastname : "",
          phone: customer?.phone ? customer.phone : "",
          email: customer?.email ? customer.email : "",
          message: "",
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Style>
            {title === 'Un cocktail ? Quelle bonne idée !' && 
              <>
                <Slider {...settings}>
                  <div>
                    <img src={planche} alt={title} />
                  </div>
                  <div>
                    <img src={crumble} alt={title} />
                  </div>
                  <div>
                    <img src={burger} alt={title} />
                  </div>
                  <div>
                    <img src={sucre} alt={title} />
                  </div>
                  <div>
                    <img src={wrap} alt={title} />
                  </div>
                </Slider>
                {/* Temporaire cocktail festif */}
                {/* {title === 'Un cocktail ? Quelle bonne idée !' &&
                  <Jumbotron>Faites découvrir et redécouvrir à vos invités le charme et les saveurs de nos produits locaux, cuisinés pour les fêtes<br/><Link to="/files/moulin-cocktail-festif-2022.pdf" target="_blank" download><b>Téléchargez notre offre festive</b></Link></Jumbotron>
                } */}
              </>
          }
            
            {title === 'Commençons la journée ensemble !' && 
              <img src={pdj} alt={title} />
            }

            <h3>{title ? title : text.name}</h3>
            <p>{description ? description : text.sub}</p>
            
          </Style>
            <Form.Control
              type='email' size='m' className={inputFormStyle} placeholder='Email*'
              name="email"
              value={values.email}
              onChange={handleChange}
              isValid={touched.email && !errors.email}
              isInvalid={touched.email && !!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>

            <Form.Control
              type='phone' size='m' className={inputFormStyle} placeholder='Téléphone*'
              name="phone"
              value={values.phone}
              onChange={handleChange}
              isValid={touched.phone && !errors.phone}
              isInvalid={touched.phone && !!errors.phone}
            />
            <Form.Control.Feedback type="invalid">
              {errors.phone}
            </Form.Control.Feedback>

            <Form.Control
              type='text' size='m' className={inputFormStyle} placeholder='Nom'
              name="lastname"
              value={values.lastname}
              onChange={handleChange}
              isValid={touched.lastname && !errors.lastname}
              isInvalid={touched.lastname && !!errors.lastname}
            />
            <Form.Control.Feedback type="invalid">
              {errors.lastname}
            </Form.Control.Feedback>
          
            <Form.Control
              type='text' as="textarea" rows={3} size='m' className={inputFormStyle} placeholder="Qu'est-ce qui vous ferait plaisir ?"
              name="message"
              value={values.message}
              onChange={handleChange}
              isValid={touched.message && !errors.message}
              isInvalid={touched.message && !!errors.message}
            />
            <Form.Control.Feedback type="invalid">
              {errors.message}
            </Form.Control.Feedback>

            <div className='text-center'>
             <GreenButton type="submit" size="lg" className='text-uppercase px-5 py-3 mt-3' disabled={isLoading}>
             {isLoading ?
               <Loader
                 type="ThreeDots"
                 color="#ffffff"
                 height={20}
                 width={20}
               />
             :
               'Me contacter'
             }
             </GreenButton>
             <br/><br/>
             <p>ou appelez-nous au <a href="tel:0638133524">06 38 13 35 24</a></p>
            </div>
        </Form>
      )}
    </Formik>
  )
}

const Style = styled.div`
  text-align:center;
  h3{
    color: ${props => props.theme.colors.primary};
    font-family: '${props => props.theme.fonts.family.script}';
    font-size: ${props => props.theme.fonts.size.m};
  }
  p{
    color: ${props => props.theme.colors.primary};
    font-size: ${props => props.theme.fonts.size.xs};
  }
  img{
    border-radius: 50%;
    max-width: 150px;
    width: 100%;
    margin-bottom: 1.0rem;
  }
`
const Jumbotron = styled.p`
  background-color: #EFD5B2;
  color: black !important;
  padding: 0.5rem;
  margin-bottom: 1.5rem;
  border-radius: 4px;
  font-size: ${props => props.theme.fonts.size.xs}
`;

//Background of inputs of forms
const inputFormStyle =css`
  background-color: #ececec;
  border: none;
  margin: 1em 0;
`;

const GreenButton = styled(Button)`
  background-color: ${props => props.theme.colors.green};
  font-size: ${props => props.theme.fonts.size.xs}
`;

Contact.propTypes = {
  onSubmit: PropTypes.func,
  text: PropTypes.object,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  customer: PropTypes.object,
  isLoading: PropTypes.bool
};

export default Contact;
