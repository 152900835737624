import React from "react";
import Connexion from "views/commons/Connexion/Connexion"
import { Container, Row, Col } from 'react-bootstrap'
import styled from "@emotion/styled";
import Layout from "views/commons/Layout/Layout";

const Login = (props) => {

  return (
    <Layout>
      <Container>
        <RowBtp>
            <Col xs={12} sm={12} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }} className={"mb-3"}>
              <Card>
                <Connexion />
              </Card>
            </Col>
        </RowBtp>
      </Container>
    </Layout>
  );
};


const Card = styled.div`
  padding: 1.0rem;
  background-color: white;
  border-radius: 6px;
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.02);
`;

const RowBtp = styled(Row)`
  margin-top: 3.0rem;
  margin-bottom: 3.0rem;
  @media (max-width: 767px){
    margin-top: 1rem;
    margin-bottom: 1.0rem;
  }
`

export default Login;
