import * as actionTypes from './types'
import { HOME_URL } from "config/constants"

export const login = (username, password, redirectUrl) => ({
  type: actionTypes.LOGIN,
  meta: {
    async: true,
    auth: true,
    body: {
      username: username,
      password: password,
    },
  },
  payload: {
    redirectUrl,
  },
});

export const postCustomer = (data) => ({
  type: actionTypes.POST_CUSTOMER,
  meta: {
    async: true,
    blocking: true,
    path: `/v3/pro/customers.json`,
    method: "POST",
    body: data
  },
  payload: {
  },
});

export const updatePassword = (data) => ({
  type: actionTypes.UPDATE_PASSWORD,
  meta: {
    async: true,
    blocking: true,
    path: `/v3/pro/customers/password.json`,
    method: "POST",
    body: data
  },
  payload: {
  },
});

export const forgotPassword = (email) => ({
  type: actionTypes.FORGOT_PASSWORD,
  meta: {
    async: true,
    blocking: true,
    path: `/v3/pro/customers/forgotpassword.json`,
    method: "GET",
    params: {email:email, uri:`${HOME_URL}/reinitialisation-mot-de-passe`}
  },
  payload: {
  },
});

export const resetPassword = (password, passwordConfirmation, token) => ({
  type: actionTypes.RESET_PASSWORD,
  meta: {
    async: true,
    blocking: true,
    path: `/v3/pro/customers/resetpassword.json`,
    method: "POST",
    body: {password: password, passwordConfirmation: passwordConfirmation, token: token}
  },
  payload: {
  },
});

export const getMe = () => ({
  type: actionTypes.ME,
  meta: {
    async: true,
    blocking: true,
    path: `/v3/pro/customers/me.json`,
    method: "GET"
  },
  payload: {
  },
});

export const updateMe = (data) => ({
  type: actionTypes.ME_UPDATE,
  meta: {
    async: true,
    blocking: true,
    path: `/v3/pro/customers/me.json`,
    method: "PUT",
    body: data
  },
  payload: {
  },
});

export const logout = () => ({
  type: actionTypes.LOGOUT,
});

export const getOrders = () => ({
  type: actionTypes.GET_ORDERS,
  meta: {
    async: true,
    blocking: true,
    path: `/v3/pro/customers/orders.json`,
    method: "GET"
  },
  payload: {
  },
});

export const putOrder = (orderType, orderId, data) => ({
  type: actionTypes.PUT_ORDER,
  meta: {
    async: true,
    blocking: true,
    path: `/v3/pro/customers/${orderType}/order/${orderId}.json`,
    method: "PUT",
    body: data
  },
  payload: {
    orderType: orderType,
    orderId: orderId
  },
});

export const getOrderByToken = (token, data) => ({
  type: actionTypes.GET_ORDER_BY_TOKEN,
  meta: {
    async: true,
    blocking: true,
    path: `/v3/pro/customers/${token}/orders/bytoken.json`,
    method: "POST",
    body: data
  },
  payload: {
  },
});
