import React from "react";
import PropTypes from "prop-types";
import styled from '@emotion/styled'
import { Row, Col } from 'react-bootstrap'
import * as utils from "utils/utils"

const MetaInfo = (props) => {

  const {
    text,
    price,
    priceTtc,
    big
  } = props

  return (
      <Info noGutters className="align-items-center">
        <Col xs={4}>
          <p className={big && "big"}>{text}</p>
        </Col>
        <Col xs={8}>
          <p className={big ? "big text-right" : "text-right"}>
             <span>{priceTtc > 0 && utils.centToEuros(priceTtc) + " TTC / "}</span>{price > 0 && utils.centToEuros(price)}{price && " HT"}
          </p>
        </Col>
      </Info>
  );

};

const Info = styled(Row)`
  padding: 0.3rem 0.5rem;
  border-top: 1px solid ${props => props.theme.colors.lightgrey};
  font-family: '${props => props.theme.fonts.family.main}';
  .big{
    font-weight: bold;
    text-transform: uppercase;
    color : ${props => props.theme.colors.primary};
    font-size: ${props => props.theme.fonts.size.s};
    span{
      color: grey;
      font-weight: normal;
    }
  }
  p{
    margin-bottom: 0rem;
    font-size: ${props => props.theme.fonts.size.xs};
    color: ${props => props.theme.colors.grey};
  }
  span{
    color: ${props => props.theme.colors.primary};
  }
`;

MetaInfo.propTypes = {
  text : PropTypes.string,
  price : PropTypes.number,
  big: PropTypes.bool
};

export default MetaInfo;
