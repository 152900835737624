import React from "react";
import { Row, Col } from 'react-bootstrap'
import Button from "styles/Button"
import styled from "@emotion/styled";
import logo from "assets/images/cocktail-festif-22/cocktail-festif-logo.svg"
import ambiance from "assets/images/offre-classique.jpg"
import { useDispatch } from 'react-redux';
import { operations as modalsOperations } from 'state/ducks/client/modals';
import { Link } from "react-router-dom";

const FakeCocktail = (props) => {

  const dispatch = useDispatch();

  return (
    
        <Col xs={12} sm={12} md={12} id="plateau-classique">
          <Background background={ambiance}>
            <div className="now">En ce moment</div>
            <Row noGutters className="align-items-center">
              <Col xs={12} sm={12} md={4}>
                <Brand>
                  {/* <img src={logo} alt="Mon plateau classique" /> */}
                  <h2>MON PLATEAU<br/>CLASSIQUE</h2>
                  <hr />
                  <p>Pour vos réunions d’équipe<br/>et séminaires, notre sélection de<br/>déjeuners savoureux et copieux</p>
                  <Link to="/files/b2b-formules-classiques.pdf" target="_blank">
                    <Button padding="1rem 1.5rem" color="#f7b078" >Découvrir</Button>
                  </Link>
                  <span onClick={() => dispatch(modalsOperations.setModalStatusContact(true, null, null))}>
                    <Button padding="1rem 1.5rem" margin="0rem 1rem" color="#FFFFFF" >Commander</Button>
                  </span>
                </Brand>
              </Col>
              <Col xs={12} sm={12} md={8}>
                
              </Col>
            </Row>
          </Background>
        </Col>
      
  );
};

const Background = styled.div`
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  padding: 2.5rem 2rem;
  height: 100%;
  @media (max-width: 767px){
    padding: 1.0rem 0.5rem;
  }
  background-image: url(${props => props.background});
  background-size: cover;
  background-position: center bottom;
  .now{
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: black;
    color: white;
    padding: 0.3rem;
    font-size: ${props => props.theme.fonts.size.xs};
    border-radius: 4px;
  }
`;

const Brand = styled.div`
  h2{
    color: white;
    font-weight: bold;
  }
  text-align: center;
  @media (max-width: 767px){
    margin: 0.5rem 0 1rem 0;
  }
  img{
    max-width: 200px;
    @media (max-width: 767px){
      max-width: 170px;
    }
  }
  hr{
    border-top: 1px solid rgba(255,255,255, 0.6);
  }
  p{
    color: white;
    font-size: ${props => props.theme.fonts.size.s};
  }
  @media (max-width: 767px){
  }
  button{
    color: black !important;
    margin-bottom: 1rem;
    &:hover{
      color: black !important;
    }
  }
`;

export default FakeCocktail;
