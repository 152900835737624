/**
 * Calcule le total du panier
 */
export const updateTotal = (cart) => {

  let totals = {
    totalPriceHt                  : 0,
    totalPriceHtWithoutPromotion  : 0,
    totalPriceTtc                 : 0,
    totalPriceTtcWithoutPromotion : 0,
    taxes: []
  }

  // Frais de livraison
  if (cart?.deliveryInfo) {
    totals.totalPriceHt += cart.deliveryInfo.deliveryCostHt;
    totals.totalPriceTtc += cart.deliveryInfo.deliveryCostTtc;
    totals.taxes.push({amount:cart.deliveryInfo.deliveryCostHt * cart.deliveryInfo.taxRate, rate:parseFloat(cart.deliveryInfo.taxRate)})
  }

  // La quantité de couvert correspond à la quantité de groupe de produit
  let quantitySilverware = 0;
  let quantitySecondSilverware = 0;

  // Articles
  if (cart?.productRanges) {
    cart.productRanges.forEach(range => {

      if (range?.articles) {
        range.articles.forEach(article => {
          let quantity = article.quantity;
          let price    = article.price;
          let taxRate  = parseFloat(article.taxRate);
          if (article?.type === 'group') {
            quantitySilverware += quantity;
          }
          else {
            quantitySecondSilverware += quantity;
          }

          totals.totalPriceHt += (price * quantity);
          totals.totalPriceTtc += (price * (1 + taxRate) * quantity);

          let taxIndex = totals.taxes.findIndex(element => parseFloat(element.rate) === taxRate);

          if (taxIndex > -1) {
            totals.taxes[taxIndex].amount = totals.taxes[taxIndex].amount + (price * taxRate * quantity);
          }
          else {
            totals.taxes.push({amount:price * taxRate * quantity, rate:taxRate})
          }
        });
      }

    });
  }

  // Les couverts
  if (cart?.silverwareInfo) {
    cart.silverwareInfo.forEach(article => {
      if (article.value === true) {
        let quantity = quantitySilverware > 0 ? quantitySilverware : quantitySecondSilverware;
        let price    = article.price;
        let taxRate  = parseFloat(article.taxRate);

        totals.totalPriceHt += (price * quantity);
        totals.totalPriceTtc += (price * (1 + taxRate) * quantity);

        let taxIndex = totals.taxes.findIndex(element => parseFloat(element.rate) === taxRate);
        if (taxIndex > -1) {
          totals.taxes[taxIndex].amount = totals.taxes[taxIndex].amount + (price * taxRate * quantity);
        }
        else {
          totals.taxes.push({amount:price * taxRate * quantity, rate:taxRate})
        }
      }
    })
  }

  return totals;
}

/**
 * Met à jour les infos des couverts en fonction des articles
 */
export const updateSilverwareInfo = (cart) => {
  let sw = [];

  if (cart?.productRanges) {
    cart.productRanges.forEach(range => {

      if (range?.articles) {
        range.articles.forEach(article => {
          if (article?.products) {
            article.products.forEach(product => {
              if (product?.mySilverwares) {
                product.mySilverwares.forEach(silverware => {
                  if (sw.find(element => element.id === silverware.id) === undefined) {
                    // regarde la valeur du couvert avant l'update s'il était déja présent
                    silverware.value = silverware.recommended;
                    if (cart?.silverwareInfo) {
                      let oldSw = cart.silverwareInfo.find(element => element.id === silverware.id);
                      if (oldSw !== undefined) {
                        silverware.value = oldSw.value;
                      }
                    }
                    sw.push(silverware)
                  }
                })
              }
            })
          }

          if (article?.mySilverwares) {
            article.mySilverwares.forEach(silverware => {
              if (sw.find(element => element.id === silverware.id) === undefined) {
                // regarde la valeur du couvert avant l'update s'il était déja présent
                silverware.value = silverware.recommended;
                if (cart?.silverwareInfo) {
                  let oldSw = cart.silverwareInfo.find(element => element.id === silverware.id);
                  if (oldSw !== undefined) {
                    silverware.value = oldSw.value;
                  }
                }
                sw.push(silverware)
              }
            })
          }
        })
      }

    })
  }

  return sw;
}
