import moment from "moment";
export const isAuthenticated = state => state.api.customer.isAuthenticated
export const isLoading = state => state.api.customer.isLoading
export const getCustomer = state => state.api.customer.data
export const getOrders = state => state.api.customer.orders
export const getAllCustomer = state => state.api.customer

/**
 * Les commandes en état de devis
 */
export const getQuotations = (state) => {
  let quotations = [];
  if (state?.api?.customer?.orders) {
    state?.api?.customer?.orders.map((quotation, index) => {
      if(['101', '102', '103', '400', '401', '402', '411', '412', '413'].includes(quotation.status)){
        return quotations = [...quotations, quotation]
      }else{
        return null
      }
    })

    quotations.sort(function(a,b){ return moment(b.eventDate) - moment(a.eventDate) });

  }
  return quotations;
}

/**
 * Les commandes en état de facture
 */
export const getInvoices = (state) => {
  let invoices = [];
  if (state?.api?.customer?.orders) {
    state?.api?.customer?.orders.map((invoice, index) => {
      if(['201', '203'].includes(invoice.status)){
        return invoices = [...invoices, invoice]
      }else{
        return null
      }
    })

    invoices.sort(function(a,b){ return moment(b.eventDate) - moment(a.eventDate) });

  }
  return invoices;
}
