import React from "react";
import styled from "@emotion/styled";
import { Container, Row, Col } from 'react-bootstrap'
import {PARTNERS_LOGOS} from "config/constants"
import Slider from "react-slick";

// Bandeau des logos des clients
const SliderPartners = (props) => {

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplaySpeed: 5000,
    autoplay: true,
    responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            autoplaySpeed: 5000,
            autoplay: true,
          }
        },
      ]
  };

  return (
    <Background>
      <Container>
        <Row>
          <Col>
            <Title>15 années à vos côtés</Title>
          </Col>
        </Row>
        <Slider {...settings}>
          {PARTNERS_LOGOS.map((logo, index) =>
          <div key={index}>
            <img src={logo.img} alt={logo.name} />
          </div>
          )}
        </Slider>
      </Container>
    </Background>
  );

};

const Background = styled.div`
  background-color: white;
  padding: 2rem 1rem;
  @media (max-width: 767px){
    padding: 0.5rem;
  }
  text-align:center;
  img{
    padding: 0.5rem 0.2rem;
    width: 160px;
    max-width: 100%;
  }
`;

const Title = styled.h3`
  font-weight: bold;
  margin-top: 1rem;
  text-transform: uppercase;
`;

export default SliderPartners;
