import React, {useState} from 'react'
import { useSelector, shallowEqual } from "react-redux";
import Item from "./Item"
import PropTypes from "prop-types";
import moment from "moment";
import { selectors as cartSelectors } from "state/ducks/client/cart";
import { selectors as productRangeSelectors } from "state/ducks/api/productRange";

const ItemContainer = (props) => {

  const {
    item,
    color,
    categoryName
  } = props

  const currentRange = useSelector(
    (state) => productRangeSelectors.getCurrentRange(state),
    shallowEqual
  );

  const article = useSelector(
    (state) => cartSelectors.getArticleFromItemAndRange(state, currentRange, item),
    shallowEqual
  );

  const deliveryDate = useSelector(
    (state) => cartSelectors.getDeliveryDate(state),
    shallowEqual
  );

  const [quantity] = useState(article?.quantity ? article.quantity : 0);

  const [isOpenModalDetailProduct, setIsOpenModalDetailProduct] = useState(false)

  const handleCloseModalDetailProduct = () => {
    setIsOpenModalDetailProduct(false)
  }

  /**
   * Ouvre la popup + GTM
   */
  const handleOpenModalDetailProduct = () => {
    setIsOpenModalDetailProduct(true);
    window.dataLayer.push({
      event : 'product_details_view',
      ecommerce: {
        detail: {
          products:[{
            name    :item.name,
            id      :item.type === 'product' ? item.genericId : item.id,
            price   :item.price / 100,
            category:categoryName,
            brand   :'Le Moulin B2B',
            variant: currentRange.name
          }]
        }
      }
    });
  }

  // Dispo du produit
  let able = false;
  if (item?.availableDates) {
    able = item?.availableDates.includes(moment(deliveryDate).format('YYYY-MM-DD'));
  }

  return (
    able &&
      <Item
        quantity={quantity}
        item={item}
        range={currentRange}
        isOpenModalDetailProduct={isOpenModalDetailProduct}
        handleOpenModalDetailProduct={handleOpenModalDetailProduct}
        handleCloseModalDetailProduct={handleCloseModalDetailProduct}
        color={color}
        article={article}
        able={able}
        categoryName={categoryName}
      />
  );

};

ItemContainer.propTypes = {
  item: PropTypes.object,
  color: PropTypes.string,
  categoryName: PropTypes.string
};

export default ItemContainer;
