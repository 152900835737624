import React from "react";
import { Container, Row, Col } from 'react-bootstrap'
import styled from "@emotion/styled";
import Layout from "views/commons/Layout/Layout";
import esus from "assets/images/labels/label-esus.png"
import equitable from "assets/images/labels/label-ville-lyon-economie-durable.png"
import Tippy from '@tippyjs/react';

const Value = (props) => {

  return (
    <Layout>
      <ContainerBtp>
        <Row>
          <Col xs={12} sm={6}>
            <Title>NOTRE VISION : UNE ENTREPRISE CITOYENNE</Title>
            <SubTitle>Agissons sur tous les fronts !</SubTitle>
            <p>
            Le bien-manger, l'écologie, notre organisation au sein des Moulin, nous essayons de construire une entreprise citoyenne, qui ne met de côté aucun des enjeux de notre société.
            </p>
            <p>
            Les labels « Entreprise solidaire » et « Lyon Ville Equitable et Durable » attestent de nos démarches.
            </p>
            <Labels>
              <Tippy content={"Nous sommes certifiés Entreprise solidaire d'utilité sociale"}>
                <img style={{ 'marginRight' : '0.5rem' }} src={esus} alt="Label entreprise solidaire d'utilité sociale"/>
              </Tippy>
              <Tippy content={"Nous sommes certifiés Lyon ville équitable & durable"}>
                <img src={equitable} alt="Label lyon ville équitable et durable"/>
              </Tippy>
            </Labels>
          </Col>
          <Col xs={12} sm={6}>
            <div style={{ "width" : "width:100%"}}>
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/DpPlRsObPTg?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </Col>
        </Row>
      </ContainerBtp>
      <div style={{ "background-color" : "white"}}>
        <ContainerBtp2>
          <Row>
            <Col xs={12} sm={12} style={{ "textAlign" : "center"}}>
              <Title2>OÙ VA L’ARGENT D’UN DÉJEUNER ?</Title2>
              <SubTitle>Parce que la transparence, c’est la confiance !</SubTitle>
              <hr/>
            </Col>

            <Col xs={12} sm={12}>
              <Section>Matières premières 34 %</Section>
              <SectionSub>Soit 3€40 sur un repas à 10€</SectionSub>
              <p>
                Chaque jour, nous travaillons à partir de produits frais, transformés par nos soins pour la plupart*, afin de vous préparer de délicieux plats et desserts faits maison. Nous voulons avancer vers toujours plus de local et de bio, tout en maintenant des prix accessibles.
              </p>
              <p>
                <small><i>* Hors avocat, compote que nous recevons transformés</i></small>
              </p>
              <hr/>
            </Col>

            <Col xs={12} sm={12} style={{ "text-align" : "right"}}>
              <Section>Main d’oeuvre 46 %</Section>
              <SectionSub>Soit 4€60 sur un repas à 10€</SectionSub>
              <p>
                Les rémunérations des collaborateurs du Moulin, ainsi que la prime d’intéressement (40% du bénéfice partagés entre tous). Les postes sont majoritairement des temps pleins, en CDI ; c’est avec une équipe stable que nous pouvons construire des projets !
              </p>
              <hr/>
            </Col>

            <Col xs={12} sm={12}>
              <Section>Charges de fonctionnement 18,5 %</Section>
              <SectionSub>Soit 1€85 sur un repas à 10€</SectionSub>
              <p>
                Les loyers, l’entretien de notre matériel et de nos Charrettes, ainsi que les autres frais pour faire tourner le Moulin !
              </p>
              <hr/>
            </Col>

            <Col xs={12} sm={12} style={{ "text-align" : "right"}}>
              <Section>Bénefices 1,5 %</Section>
              <SectionSub>Soit 0€15 sur un repas à 10€</SectionSub>
              <p>
                Les bénéfices sont exclusivement utilisés pour grandir et aller plus loin dans nos valeurs !
              </p>
            </Col>

            <Col xs={12} style={{ "text-align" : "center"}}>
              <hr />
              <p>
                <small><i>Chiffres issus du compte de résultats 2018-2019</i></small>
              </p>
              <p>Une prime d’intéressement permet de distribuer 40% de notre résultat à toute l’équipe si les objectifs de pérennité sont atteints.</p>
              <p>
              L’intégralité des bénéfices de la structure est utilisée pour grandir et aller plus loin dans nos valeurs.</p>
              <p>
              Le capital est détenu à 100% pour des personnes engagées dans le projet !
              </p>
              <p>
              L’équipe compte à l’heure actuelle 70 collaborateurs.
              </p>
              <p>
              La rémunération moyenne est de 1350€ net (soit 1730€ brut) en équivalent temps plein, allant du SMIC à 2150 € net pour l’équipe de cogérance.
              </p>
            </Col>
          </Row>
        </ContainerBtp2>
      </div>
    </Layout>
  );
};

const ContainerBtp = styled(Container)`
  font-family: '${props => props.theme.fonts.family.main}';
  padding: 3.0rem 0.5rem;
`;

const ContainerBtp2 = styled(Container)`
  font-family: '${props => props.theme.fonts.family.main}';
  padding: 3.0rem 0.5rem;
  max-width: 740px;
`;

const Title = styled.h1`
  font-family: '${props => props.theme.fonts.family.main}';
  font-size: ${props => props.theme.fonts.size.xxxl};
`;

const Title2 = styled.h2`
  font-family: '${props => props.theme.fonts.family.main}';
  font-size: ${props => props.theme.fonts.size.xxl};
`;

const SubTitle = styled.p`
  font-family: '${props => props.theme.fonts.family.script}';
  color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fonts.size.m};
`;

const Section = styled.p`
  font-family: '${props => props.theme.fonts.family.main}';
  font-size: ${props => props.theme.fonts.size.xxl};
`;

const SectionSub = styled.p`
  font-family: '${props => props.theme.fonts.family.script}';
  color: ${props => props.theme.colors.primary};
  font-size: ${props => props.theme.fonts.size.xs};
`;

const Labels = styled.div`
  img{
    max-width: 120px;
  }
`;

export default Value;
