import React from 'react'
import ItemDetail from "./ItemDetail"
import PropTypes from "prop-types";

const ItemDetailContainer = (props) => {

  const {
    item,
    color
  } = props

  return (
    <ItemDetail item={item} color={color}/>
  )
}


ItemDetailContainer.propTypes = {
  item: PropTypes.object,
  color: PropTypes.string
};


export default ItemDetailContainer;
