import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import ResetPassword from "./ResetPassword"
import { Redirect } from "react-router-dom"
import queryString from 'query-string';
import { selectors as customerSelectors, operations as customerOperations } from "state/ducks/api/customer";

const ResetPasswordContainer = (props) => {

  const dispatch = useDispatch();

  // On récupere le token de reinitialisation de mot de passe)
  const token = queryString.parse(props.location.search).token;

  const isLoading = useSelector(
    (state) => customerSelectors.isLoading(state),
    shallowEqual
  );

  const onSubmit = (values) => {
    dispatch(customerOperations.resetPassword(values.password, values.passwordConfirmation, token));
  }

  if ( token === undefined ) {
    return <Redirect to="/" />
  }

  return (
    <ResetPassword isLoading={isLoading} onSubmit={onSubmit}/>
  );
};

export default ResetPasswordContainer;
