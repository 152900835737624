import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import queryString from 'query-string';
import { selectors as cartApiSelectors } from "state/ducks/api/cart";
import ThanksOrder from "./ThanksOrder"
import { operations as modalsOperations } from 'state/ducks/client/modals'

const ThanksOrderContainer = (props) => {

  const dispatch = useDispatch();

  // On différencie la vue en fonction du param (commande/devis)
  const type = queryString.parse(props.location.search).type;

  const invoiceUrl = useSelector(
    (state) => cartApiSelectors.getInvoiceUrl(state),
    shallowEqual
  );

  const handleContactModal = (title, description) => {
    dispatch(modalsOperations.setModalStatusContact(true, title, description))
  }

  return (
    <ThanksOrder type={type} invoiceUrl={invoiceUrl} handleContactModal={handleContactModal}/>
  );
};

export default ThanksOrderContainer;
