export const CLEAN = 'api/cart/CLEAN';

export const SET_IS_OPEN = 'client/cart/SET_IS_OPEN'

export const UPDATE_CUSTOMER_INFO    = 'client/cart/UPDATE_CUSTOMER_INFO';
export const UPDATE_DELIVERY_INFO    = 'client/cart/UPDATE_DELIVERY_INFO';
export const UPDATE_BILLING_INFO     = 'client/cart/UPDATE_BILLING_INFO';
export const UPDATE_DELIVERY_ADDRESS = 'client/cart/UPDATE_DELIVERY_ADDRESS';

export const ADD_ARTICLE             = 'client/cart/ADD_ARTICLE';
export const REMOVE_ARTICLE          = 'client/cart/REMOVE_ARTICLE';
export const CHANGE_QUANTITY_ARTICLE = 'client/cart/CHANGE_QUANTITY_ARTICLE';
export const RESET_ARTICLES          = 'client/cart/RESET_ARTICLES';

export const UPDATE_TOTAL = 'client/cart/UPDATE_TOTAL';

export const UPDATE_SILVERWARE_INFO = 'client/cart/UPDATE_SILVERWARE_INFO';
export const CHANGE_SILVERWARE_VALUE = 'client/cart/CHANGE_SILVERWARE_VALUE';

export const VALIDATE_DELIVERY_ADDRESS           = 'client/cart/VALIDATE_DELIVERY_ADDRESS';
export const VALIDATE_DELIVERY_ADDRESS_COMPLETED = 'client/cart/VALIDATE_DELIVERY_ADDRESS_COMPLETED';
export const VALIDATE_DELIVERY_ADDRESS_FAILED    = 'client/cart/VALIDATE_DELIVERY_ADDRESS_FAILED';
