import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import SignIn from 'views/commons/Connexion/Tabs/SignIn'
import { operations as customerOperations, selectors as customerSelectors } from "state/ducks/api/customer";

const SignInContainer = (props) => {

  const dispatch = useDispatch();

  const isLoading = useSelector(
    (state) => customerSelectors.isLoading(state),
    shallowEqual
  );

  const onSubmit = (values) => {
    dispatch(customerOperations.login(values.email, values.password))
  }

  const onSubmitRequiredPassword = (values) => {
    dispatch(customerOperations.forgotPassword(values.email))
  }

  return(
    <SignIn onSubmit={onSubmit} isLoading={isLoading} onSubmitRequiredPassword={onSubmitRequiredPassword}/>
  )
}

export default SignInContainer;
