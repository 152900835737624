import * as actionTypes from './types'
// eslint-disable-next-line
import { fakeData } from './fakeData.js'
import { createReducer } from '../../../utils'

const initialState = {
  isInit: false,
  isLoading: false,
  isLoadingEditPassword: false,
  isLoadingUpdateMe: false,
  data: null,
  token: window.localStorage.getItem("token"),
  isAuthenticated: false,
  orders: []
};

const reducers = createReducer( initialState )( {

  [actionTypes.LOGIN]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.LOGIN_COMPLETED]: (state, action) => {
    return {
      ...state,
      redirectAfterLogin: action.payload.redirectUrl,
      isAuthenticated: true,
      isLoading: false,
      token: action.payload.result,
    };
  },
  [actionTypes.LOGIN_FAILED]: (state, action) => {
    return { ...state, isLoading: false, isInit: true };
  },

  [actionTypes.LOGOUT]: (state, action) => {
    window.localStorage.removeItem("token");
    return initialState;
  },

  [actionTypes.POST_CUSTOMER]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.POST_CUSTOMER_COMPLETED]: (state, action) => {
    return {
      ...state,
      isLoading: false
    };
  },
  [actionTypes.POST_CUSTOMER_FAILED]: (state, action) => {
    return { ...state, isLoading: false, isInit: true };
  },

  [actionTypes.FORGOT_PASSWORD]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.FORGOT_PASSWORD_COMPLETED]: (state, action) => {
    return {
      ...state,
      isLoading: false
    };
  },
  [actionTypes.FORGOT_PASSWORD_FAILED]: (state, action) => {
    return { ...state, isLoading: false, isInit: true };
  },

  [actionTypes.UPDATE_PASSWORD]: (state, action) => {
    return { ...state, isLoadingEditPassword: true };
  },
  [actionTypes.UPDATE_PASSWORD_COMPLETED]: (state, action) => {
    return {
      ...state,
      isLoadingEditPassword: false
    };
  },
  [actionTypes.UPDATE_PASSWORD_FAILED]: (state, action) => {
    return { ...state, isLoadingEditPassword: false, isInit: true };
  },

  [actionTypes.RESET_PASSWORD]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.RESET_PASSWORD_COMPLETED]: (state, action) => {
    return {...state, isLoading: false };
  },
  [actionTypes.RESET_PASSWORD_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },

  [actionTypes.ME]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.ME_COMPLETED]: (state, action) => {
    return {
      ...state,
      isAuthenticated: true,
      isLoading: false,
      data: action.payload.result,
    };
  },
  [actionTypes.ME_FAILED]: (state, action) => {
    return { ...state, isLoading: false, isInit: true };
  },

  [actionTypes.ME_UPDATE]: (state, action) => {
    return { ...state, isLoadingUpdateMe: true };
  },
  [actionTypes.ME_UPDATE_COMPLETED]: (state, action) => {
    return {
      ...state,
      isLoadingUpdateMe: false,
      customer: action.payload.result,
    };
  },
  [actionTypes.ME_UPDATE_FAILED]: (state, action) => {
    return { ...state, isLoadingUpdateMe: false };
  },

  [actionTypes.GET_ORDERS]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.GET_ORDERS_COMPLETED]: (state, action) => {
    return {
      ...state,
      isLoading: false,
      orders: action.payload.result,
    };
  },
  [actionTypes.GET_ORDERS_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },

  [actionTypes.PUT_ORDER]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.PUT_ORDER_COMPLETED]: (state, action) => {
    let orderId    = action.payload.orderId;
    let orderIndex = state.orders.findIndex(element => element.id === orderId);

    if (orderIndex < 0) {
      return {
        ...state
      }
    }
    return {
      ...state,
      isLoading: false,
      orders: [
        ...state.orders.slice(0, orderIndex),
        {
          ...state.orders[orderIndex],
          ...action.payload.result
        },
        ...state.orders.slice(orderIndex + 1)
      ]
    };
  },
  [actionTypes.PUT_ORDER_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [actionTypes.GET_ORDER_BY_TOKEN]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.GET_ORDER_BY_TOKEN_COMPLETED]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [actionTypes.GET_ORDER_BY_TOKEN_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },

});

export default reducers;
