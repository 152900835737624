import React from "react";
import Layout from "views/commons/Layout/Layout";
import { Container, Button } from 'react-bootstrap'
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const Page404 = (props) => {

  return (
    <Layout>
      <Container>
        <Card>
          Oups, cette page n'existe pas.<br/><br/>
          <Link to="/"><Button>Retour à l'accueil</Button></Link>
        </Card>
      </Container>
    </Layout>
  );
};

const Card = styled.div`
  padding: 2.0rem;
  background-color: white;
  border-radius: 6px;
  margin: 2rem 0;
  text-align: center;
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.02);
`;

export default Page404;
