import * as actions from './actions'
import { operations as notificationsOperations } from 'state/ducks/api/notifications';
import {operations as operationsModals } from "state/ducks/client/modals"
const logout = actions.logout;
const getMe = actions.getMe;
const getOrders = actions.getOrders;

const login = (email, password, redirectUrl) => ( dispatch ) => {
  return dispatch(actions.login(email, password, redirectUrl))
  .then(succes => {
      dispatch(actions.getMe());
      dispatch(operationsModals.setModalStatusConnexion(false));
      dispatch(notificationsOperations.setNotificationSuccess("Vous êtes connecté, bienvenue !"))
    }
  )
  .catch(error => {});
}

const createCustomer = (customer) => ( dispatch ) => {
  return dispatch(actions.postCustomer(customer)).then(
    (success) => {
      dispatch(operationsModals.setModalStatusConnexion(false));
      dispatch(notificationsOperations.setNotificationSuccess("Bienvenue au Moulin !"))
    }
  );
}

const forgotPassword = (customer) => ( dispatch ) => {
  dispatch(actions.forgotPassword(customer)).then(
    (success) => {
      dispatch(notificationsOperations.setNotificationSuccess("Nous venons de vous envoyer un email de reinitialisation de mot de passe."))
    }
  );
}

const updateCustomer = (customer) => ( dispatch ) => {
  dispatch(actions.updateMe(customer)).then(
    (success) => {
      dispatch(actions.getMe());
      dispatch(notificationsOperations.setNotificationSuccess("Mise à jour effectuée"))
    }
  );
}

const updatePassword = (values) => (dispatch) => {
  dispatch(actions.updatePassword(values)).then(
    (success) => {
      dispatch(notificationsOperations.setNotificationSuccess("Mise à jour effectuée"))
    }
  );
}

const resetPassword = (password, passwordConfirmation, token) => (dispatch) => {
  dispatch(actions.resetPassword(password, passwordConfirmation, token)).then(
    (success) => {
      dispatch(notificationsOperations.setNotificationSuccess("Votre mot de passe a bien été modifié. Vous pouvez maintenant vous connecter."));
    }
  );
}

const cancelOrder = (orderType, orderId) => (dispatch) => {
  return dispatch(actions.putOrder(orderType, orderId, {statusText: 'cancel'}));
}

const confirmOrder = (orderType, orderId) => (dispatch) => {
  return dispatch(actions.putOrder(orderType, orderId, {statusText: 'confirm'}));
}

const generateQuotation = (orderType, orderId) => (dispatch) => {
  return dispatch(actions.putOrder(orderType, orderId, {statusText: 'generate'}));
}

const getOrderByToken = (token, data) => (dispatch) => {
  return dispatch(actions.getOrderByToken(token, data));
}

export {
    login,
    logout,
    createCustomer,
    getMe,
    updateCustomer,
    forgotPassword,
    updatePassword,
    resetPassword,
    getOrders,
    cancelOrder,
    confirmOrder,
    generateQuotation,
    getOrderByToken
};
