import React from 'react'
import DisplayBanner from "./DisplayBanner"
import PropTypes from "prop-types";

const DisplayBannerContainer = (props) => {

  const {
    banner
  } = props

  return (<DisplayBanner banner={banner} />);

};

DisplayBannerContainer.propTypes = {
  banner: PropTypes.object
};

export default DisplayBannerContainer;
