import React from "react";
import Tippy from '@tippyjs/react';
import vegan from "assets/images/attr/badge-vegan.svg"
import vege from "assets/images/attr/badge-vegetarien.svg"
import bio from "assets/images/attr/badge-ab-bio.svg"
import nomilk from "assets/images/attr/badge-sans-lactose.svg"
import nogluten from "assets/images/attr/badge-sans-gluten.svg"
import styled from "@emotion/styled";
import PropTypes from "prop-types";

const AttributProduct = (props) => {

  const {
    characteristics
  } = props;

  return (
    <div>
      {characteristics?.map((characteristic, index) =>
        characteristic === "bio" ?
          <Tippy content={"Bio"} key={index}>
            <SVG src={bio} alt={"Bio"} />
          </Tippy>
        : characteristic === "vege" ?
          <Tippy content={"Végétarien"} key={index}>
            <SVG src={vege} alt={"Végétarien"} />
          </Tippy>
        : characteristic === "vegan" ?
          <Tippy content={"Végan"} key={index}>
            <SVG src={vegan} alt={"Végan"} />
          </Tippy>
        : characteristic === "noLactose" ?
          <Tippy content={"Sans lactose"} key={index}>
            <SVG src={nogluten} alt={"Sans lactose"} />
          </Tippy>
        : characteristic === "noGluten" ?
          <Tippy content={"Sans gluten"} key={index}>
            <SVG src={nomilk} alt={"Sans gluten"} />
          </Tippy>
        : null
      )
      }



    </div>
  );

};

const SVG = styled.img`
  max-width: 25px;
`

AttributProduct.propTypes = {
  characteristics: PropTypes.array,
};

export default AttributProduct;
