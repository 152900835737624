import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from 'react-router-dom'
import moment from "moment";
import Range from "views/pages/Mealtray/Range/Range"
import { operations as rangeOperations, selectors as rangeSelectors } from "state/ducks/api/productRange";
import { selectors as cartSelectors } from "state/ducks/client/cart";
import { selectors as marketingSelectors } from "state/ducks/api/marketing";

const RangeContainer = () => {

  const dispatch = useDispatch();
  const {slug}   = useParams();

  const ranges = useSelector(
    (state) => rangeSelectors.getRanges(state),
    shallowEqual
  );

  const currentRange = useSelector(
    (state) => rangeSelectors.getRangeBySlug(state, slug),
    shallowEqual
  );

  const cart = useSelector(
    (state) => cartSelectors.getCart(state),
    shallowEqual
  );

  const deliveryDate = useSelector(
    (state) => cartSelectors.getDeliveryDate(state),
    shallowEqual
  );

  const addressDeliverable = useSelector(
    (state) => cartSelectors.isAddressDeliverable(state),
    shallowEqual
  );

  const dateAvailable = useSelector(
    (state) => cartSelectors.isDateAvailable(state),
    shallowEqual
  );

  const displayBanners = useSelector(
    (state) => marketingSelectors.getDisplayBanners(state),
    shallowEqual
  );

  useEffect(() => {
    if (!ranges) {
      dispatch(rangeOperations.initialize(slug));
    }
    dispatch(rangeOperations.setCurrentRangeSlug(slug));
  }, [dispatch, slug, ranges]);

  let rangeAvailable = true;
  // Si la date actuelle n'est pas disponible
  if (currentRange?.availableDates) {
    if (currentRange.availableDates.length < 1) {
      rangeAvailable = false
    }
    else {
      rangeAvailable = currentRange.availableDates.includes((moment(deliveryDate).format('YYYY-MM-DD')));
    }
  }

  return (
    <Range
      cart={cart}
      range={currentRange}
      dateAvailable={dateAvailable}
      addressDeliverable={addressDeliverable}
      rangeAvailable={rangeAvailable}
      displayBanners={displayBanners}
    />
  );

};

export default RangeContainer;
