import React from "react";
import { Row, Col } from 'react-bootstrap'
import styled from "@emotion/styled";
import { SOURCE_URI } from "config/constants"
import AttributProductContainer from "views/commons/Blocks/AttributProduct/AttributProductContainer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import noVisual from "assets/images/no-visual-big.jpg"
import ModalItemDetail from "./Partials/ModalItemDetail"
import QuantityItemContainer from "views/commons/QuantityItem/QuantityItemContainer"
import PropTypes from "prop-types";

const Item = (props) => {

  const {
    item,
    quantity,
    range,
    handleCloseModalDetailProduct,
    handleOpenModalDetailProduct,
    isOpenModalDetailProduct,
    color,
    article,
    able,
    categoryName
  } = props

  return (
  <ColBtp xs={6} sm={4} md={4} lg={3}>
    <Container>
      <Image color={color} onClick={handleOpenModalDetailProduct} background={item?.mediaUris?.normal ? `${SOURCE_URI}/${item.mediaUris.normal}` : noVisual}>
        <div className="inner">
          <div>
            {article?.quantity > 0 &&
              <div className="overlay">
                  {article.quantity} <br /> dans votre panier
              </div>
            }
          </div>
        </div>
        <div className="info"><FontAwesomeIcon icon={faInfoCircle} /></div>
      </Image>
      <Content>
        <Title>{item?.name}</Title>
        <Price>{(item?.price / 100).toFixed(2)} € <small>HT</small>{item.pricePerPerson && " par personne"}</Price>

        {item?.products &&
            item.description && <Description><p>{item.description}</p></Description>
        }
      </Content>
      <Row noGutters className="align-items-center px-3 pb-3">
        <Col xs={12} sm={12} md={6} className="mt-2 text-center">
          <AttributProductContainer characteristics={item?.characteristics} />
        </Col>
        <Col xs={12} sm={12} md={6} className="mt-2">
          <QuantityItemContainer able={able} item={item} range={range} color={color} article={article} categoryName={categoryName}/>
        </Col>
      </Row>
      <ModalItemDetail item={item} range={range} isOpen={isOpenModalDetailProduct} onClose={handleCloseModalDetailProduct} quantity={quantity} color={color} article={article} able={able} categoryName={categoryName}/>
    </Container>
    </ColBtp>
  );
};

const Container = styled.div`
  border: solid 1px #ccc;
  background-color: white;
  border-radius: 4px;
  position: relative;
  margin-bottom: 1rem;
  .button{
    width: 100%;
    font-size: ${props => props.theme.fonts.size.xs};
    background-color: #111;
    color: white;
    padding: 0.5rem;
  }
`;

const Image = styled.div`
  height: 210px;
  @media (max-width: 767px){
   height: 160px;
  }
  width: 100%;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  position: relative;
  img{
    max-width: 100%;
  }
  &:hover{
    .inner{
      transform: scale(1.05);
      overflow: hidden;
      cursor: pointer;
    }
    .info{
      opacity: 1;
    }
  }
  .inner{
    background-image: url('${(props) => props.background && props.background}');
    background-color: grey;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    transition: all .5s;
    display: block;
    position: relative;
    .overlay{
      background-color: rgba(0,0,0,0.5);
      color: white;
      display: flex;
      webkit-box-pack: center;
      justify-content: center;
      flex-direction: column;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      position: absolute;
      text-align: center;
      font-family: '${props => props.theme.fonts.family.script}';
      font-size: ${props => props.theme.fonts.size.s};
      @media (max-width: 767px){
       font-size: ${props => props.theme.fonts.size.xs};
      }
    }
  }
  .info{
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: ${(props) => props.color ? props.color : props.theme.colors.primary};
    color: white;
    padding: 0.5rem;
    opacity: 0.8;
    cursor: pointer;
  }
`;

const Content = styled.div`
  padding: 1.0rem;
`;

const Price = styled.div`
  text-align: center;
  color: #bbb;
  font-size: ${props => props.theme.fonts.size.s};
`;

const Title = styled.div`
  text-align: center;
  font-weight: bold;
  font-family: '${props => props.theme.fonts.family.main}';
  font-size: ${props => props.theme.fonts.size.m};
  @media (max-width: 767px){
   font-size: ${props => props.theme.fonts.size.sm};
  }
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`;

const Description = styled.div`
  text-align: center;
  min-height: 40px;
  color: grey;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  font-family: '${props => props.theme.fonts.family.main}';
  font-size: ${props => props.theme.fonts.size.xs};
  p{
    margin-bottom: 0rem;
  }
`;

const ColBtp = styled(Col)`
  @media (max-width: 767px){
    padding-right: 5px;
    padding-left: 5px;
  }
`

Item.propTypes = {
  item: PropTypes.object,
  quantity: PropTypes.number,
  range: PropTypes.object,
  handleCloseModalDetailProduct: PropTypes.func,
  handleOpenModalDetailProduct: PropTypes.func,
  isOpenModalDetailProduct: PropTypes.bool,
  color:PropTypes.string,
  article: PropTypes.object,
  able: PropTypes.bool,
  categoryName:PropTypes.string
};

export default Item;
