import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from 'react-bootstrap'
import Button from "styles/Button"
import styled from "@emotion/styled";
import ItemContainer from "views/pages/Mealtray/Ranges/Range/Item/ItemContainer"
import { Link } from "react-router-dom";
import { MEALTRAY_OTHER, SOURCE_URI } from "config/constants"
import OptionBadge from "views/commons/Blocks/OptionBadge"

const Range = (props) => {

  const {
    range,
    rangeAble,
    sortItems
  } = props

  return (
    <Container>
      <Row>
        <Col>
          <Background background={range?.backgroundUris?.normal && `${SOURCE_URI}/${range.backgroundUris.normal}`}>
            <Options>
              <Row>
                <Col xs={12}>
                  <OptionBadge orderBeforeDays={range?.orderBeforeDays && range.orderBeforeDays} minAmount={range?.minAmountOrder && range.minAmountOrder} />
                </Col>
              </Row>
            </Options>
            <Row noGutters className="align-items-center">
              <Col xs={12} sm={12} md={3}>
                <Brand>
                  <img src={range?.logoUris?.normal && `${SOURCE_URI}/${range.logoUris.normal}`} alt={range.name} />
                  <hr />
                  <p>{range.description}</p>
                  <Link to={{ pathname: `/plateaux-repas/gamme/${range.slug}`}}>
                    <Button padding="1rem 1.5rem" color={range.color ? range.color : 'grey'} >Commander</Button>
                  </Link>
                </Brand>
              </Col>
              {sortItems.map((item, index) =>
                index <= 1 &&
                  <ItemContainer item={item} link={range.slug} color={range.color ? range.color : 'grey'} rangeAble={rangeAble} key={index}/>
              )}

              <Col xs={12} sm={12} md={3}>
                <ItemContainer item={MEALTRAY_OTHER} link={range.slug} color={range.color ? range.color : 'grey'} rangeAble={true}/>
              </Col>
            </Row>
          </Background>
        </Col>
      </Row>
    </Container>
  );
};

const Background = styled.div`
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  padding: 1.5rem 2rem;
  @media (max-width: 767px){
    padding: 1.0rem 0.5rem;
  }
  background-image: url(${props => props.background});
  background-size: cover;
  background-position: center center;
  margin-bottom: 1.5rem;
`;

const Brand = styled.div`
  text-align: center;
  @media (max-width: 767px){
    margin: 0.5rem 0 1rem 0;
  }
  img{
    max-width: 200px;
    @media (max-width: 767px){
      max-width: 170px;
    }
  }
  hr{
    border-top: 1px solid rgba(255,255,255, 0.6);
  }
  p{
    color: white;
    font-size: ${props => props.theme.fonts.size.s};
  }
`;

const Options = styled.div`
  text-align: right;
  margin-bottom: 1.0rem;
  @media (max-width: 767px){
    text-align: center;
  }
`;

Range.propTypes = {
  range: PropTypes.object,
  sortItems: PropTypes.array,
};

export default Range;
