import React from "react";
import Footer from "./Footer"
import { operations as modalsOperations } from 'state/ducks/client/modals'
import { useDispatch } from 'react-redux';

const FooterContainer = (props) => {

  const dispatch = useDispatch();

  const handleContactModal = (title, description) => {
    dispatch(modalsOperations.setModalStatusContact(true, title, description))
  }

  return (
    <Footer handleContactModal={handleContactModal}/>
  );
};

export default FooterContainer;
