export const CLEAN = 'api/cart/CLEAN';

export const GET_CART           = 'api/cart/GET_CART';
export const GET_CART_COMPLETED = 'api/cart/GET_CART_COMPLETED';
export const GET_CART_FAILED    = 'api/cart/GET_CART_FAILED';

export const POST_CART           = 'api/cart/POST_CART';
export const POST_CART_COMPLETED = 'api/cart/POST_CART_COMPLETED';
export const POST_CART_FAILED    = 'api/cart/POST_CART_FAILED';

export const PUT_CART           = 'api/cart/PUT_CART';
export const PUT_CART_COMPLETED = 'api/cart/PUT_CART_COMPLETED';
export const PUT_CART_FAILED    = 'api/cart/PUT_CART_FAILED';

export const CHECK_CART           = 'api/cart/CHECK_CART';
export const CHECK_CART_COMPLETED = 'api/cart/CHECK_CART_COMPLETED';
export const CHECK_CART_FAILED    = 'api/cart/CHECK_CART_FAILED';

export const POST_ORDER           = 'api/cart/POST_ORDER';
export const POST_ORDER_COMPLETED = 'api/cart/POST_ORDER_COMPLETED';
export const POST_ORDER_FAILED    = 'api/cart/POST_ORDER_FAILED';

export const GET_INVOICE_URL           = 'api/cart/GET_INVOICE_URL';
export const GET_INVOICE_URL_COMPLETED = 'api/cart/GET_INVOICE_URL_COMPLETED';
export const GET_INVOICE_URL_FAILED    = 'api/cart/GET_INVOICE_URL_FAILED';
