import React, {useState} from 'react'
import styled from '@emotion/styled'
import {Tabs, Tab} from 'react-bootstrap'
import SignInContainer from 'views/commons/Connexion/Tabs/SignInContainer'
import SignUpContainer from 'views/commons/Connexion/Tabs/SignUpContainer'

const Connexion = (props) => {

  const [key, setKey] = useState('signIn');

  return (
      <TabStyle id='signin-signup-tab' activeKey={key} onSelect={k => setKey(k)} >
        <Tab eventKey='signIn' title='Connexion'>
          <SignInContainer />
        </Tab>
        <Tab eventKey='signUp' title='Inscription'>
          <SignUpContainer />
        </Tab>
      </TabStyle>
  )
}

const TabStyle = styled(Tabs)`
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border:none;
  padding: 0 !important;
  .nav-item{
    font-family: '${props => props.theme.fonts.family.main}';
    border:none;
    font-weight: bold;
    font-size: ${props => props.theme.fonts.size.s};
    border-radius:none;
    color: #ccc;
    &.active{
      color: ${props => props.theme.colors.primary};
    }
  }
`;

Connexion.propTypes = {
};

Connexion.defaultProps = {
};

export default Connexion;
