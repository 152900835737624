import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useParams } from 'react-router-dom'
import { operations as customerOperations, selectors as customerSelectors } from "state/ducks/api/customer";
import PublicPayment from "views/pages/PublicPayment/PublicPayment"
import queryString from 'query-string';
import { operations as modalsOperations } from 'state/ducks/client/modals'

const PublicPaymentContainer = (props) => {

  const dispatch          = useDispatch();
  const {token}           = useParams();
  const [order, setOrder] = useState(null);

  const isLoading = useSelector(
    (state) => customerSelectors.isLoading(state),
    shallowEqual
  );

  const returnUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;


  // Gestion retour payzen
  const payzen = queryString.parse(props.location.search).payzen;

  // Recupère la commande
  useEffect(() => {
    if (!order) {
      dispatch(customerOperations.getOrderByToken(token, {'returnUrl': returnUrl})).then((r) => {
        if (r?.data?.result) {
          setOrder(r.data.result);
        }
        if(payzen === "error"){
          dispatch(modalsOperations.setModalStatusGeneric(true, "Notre service a rencontré une erreur, merci de ressayer ou de contacter notre équipe au 06 38 13 35 24", "Oups..."));
        }else if(payzen === "refused"){
          dispatch(modalsOperations.setModalStatusGeneric(true, "Votre paiement a été refusé, merci de ressayer ou de contacter notre équipe au 06 38 13 35 24", "Oups..."));
        }
      });
    }
  }, [dispatch, order, token, returnUrl, payzen]);

  return (
    <PublicPayment order={order} payzen={payzen} isLoading={isLoading}/>
  );

};

export default PublicPaymentContainer;
