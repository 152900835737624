import * as actionTypes from './types'

export const postLead = (data) => ({
  type: actionTypes.POST_LEAD,
  meta: {
    async: true,
    blocking: true,
    path: `/v3/pro/business/leads.json`,
    method: "POST",
    body: data
  },
  payload: {
  },
});
