import * as types from './types'

export const setNotification = (data) => ( {
    type: types.SET_NOTIFICATION,
    payload: {
      data
    },
} );

export const clearNotification = () => ( {
    type: types.CLEAR_NOTIFICATION,
} );

export const setNotificationSuccess = (message) => ( {
    type: types.SET_NOTIFICATION_SUCCESS,
    payload: {
      message
    },
} );

export const clearNotificationSuccess = () => ( {
    type: types.CLEAR_NOTIFICATION_SUCCESS,
} );
