import React from "react";
import PropTypes from "prop-types";
import Item from "views/pages/Mealtray/Ranges/Range/Item/Item"

const ItemContainer = (props) => {

  const {
    item,
    link,
    color,
    rangeAble
  } = props

  return (
    <Item item={item} link={link} color={color} rangeAble={rangeAble}/>
  );

};

ItemContainer.propTypes = {
  item: PropTypes.object,
  link: PropTypes.string,
  color: PropTypes.string,
};

export default ItemContainer;
