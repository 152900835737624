import React, {useState} from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Button, Form, Col, Row } from 'react-bootstrap'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { css } from '@emotion/css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faSave } from "@fortawesome/free-solid-svg-icons"
import Loader from "react-loader-spinner";
import Tippy from '@tippyjs/react';

const InfoDelivery = (props) => {

  const {
    deliveryInfo,
    billingInfo,
    handleSubmitOrder,
    customer,
    isLoading,
    modalMinAmount,
    closeModalMinAmount
  } = props;

  const [showEditInvoiceAddress, setShowEditInvoiceAddress] = useState(false);

  const [status, setStatus] = useState("101");

  const schema = Yup.object().shape({

    deliveryLastname: Yup.string().required("Merci de renseigner un nom "),
    deliveryFirstname: Yup.string().required("Merci de renseigner un prénom "),
    deliveryPhone: Yup.string().required("Merci de renseigner votre téléphone").matches(/^((\+|00)33\s?|0)[0123456789](\s?\d{2}){4}$/, 'Merci de renseigner un numéro de portable valide en 06 ou 07').required('Merci de renseigner votre numéro de téléphone'),
    deliveryInformation: Yup.string(),
    invoiceLastname: Yup.string().required("Merci de renseigner un nom "),
    invoiceFirstname: Yup.string().required("Merci de renseigner un prénom "),
    invoiceCompany: Yup.string().required("Merci de renseigner une entité à facturer "),
    invoiceAddress: Yup.string().required("Merci de renseigner une addresse"),
    invoiceInformation: Yup.string(),

  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(values) => handleSubmitOrder(values, status)}
      initialValues={{
        deliveryLastname: deliveryInfo?.lastname ? deliveryInfo.lastname : '',
        deliveryFirstname: deliveryInfo?.firstname ? deliveryInfo.firstname : '',
        deliveryPhone: deliveryInfo?.phone ? deliveryInfo.phone : '',
        deliveryInformation: deliveryInfo?.addressComplement ? deliveryInfo.addressComplement : '',
        invoiceLastname: billingInfo?.lastname ? billingInfo.lastname : customer.lastname,
        invoiceFirstname: billingInfo?.firstname ? billingInfo.firstname : customer.firstname,
        invoiceCompany: billingInfo?.company ? billingInfo.company : customer.company,
        invoiceAddress: billingInfo?.fullAddress ? billingInfo.fullAddress : deliveryInfo?.complete,
        invoiceInformation: billingInfo?.moreInformation ? billingInfo.moreInformation : '',
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            <Col xs={12} sm={6} md={6}>
              <Card>
                <Title>Contact pour la livraison</Title>
                  <p className="text-center">Notre équipe de livraison vous déposera vos déjeuners entre 10h30 et 12h00. Merci</p>
                  <Form.Control
                    type='text' size='m' className={inputFormStyle} placeholder='Nom du contact de livraison'
                    name="deliveryLastname"
                    value={values.deliveryLastname}
                    onChange={handleChange}
                    isValid={touched.deliveryLastname && !errors.deliveryLastname}
                    isInvalid={touched.deliveryLastname && !!errors.deliveryLastname}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.deliveryLastname}
                  </Form.Control.Feedback>
                  <Form.Control
                    type='text' size='m' className={inputFormStyle} placeholder='Prénom du contact de livraison'
                    name="deliveryFirstname"
                    value={values.deliveryFirstname}
                    onChange={handleChange}
                    isValid={touched.deliveryFirstname && !errors.deliveryFirstname}
                    isInvalid={touched.deliveryFirstname && !!errors.deliveryFirstname}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.deliveryFirstname}
                  </Form.Control.Feedback>
                  <Form.Control
                    type='phone' size='m' className={inputFormStyle} placeholder='Téléphone'
                    name="deliveryPhone"
                    value={values.deliveryPhone}
                    onChange={handleChange}
                    isValid={touched.deliveryPhone && !errors.deliveryPhone}
                    isInvalid={touched.deliveryPhone && !!errors.deliveryPhone}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.deliveryPhone}
                  </Form.Control.Feedback>

                  <Form.Control
                    type='textarea' size='m' className={inputFormStyle} placeholder="Complément d'information (code d'accès etc...)"
                    name="deliveryInformation"
                    value={values.deliveryInformation}
                    onChange={handleChange}
                    isValid={touched.deliveryInformation && !errors.deliveryInformation}
                    isInvalid={touched.deliveryInformation && !!errors.deliveryInformation}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.deliveryInformation}
                  </Form.Control.Feedback>

                  {!showEditInvoiceAddress &&
                    <ChangeInvoiceAddress variant="outline-primary" onClick={() => setShowEditInvoiceAddress(true)}>
                      Modifier les informations de facturation
                      <br/><small>Par défaut, adresse de facturation = info de votre compte client</small>
                    </ChangeInvoiceAddress>
                  }

                  {showEditInvoiceAddress &&
                    <>
                      <Title className="mt-3">Facturation</Title>
                      <Form.Control
                        type='text' size='m' className={inputFormStyle} placeholder='Entreprise'
                        name="invoiceCompany"
                        value={values.invoiceCompany}
                        onChange={handleChange}
                        isValid={touched.invoiceCompany && !errors.invoiceCompany}
                        isInvalid={touched.invoiceCompany && !!errors.invoiceCompany}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.invoiceCompany}
                      </Form.Control.Feedback>
                      <Form.Control
                        type='text' size='m' className={inputFormStyle} placeholder='Nom'
                        name="invoiceLastname"
                        value={values.invoiceLastname}
                        onChange={handleChange}
                        isValid={touched.invoiceLastname && !errors.invoiceLastname}
                        isInvalid={touched.invoiceLastname && !!errors.invoiceLastname}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.invoiceLastname}
                      </Form.Control.Feedback>
                      <Form.Control
                        type='text' size='m' className={inputFormStyle} placeholder='Prénom'
                        name="invoiceFirstname"
                        value={values.invoiceFirstname}
                        onChange={handleChange}
                        isValid={touched.invoiceFirstname && !errors.invoiceFirstname}
                        isInvalid={touched.invoiceFirstname && !!errors.invoiceFirstname}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.invoiceFirstname}
                      </Form.Control.Feedback>
                      <Form.Control
                        type='textarea' size='m' className={inputFormStyle} placeholder="Adresse de facturation"
                        name="invoiceAddress"
                        value={values.invoiceAddress}
                        onChange={handleChange}
                        isValid={touched.invoiceAddress && !errors.invoiceAddress}
                        isInvalid={touched.invoiceAddress && !!errors.invoiceAddress}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.invoiceAddress}
                      </Form.Control.Feedback>
                      <Form.Control
                        type='textarea' size='m' className={inputFormStyle} placeholder="Complément d'information (code interne ...)"
                        name="invoiceInformation"
                        value={values.invoiceInformation}
                        onChange={handleChange}
                        isValid={touched.invoiceInformation && !errors.invoiceInformation}
                        isInvalid={touched.invoiceInformation && !!errors.invoiceInformation}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.invoiceInformation}
                      </Form.Control.Feedback>
                    </>
                  }
              </Card>
            </Col>
            <Col xs={12} sm={6} md={6}>
              <CGV className="mb-3">
                En passant commande au Moulin je reconnais avoir pris connaissance <a href="https://lemoulin.fr/cgv-moulin/" rel="noreferrer" target="_blank">des CGV</a>, <a href="https://lemoulin.fr/politique-de-confidentialite/" rel="noreferrer" target="_blank">de la politique de confidentialité</a> et je les accepte.
              </CGV>

              <Tippy
                content={
                  <>
                    <p className="mb-1">{modalMinAmount.text}</p>
                  </>
                }
                visible={modalMinAmount.isOpen}
                interactive={true}
                placement="top"
                className="tippy-error"
                onClickOutside={(instance, event) => closeModalMinAmount()}
              >
                <Action size="lg" variant="dark" type="submit" disabled={isLoading.value} onClick={() => setStatus("102")} >
                  {
                    isLoading.value ?
                      <>
                        <Loader
                          type="ThreeDots"
                          color="#ffffff"
                          height={20}
                          width={20}
                        />
                        <span>Vous pourrez modifier votre commande
                        jusqu'à 48h avant votre évènement</span>
                      </>
                    :
                    <>
                      <FontAwesomeIcon icon={faArrowRight} /> Commander
                      <br/><span>Vous pourrez modifier votre commande
                      jusqu'à 48h avant votre évènement</span>
                    </>
                  }
                </Action>
              </Tippy>

              <Save size="lg" variant="dark" type="submit" disabled={isLoading.value} onClick={() => setStatus("101")}>
                { isLoading.value ?
                  <>
                    <Loader
                      type="ThreeDots"
                      color="#111"
                      height={20}
                      width={20}
                    />
                  </>
                :
                  <>
                    {customer && !customer.distributor &&
                      <><FontAwesomeIcon icon={faSave} /> Ou sauvegarder sous forme de devis</>
                    }
                  </>
                }
              </Save>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );

};

const Card = styled.div`
  padding: 1.0rem;
  background-color: white;
  border-radius: 6px;
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.02);
`;

const Title = styled.h2`
  text-align: center;
  text-transform: uppercase;
  font-family: '${props => props.theme.fonts.family.main}';
  color: ${props => props.theme.colors.primary};
  font-weight: bold;
  font-size: ${props => props.theme.fonts.size.m};
`;

const ChangeInvoiceAddress = styled(Button)`
  text-transform: none;
  width: 100%;
`;

//Background of inputs of forms
const inputFormStyle =css`
  background-color: #ececec;
  border: none;
  margin: 1em 0;
`;


const CGV = styled.div`
  font-size: ${props => props.theme.fonts.size.s};
  color: ${props => props.theme.colors.black};
  a{
    text-decoration: underline;
  }
  @media (max-width: 767px){
    text-align: center;
    margin-top: 1.0rem;
  }
`;

const Action = styled(Button)`
  font-family: '${props => props.theme.fonts.family.main}';
  font-size: ${props => props.theme.fonts.size.s};
  color: ${props => props.theme.colors.black};
  width: 100%;
  text-align: left;
  padding: 1.0rem 1.0rem;
  svg{
    margin-right: 0.5rem;
  }
  span{
    text-transform: none;
    font-weight: normal;
  }
`;

const Save = styled(Button)`
  padding: 1.0rem 0;
  text-align: center;
  font-family: '${props => props.theme.fonts.family.main}';
  font-size: ${props => props.theme.fonts.size.xs};
  text-align: center;
  display: block;
  background-color: inherit;
  color: black;
  border: none;
  width: 100%;
  font-weight: bold;
  transition: 500ms;
  a{
    color: ${props => props.theme.colors.inherit} !important;
  }
  &:hover, &:disabled{
    background-color: inherit;
    border: none;
    opacity: 0.5;
    color: black;
  }
`;

InfoDelivery.propTypes = {
  handleSubmitOrder: PropTypes.func,
  deliveryInfo: PropTypes.object,
  billingInfo: PropTypes.object,
  customer: PropTypes.object,
  isLoading: PropTypes.object,
  modalMinAmount: PropTypes.object,
  closeModalMinAmount: PropTypes.func
};

export default InfoDelivery;
