import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Edit from "views/pages/Account/Edit/Edit"
import { selectors as customerSelectors, operations as customerOperations } from "state/ducks/api/customer";

const EditContainer = (props) => {

  const dispatch = useDispatch();

  const customer = useSelector(
    (state) => customerSelectors.getAllCustomer(state),
    shallowEqual
  );

  // On envoie les infos à modifier du formulaire compte client hors mot de passe,
  const onSubmitInfo = (values) => {
    dispatch(customerOperations.updateCustomer({customer:values}))
  }

  // Modification d'un mot de passe
  const onSubmitPassword = (values) => {
    dispatch(customerOperations.updatePassword(values))
  }

  return (
    <Edit customer={customer.data} isLoadingEditPassword={customer.isLoadingEditPassword}
    isLoadingUpdateMe={customer.isLoadingUpdateMe} onSubmitInfo={onSubmitInfo} onSubmitPassword={onSubmitPassword}/>
  );

};

export default EditContainer;
