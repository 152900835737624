import * as actionTypes from './types'
import { createReducer } from '../../../utils'

const initialState = {
  isOpen      : false,
  isLoadingAddress: false,
  customerInfo: {
  },
  deliveryInfo: {
  },
  billingInfo: {
  },
  deliveryAddress: {
    streetNumber:null,
    street:null,
    city:null,
    postal:null,
    lat:null,
    lng:null,
    complete:null,
    available:null,
  },
  productRanges: [
    {
      name:null,
      id:null,
      slug:null,
      articles: []
    }
  ],
  silverwareInfo: []
};

const cartReducer = createReducer( initialState )( {

[actionTypes.CLEAN]: (state, action) => {
  return { ...state, ...initialState };
},

[ actionTypes.UPDATE_CUSTOMER_INFO ]: ( state, action ) => {
    return { ...state, customerInfo: action.payload}
},

[ actionTypes.UPDATE_DELIVERY_INFO ]: ( state, action ) => {
    return { ...state, deliveryInfo: action.payload}
},

[ actionTypes.UPDATE_BILLING_INFO ]: ( state, action ) => {
    return { ...state, billingInfo: action.payload}
},

// Gère l'état d'ouverture du panier
[ actionTypes.SET_IS_OPEN ]: ( state, action ) => {
    return { ...state, isOpen: action.payload.status}
},

[actionTypes.ADD_ARTICLE]: (state, action) => {
  let article = action.payload.article;

  let range = action.payload.range;

  let rangeIndex = state.productRanges.findIndex((element) => element.slug === range.slug);

  // Ajoute la gamme avec le produit si pas d'index
  if (rangeIndex === -1) {
    return {
      ...state,
      productRanges: [{
        name:range.name,
        id:range.id,
        slug:range.slug,
        minAmountOrder:range.minAmountOrder,
        articles:[article]
      }]
    }
  }

  let prs = [...state.productRanges];
  let pr = {...prs[rangeIndex]};
  pr.articles.push(article);
  prs[rangeIndex] = pr;

  return {
    ...state,
    productRanges: prs
  }
},

[actionTypes.REMOVE_ARTICLE]: (state, action) => {
  let article = action.payload.article;

  let range   = action.payload.range;

  let rangeIndex = state.productRanges.findIndex((element) => element.slug === range.slug);
  rangeIndex = rangeIndex === -1 ? 0 : rangeIndex;
  let articles = state.productRanges[rangeIndex].articles.filter((element) => element.product_id !== article.product_id || article.type !== element.type);

  // @source https://stackoverflow.com/a/49502115/4140859
  let prs = [...state.productRanges];
  let pr = {...prs[rangeIndex]};
  pr.articles = articles;
  prs[rangeIndex] = pr;

  return {
    ...state,
    productRanges: prs
  }
},

[actionTypes.CHANGE_QUANTITY_ARTICLE]: (state, action) => {
  let article  = action.payload.article;

  let range    = action.payload.range;
  let quantity = action.payload.quantity;
  let rangeIndex = state.productRanges.findIndex((element) => element.slug === range.slug);
  rangeIndex = rangeIndex === -1 ? 0 : rangeIndex;

  let articleIndex = state.productRanges[rangeIndex].articles.findIndex(element => element.id === article.id && article.type === element.type);

  if (articleIndex < 0) {
    return {
      ...state
    }
  }

  if (quantity > 0) {
    return {
      ...state,
      productRanges: [
        ...state.productRanges.slice(0, rangeIndex),
        {
          ...state.productRanges[rangeIndex],
          articles: [
            ...state.productRanges[rangeIndex].articles.slice(0, articleIndex),
            {
              ...state.productRanges[rangeIndex].articles[articleIndex],
              quantity:quantity
            },
            ...state.productRanges[rangeIndex].articles.slice(articleIndex + 1)
          ]
        },
        ...state.productRanges.slice(rangeIndex + 1)
      ]
    }
  }

  // Retire l'article si quantité est à 0
  return {
    ...state,
    productRanges: [
      ...state.productRanges.slice(0, rangeIndex),
      {
        ...state.productRanges[rangeIndex],
        articles: [
          ...state.productRanges[rangeIndex].articles.slice(0, articleIndex),
          ...state.productRanges[rangeIndex].articles.slice(articleIndex + 1)
        ]
      },
      ...state.productRanges.slice(rangeIndex + 1)
    ]
  }

},

[actionTypes.RESET_ARTICLES]: (state, action) => {
  return { ...state,
    productRanges: initialState.productRanges
  };
},

[actionTypes.UPDATE_DELIVERY_ADDRESS]: (state, action) => {
  let address = action.payload;
  return { ...state,
    deliveryAddress: address
  };
},

[actionTypes.UPDATE_TOTAL]: (state, action) => {
  return { ...state,
    priceInfo: action.payload
  };
},

[actionTypes.UPDATE_SILVERWARE_INFO]: (state, action) => {
  return { ...state,
    silverwareInfo: action.payload
  };
},
[actionTypes.CHANGE_SILVERWARE_VALUE]: (state, action) => {
  let swIndex = state.silverwareInfo.findIndex(element => element.id === action.payload.silverwareId);
  if (swIndex > -1) {
    let sws = [...state.silverwareInfo];
    let sw = {...sws[swIndex], value:action.payload.value};
    sws[swIndex] = sw;

    return {
      ...state,
      silverwareInfo: sws
    }
  }
  return { ...state};
},

});

export default cartReducer;
