import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Confirmation from "views/pages/Confirmation/Confirmation"
import { selectors as cartSelectors, operations as cartOperations } from "state/ducks/client/cart";
import { operations as cartApiOperations, selectors as cartApiSelectors } from "state/ducks/api/cart";
import { operations as rangeOperations } from "state/ducks/api/productRange";
import { selectors as customerSelectors } from "state/ducks/api/customer";
import { selectors as modalsSelectors } from "state/ducks/client/modals";
import { useHistory } from "react-router-dom";
import { Redirect } from 'react-router-dom'
import * as modalsActions from 'state/ducks/client/modals/actions'
import * as otherUtils from "utils/utils"

const ConfirmationContainer = (props) => {

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState({value: false, type: null});

  // Permet de vérifier le panier à l'initialisation du composant
  const [cartChecked, setCartChecked] = useState(false);

  const cart = useSelector(
    (state) => cartSelectors.getCart(state),
    shallowEqual
  );

  const cartApi = useSelector(
    (state) => cartApiSelectors.getCart(state),
    shallowEqual
  );

  const cartIsChecking = useSelector(
    (state) => cartApiSelectors.cartIsChecking(state),
    shallowEqual
  );

  const isAuthenticated = useSelector(
    (state) => customerSelectors.isAuthenticated(state),
    shallowEqual
  );

  const customer = useSelector(
    (state) => customerSelectors.getCustomer(state),
    shallowEqual
  );

  const modalMinAmount = useSelector(
    (state) => modalsSelectors.getModalMinAmount(state),
    shallowEqual
  );

  const closeModalMinAmount = () => {
    dispatch(modalsActions.setModalStatusMinAmount(false));
  }

  const history = useHistory();

  const handleSubmitOrder = (values, status) => {

    if ((cart.priceInfo.totalPriceHt - cart?.deliveryInfo?.deliveryCostHt) < cart.productRanges[0].minAmountOrder) {
      dispatch(modalsActions.setModalStatusMinAmount(true, "Le montant minimum pour cette gamme est de " + otherUtils.centToEuros(cart.productRanges[0].minAmountOrder) + " (hors frais de livraison)"));
      return false;
    }

    setIsLoading({value: true, type: status});
    let deliveryInfo = {...cart.deliveryInfo,
      lastname          : values?.deliveryLastname,
      firstname         : values?.deliveryFirstname,
      phone             : values?.deliveryPhone,
      addressComplement : values?.deliveryInformation,
      company           : customer?.company
    };
    let billingInfo = {...cart.billingInfo,
      lastname        : values.invoiceLastname ? values.invoiceLastname : values?.deliveryLastname,
      firstname       : values.invoiceFirstname ? values.invoiceFirstname : values?.deliveryFirstname,
      phone           : values.invoicePhone ? values.invoicePhone : values?.deliveryPhone,
      moreInformation : values.invoiceInformation ? values.invoiceInformation : null,
      company         : values.invoiceCompany ? values.invoiceCompany : customer?.company,
      address         : values?.invoiceAddress ? values.invoiceAddress : cart?.deliveryInfo?.complete
    };

    Promise.resolve(dispatch(cartOperations.updateBillingAndDeliveryInfo(deliveryInfo, billingInfo)))
    .then((success) => {
      dispatch(cartApiOperations.createOrder({status:status}))
      .then((response) => {
        dispatch(rangeOperations.initialize());
        // GTM
        let gtmProducts = [];
        cart.productRanges.forEach((productRange, index) => {
          productRange.articles.forEach((article, index) => {
            return gtmProducts = [...gtmProducts, {
              name    :article.name,
              id      :article.genericId,
              price   :article.price / 100,
              category:article.categoryName,
              brand   :'Le Moulin B2B',
              quantity:article.quantity,
              variant : productRange.name
            }]
          })
        })

        window.dataLayer.push({
          event : 'orderComplete',
          ecommerce: {
            purchase: {
              actionField : {
                id:response?.orderId,
                revenue: cart.priceInfo.totalPriceHt / 100,
                tax: null,
                shipping: cart.deliveryInfo.cost / 100,
                coupon: null
              }
            },
            products: gtmProducts
          }
        });

        if(status === "102"){
          history.push({
            pathname: '/merci-pour-votre-commande',
            search: '?type=commande'
          })
        }else if( status === "101"){
          history.push({
            pathname: '/merci-pour-votre-commande',
            search: '?type=sauvegarde'
          })
        }
      })
      .finally((a) => {setIsLoading({value: false, type: null})})
    });

  }

  useEffect(() => {
    if (cartApi?.id && cartChecked === false) {
      dispatch(cartApiOperations.checkCart(cartApi.id));
      setCartChecked(true);
    }
  }, [dispatch, cartApi, cartChecked, setCartChecked])

  if(cart.productRanges[0].id === null || cart.priceInfo.totalPriceHt < cart.productRanges[0].minAmountOrder){
    return <Redirect to="/" />;
  }

  return (
    <Confirmation
      cart={cart}
      handleSubmitOrder={handleSubmitOrder}
      customer={customer}
      isAuthenticated={isAuthenticated}
      isLoading={isLoading}
      cartIsChecking={cartIsChecking}
      modalMinAmount={modalMinAmount}
      closeModalMinAmount={closeModalMinAmount}
    />
  );

};

export default ConfirmationContainer;
