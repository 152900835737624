import React from 'react'
import { Row, Col } from 'react-bootstrap'
import styled from '@emotion/styled'

const NoAvailableAddress = (props) => {

  return(
    <Style noGutters>
      <Col xs={12}>
        <Action>
          Désolé, notre formidable équipe ne livraison de dessert pas encore cette zone <br/>
        </Action>
      </Col>
    </Style>
  )
}

const Style = styled(Row)`
  text-align: center;
  padding: 1.0rem;
  font-size: ${props => props.theme.fonts.size.m};
  height: 100vh;
  p{
    line-height: 1.1;
  }
  div{
    display: flex;
    webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

const Action = styled.p`
  text-align: center;
  font-size: ${props => props.theme.fonts.size.sm};
  button{
    padding: 1rem;
  }
`;

export default NoAvailableAddress;
