import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

// Badge d'options avec tippy description
const OptionBadge = (props) => {

  const {
    orderBeforeDays,
    minAmount
  } = props

  return (
    <>
      {orderBeforeDays &&
        orderBeforeDays === 1 ?
            <Option>Commande la veille avant 11h</Option>
          :
            <Option>Commande {orderBeforeDays * 24}h avant</Option>
      }
      {minAmount &&
        <Option>Commande à partir de {(minAmount/ 100).toFixed(0)}€ HT</Option>
      }
    </>
  );

};

const Option = styled.div`
  color: white;
  border: solid rgba(255, 255, 255, 0.5) 1px;
  padding: 0.2rem 0.8rem;
  display: inline-block;
  border-radius: 4px;
  font-size: ${props => props.theme.fonts.size.s};
  font-family: '${props => props.theme.fonts.family.main}';
  transition: 300ms;
  margin: 0 0 0 0.4rem;;
  background-color: rgba(0, 0, 0, 0.4);
`;

OptionBadge.propTypes = {
  option: PropTypes.object
};

export default OptionBadge;
