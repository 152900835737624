import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/css'
import {Form} from 'react-bootstrap'
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loader from "react-loader-spinner";
import Button from "styles/Button"
import styled from "@emotion/styled";

const SignIn = (props) => {

  const {
    onSubmit,
    isLoading,
    onSubmitRequiredPassword
  } = props

  const schema = Yup.object().shape({
    email: Yup.string().email("Cet email n'est pas correct").required("Merci de renseigner votre email"),
    password: Yup.string().required('Merci de renseigner votre mot de passe'),
  });

  const schemaRequiredPassword = Yup.object().shape({
    email: Yup.string().email("Cet email n'est pas correct").required("Merci de renseigner votre email"),
  });

  const [requiredPassword, setRequiredPassword] = useState(false);

  return(
    !requiredPassword ?
      <Formik
        validationSchema={schema}
        onSubmit={(values) => onSubmit(values)}
        initialValues={{
          email: '',
          password: '',
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Control
              type='email' size='m' className={inputFormStyle} placeholder='Email'
              name="email"
              value={values.email}
              onChange={handleChange}
              isValid={touched.email && !errors.email}
              isInvalid={touched.email && !!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>

            <Form.Control
              type='password' size='m' className={inputFormStyle} placeholder='Mot de passe'
              name="password"
              value={values.password}
              onChange={handleChange}
              isValid={touched.password && !errors.password}
              isInvalid={touched.password && !!errors.password}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>

            <div className='text-center'>
              <LinkCustom onClick={() => setRequiredPassword(true)}>Mot de passe oublié</LinkCustom>
              <br/>
              <Button padding="0.8rem 2.0rem;" margin="1.0rem 0 0 0"type="submit" size="lg" className='text-uppercase px-5 py-3 mt-2' disabled={isLoading && "disabled"}>
                {isLoading ?
                  <Loader
                    type="ThreeDots"
                    color="#ffffff"
                    height={25}
                    width={25}
                  />
                : "Valider"}
              </Button>
            </div>
          </Form>
       )}
      </Formik>
    :
    <Formik
      validationSchema={schemaRequiredPassword}
      onSubmit={(values) => onSubmitRequiredPassword(values)}
      initialValues={{
        email: ''
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Control
            type='email' size='m' className={inputFormStyle} placeholder='Email du mot de passe oublié'
            name="email"
            value={values.email}
            onChange={handleChange}
            isValid={touched.email && !errors.email}
            isInvalid={touched.email && !!errors.email}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>

          <div className='text-center'>
            <Button padding="0.8rem 2.0rem;" margin="1.0rem 0 0 0"type="submit" size="lg" className='text-uppercase px-5 py-3 mt-2' disabled={isLoading && "disabled"}>
              {isLoading ?
                <Loader
                  type="ThreeDots"
                  color="#ffffff"
                  height={25}
                  width={25}
                />
              : "Faire une demande"}
            </Button>
            <br/><br/>
            <LinkCustom onClick={() => setRequiredPassword(false)}>ou essayer de se connecter</LinkCustom>
          </div>
        </Form>
     )}
    </Formik>
  )
}

//Background of inputs of forms
const inputFormStyle =css`
  background-color: #ececec;
  border: none;
  margin: 1em 0;
`;

const LinkCustom = styled.div`
  cursor: pointer;
  color: #111;
  font-family: '${props => props.theme.fonts.family.main}';
  text-decoration: underline;
  font-size: ${(props) => props.theme.fonts.size.sm};
  &:hover, &.active{
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
  }
`

SignIn.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  handleForgotPassword: PropTypes.func
};

SignIn.defaultProps = {
};

export default SignIn;
