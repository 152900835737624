import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/css'
import {Form} from 'react-bootstrap'
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loader from "react-loader-spinner";
import Button from "styles/Button"
import styled from "@emotion/styled";

const SignUp = (props) => {

  const {
    onSubmit,
    isLoading
  } = props

  const schema = Yup.object().shape({
    company: Yup.string().required("Merci de renseigner votre nom de société"),
    lastname: Yup.string().required("Merci de renseigner votre nom"),
    firstname: Yup.string().required("Merci de renseigner votre prénom"),
    phone: Yup.string().matches(/^((\+|00)33\s?|0)[0123456789](\s?\d{2}){4}$/, 'Merci de renseigner un numéro de portable valide en 06 ou 07').required('Merci de renseigner votre numéro de téléphone'),
    email: Yup.string().email("Cet email n'est pas correct").required("Merci de renseigner votre email"),
    password: Yup.string().matches(/^\S*(?=\S{8,})(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[\d])\S*$/, 'Le mot de passe doit être composé de 8 caractères, d\'au moins une majuscule, une minuscule et un chiffre.').required('Merci de renseigner votre mot de passe'),
    passwordConfirmation: Yup.string().required('Merci de renseigner votre confirmation de mot de passe')
     .oneOf([Yup.ref('password'), null], 'La confirmation de mot de passe ne correspond pas'),
    terms: Yup.bool().required("Merci d'accepter nos CGV").oneOf([true], "Merci d'accepte nos CGV"),
  });

  return(
    <Formik
      validationSchema={schema}
      onSubmit={(values) => {
        let submittedValued = {...values, plainPassword:{first: values.password, confirmation: values.passwordConfirmation}};
        delete submittedValued.password;
        delete submittedValued.passwordConfirmation;
        onSubmit(submittedValued);
      }}
      initialValues={{
        company: '',
        lastname: '',
        firstname: '',
        phone: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        terms: false
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Title>Bienvenue dans le club des gourmands (même en réunion) !</Title>
          <p className="text-center"><i>Ces quelques informations nous permettent de vous accompagner aux petits oignons.</i></p>
          <Form.Control
            type='text' size='m' className={inputFormStyle} placeholder='Entreprise'
            name="company"
            value={values.company}
            onChange={handleChange}
            isValid={touched.company && !errors.company}
            isInvalid={!!errors.company}
          />
          <Form.Control.Feedback type="invalid">
            {errors.company}
          </Form.Control.Feedback>
          <Form.Control
            type='text' size='m' className={inputFormStyle} placeholder='Nom'
            name="lastname"
            value={values.lastname}
            onChange={handleChange}
            isValid={touched.lastname && !errors.lastname}
            isInvalid={touched.lastname && !!errors.lastname}
          />
          <Form.Control.Feedback type="invalid">
            {errors.lastname}
          </Form.Control.Feedback>
          <Form.Control
            type='text' size='m' className={inputFormStyle} placeholder='Prénom'
            name="firstname"
            value={values.firstname}
            onChange={handleChange}
            isValid={touched.firstname && !errors.firstname}
            isInvalid={touched.firstname && !!errors.firstname}
          />
          <Form.Control.Feedback type="invalid">
            {errors.firstname}
          </Form.Control.Feedback>
          <Form.Control
            type='phone' size='m' className={inputFormStyle} placeholder='Téléphone'
            name="phone"
            value={values.phone}
            onChange={handleChange}
            isValid={touched.phone && !errors.phone}
            isInvalid={touched.phone && !!errors.phone}
          />
          <Form.Control.Feedback type="invalid">
            {errors.phone}
          </Form.Control.Feedback>
          <Form.Control
            type='email' size='m' className={inputFormStyle} placeholder='Email'
            name="email"
            value={values.email}
            onChange={handleChange}
            isValid={touched.email && !errors.email}
            isInvalid={touched.email && !!errors.email}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>

          <hr />

          <Form.Control
            type='password' size='m' className={inputFormStyle} placeholder='Mot de passe'
            name="password"
            value={values.password}
            onChange={handleChange}
            isValid={touched.password && !errors.password}
            isInvalid={touched.password && !!errors.password}
          />
          <Form.Control.Feedback type="invalid">
            {errors.password}
          </Form.Control.Feedback>

          <Form.Control
            type='password' size='m' className={inputFormStyle} placeholder='Confirmation du mot de passe'
            name="passwordConfirmation"
            value={values.passwordConfirmation}
            onChange={handleChange}
            isValid={touched.passwordConfirmation && !errors.passwordConfirmation}
            isInvalid={touched.passwordConfirmation && !!errors.passwordConfirmation}
          />
          <Form.Control.Feedback type="invalid" className="pb-3">
            {errors.passwordConfirmation}
          </Form.Control.Feedback>

          <Custom>
            <Form.Check
              required
              name="terms"
              id="terms"
              onChange={handleChange}
              isInvalid={touched.terms && !!errors.terms}
              feedback={errors.terms}
            >
              <Form.Check.Input isInvalid={touched.terms && !!errors.terms} onChange={handleChange}/>
              <Form.Check.Label>J'accepte les <a href='https://lemoulin.fr/cgv-moulin/'  rel="noreferrer" target="_blank">CGV</a> et <a href='https://lemoulin.fr/politique-de-confidentialite/' rel="noreferrer" target="_blank">la politique de confidentialité</a></Form.Check.Label>
            </Form.Check>
          </Custom>


          <div className='text-center'>
            <Button padding="0.8rem 2.0rem;" margin="1.0rem 0 0 0"type="submit" size="lg" className='text-uppercase px-5 py-3 mt-2' disabled={isLoading && "disabled"}>
              {isLoading ?
                <Loader
                  type="ThreeDots"
                  color="#ffffff"
                  height={25}
                  width={25}
                />
              : "Valider"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const inputFormStyle = css`
  background-color: #ececec;
  border: none;
  margin: 1em 0;
`;

const Title = styled.div`
  color: ${(props) => props.theme.colors.primary};
  text-align:center;
  font-weight: bold;
  margin-top: 1.0rem;
`

const Custom = styled.div`
  color: ${(props) => props.theme.colors.primary};
  a{
    text-decoration: underline;
  }
`

SignUp.propTypes = {
  onSubmit: PropTypes.func,
};

SignUp.defaultProps = {
};

export default SignUp;
