import React from "react";
import { Container, Row, Col, Form } from 'react-bootstrap'
import styled from "@emotion/styled";
import Layout from "views/commons/Layout/Layout";
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loader from "react-loader-spinner";
import { css } from '@emotion/css'
import Button from "styles/Button"
import PropTypes from 'prop-types'

const ResetPassword = (props) => {

  const {
    onSubmit,
    isLoading
  } = props

  const schema = Yup.object().shape({
    password: Yup.string().required('Merci de renseigner votre nouveau mot de passe'),
    passwordConfirmation: Yup.string().required('Merci de renseigner votre confirmation de nouveau mot de passe')
     .oneOf([Yup.ref('password'), null], 'La confirmation de nouveau mot de passe ne correspond pas'),
  });

  return (
    <Layout>
      <Container>
        <RowBtp>
            <Col xs={12} sm={12} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }} className={"mb-3"}>
              <Card>
                <h3 className="text-center">Reinitialisation de mot de passe</h3>
                <Formik
                  validationSchema={schema}
                  onSubmit={(values) => onSubmit(values)}
                  initialValues={{
                    password: ''
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                  }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Control
                      type='password' size='m' className={inputFormStyle} placeholder='Nouveau mot de passe'
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isValid={touched.password && !errors.password}
                      isInvalid={touched.password && !!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>

                    <Form.Control
                      type='password' size='m' className={inputFormStyle} placeholder='Confirmation du nouveau mot de passe'
                      name="passwordConfirmation"
                      value={values.passwordConfirmation}
                      onChange={handleChange}
                      isValid={touched.passwordConfirmation && !errors.passwordConfirmation}
                      isInvalid={touched.passwordConfirmation && !!errors.passwordConfirmation}
                    />
                    <Form.Control.Feedback type="invalid" className="pb-3">
                      {errors.passwordConfirmation}
                    </Form.Control.Feedback>

                      <div className='text-center'>
                        <Button padding="0.8rem 2.0rem;" margin="1.0rem 0 0 0"type="submit" size="lg" className='text-uppercase px-5 py-3 mt-2' disabled={isLoading && "disabled"}>
                          {isLoading ?
                            <Loader
                              type="ThreeDots"
                              color="#ffffff"
                              height={25}
                              width={25}
                            />
                          : "Modifier"}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Card>
            </Col>
        </RowBtp>
      </Container>
    </Layout>
  );
};


const Card = styled.div`
  padding: 1.0rem;
  background-color: white;
  border-radius: 6px;
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.02);
`;

const RowBtp = styled(Row)`
  margin-top: 3.0rem;
  margin-bottom: 3.0rem;
  @media (max-width: 767px){
    margin-top: 1rem;
    margin-bottom: 1.0rem;
  }
`
const inputFormStyle = css`
  background-color: #ececec;
  border: none;
  margin: 1em 0;
`;

ResetPassword.propTypes = {
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default ResetPassword;
