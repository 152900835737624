import React from "react";
import { Container, Row, Col } from 'react-bootstrap'
import ContentLoader from 'react-content-loader'
import Tippy from '@tippyjs/react';
import Button from "styles/Button"
import { Link } from "react-router-dom";
import PropTypes from 'prop-types'
import styled from "@emotion/styled";
import Layout from "views/commons/Layout/Layout";
import Menu from "views/pages/Account/commons/Menu"

const PublicPayment = (props) => {

  const {
    order,
    payzen,
    isLoading
  } = props;

  return (
    <Layout>
      <Menu />
      <Container>

        <RowBtp>
            <Col xs={12} sm={12} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }} className={"mb-3"}>
              <Card>
                {isLoading || !order ?
                  <ContentLoader viewBox="0 0 250 75">
                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="15" />
                    <rect x="0" y="20" rx="0" ry="0" width="60%" height="15" />
                    <rect x="0" y="40" rx="0" ry="0" width="70%" height="15" />
                    <rect x="0" y="60" rx="0" ry="0" width="50%" height="15" />
                  </ContentLoader>
                :
                <>
                  {payzen === "success" && (order.status === "203") ?
                    <>
                      <p>Merci pour votre règlement de votre facture {order.reference} d'un montant de {(order.price / 100).toFixed(2)} € HT / {(order.priceTTC / 100).toFixed(2)} € TTC.</p>
                      <p><small><i> Votre facture est consultable depuis <Link to="/compte/factures">votre compte client.</Link></i></small></p>
                    </>
                    : (order.paymentForm && (order.status === "201" || order.status === "202")) ?
                      <>
                        Montant de votre facture {(order.reference)} à régler<br/>
                        <b>{(order.price / 100).toFixed(2)} € HT / {(order.priceTTC / 100).toFixed(2)} € TTC</b><br/>
                        <span dangerouslySetInnerHTML={{__html: order.paymentForm}}></span>
                        <Tippy content={"Cliquer pour télécharger notre RIB afin d'effectuer votre virement."}>
                          <Link to="/files/rib-lemoulinasalades.pdf" target="_blank" download>
                            <Button
                              margin="0 0.2rem"
                              texttransform="inherit"
                            >
                              Payer par virement
                            </Button>
                          </Link>
                        </Tippy>
                      </>
                    :
                    <>
                      <p>La commande {order.reference} d'un montant de {(order.price / 100).toFixed(2)} € HT / {(order.priceTTC / 100).toFixed(2)} € TTC  n'est pas à régler.</p>
                      {(order.status === "203") && <p>Elle a déjà été réglée</p>}
                      <p><small><i> Votre commande est consultable depuis <Link to="/compte/factures">votre compte client.</Link></i></small></p>
                    </>
                    }
                  </>
                }
              </Card>
            </Col>
        </RowBtp>
      </Container>
    </Layout>
  );

};

const Card = styled.div`
  padding: 1.0rem;
  background-color: white;
  border-radius: 6px;
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.02);
  text-align: center;
  font-size: 1.4rem;
`;

const RowBtp = styled(Row)`
  margin-top: 3.0rem;
  margin-bottom: 0.0rem;
  @media (max-width: 767px){
    margin-top: 1rem;
    margin-bottom: 1.0rem;
  }
`

PublicPayment.propTypes = {
  order: PropTypes.object,
  payzen: PropTypes.string,
  isLoading: PropTypes.bool
};

export default PublicPayment;
