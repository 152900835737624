import * as actionTypes from './types'

export const clean = () => ({
  type: actionTypes.CLEAN,
  payload: {},
});

export const getCart = (cartId) => ({
  type: actionTypes.GET_CART,
  meta: {
    async: true,
    blocking: true,
    path: `/v3/pro/carts/${cartId}.json`,
    method: "GET"
  },
  payload: {
    cartId
  },
});

export const postCart = (data, type) => ( {
  type: actionTypes.POST_CART,
  meta: {
    async: true,
    blocking: true,
    path: `/v3/pro/carts.json`,
    method: "POST",
    body: data
  },
  payload: {
    type
  },
});

export const putCart = (cartId, data, type) => ( {
  type: actionTypes.PUT_CART,
  meta: {
    async: true,
    blocking: true,
    path: `/v3/pro/carts/${cartId}.json`,
    method: "PUT",
    body: data
  },
  payload: {
    type
  },
});

export const postOrder = (cartId, data) => ({
  type: actionTypes.POST_ORDER,
  meta: {
    async: true,
    blocking: true,
    path: `/v3/pro/carts/${cartId}/orders.json`,
    method: "POST",
    body: data
  },
  payload: {
  },
});

export const checkCart = (cartId) => ({
  type: actionTypes.CHECK_CART,
  meta: {
    async: true,
    blocking: true,
    path: `/v3/pro/carts/${cartId}/check.json`,
    method: "GET"
  },
  payload: {
  },
});

export const getInvoiceUrl = (orderId) => ({
  type: actionTypes.GET_INVOICE_URL,
  meta: {
    async: true,
    blocking: true,
    path: `/v3/pro/carts/${orderId}/invoiceurl.json`,
    method: "GET"
  },
  payload: {
  },
});
