import React from "react";
import {slide as Menu} from 'react-burger-menu';
import PropTypes from "prop-types";
import styled from '@emotion/styled'
import MetaInfo from "./Partials/MetaInfo"
import Validate from "./Partials/Validate"
import NoCart from "./Partials/NoCart"
import NoAvailableAddress from "./Partials/NoAvailableAddress"
import Article from "./Partials/Article"
import Options from "./Partials/Options"
import Silverwares from "./Partials/Silverwares"
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

// Panier version sidebar
const SidebarCart = (props) => {

  const {
    isOpen,
    handleStateCartChange,
    cart,
    silverwares,
    handleValideCart,
    handleChangeArticleQuantity,
    handleChangeOption,
    handleChangeSilverwares,
    modalMinAmount,
    isLoading,
    handleStateCartClose,
    closeModalMinAmount,
    swPriceHt
  } = props

  return (
    <Menu
      id={"cart"}
      right
      customBurgerIcon={ false }
      isOpen={isOpen}
      disableAutoFocus={true}
      customCrossIcon={ false }
      onStateChange={(state) => handleStateCartChange(state)}
    >
      <Close onClick={(state) => handleStateCartClose()}><FontAwesomeIcon icon={faTimes}/></Close>
      {cart?.productRanges[0]?.articles && cart?.productRanges[0]?.articles.length > 0 && cart?.deliveryAddress?.available === true ?
        <>
          <InfoEvent> Pour votre évènement du <b>{moment(cart.deliveryInfo.deliveryDate).format('DD-MM-YYYY')}</b> au<br/><b>{cart?.deliveryAddress?.address?.complete ? cart.deliveryAddress.address.complete : null}</b></InfoEvent>

          {(silverwares && silverwares.length > 0) &&
            <Silverwares silverwares={silverwares} handleChange={handleChangeSilverwares} isDisable={isLoading}/>
          }
          {cart.options &&
            <Options options={cart.options} handleChangeOption={handleChangeOption}/>
          }

          {cart?.productRanges && cart.productRanges.map((productRange, index) =>
            <div key={index}>
              <ArticleRange>{productRange.name}</ArticleRange>

              <Articles>
                {productRange.articles && productRange.articles.map((article, index) =>
                  <Article
                    range={productRange}
                    handleChangeArticleQuantity={handleChangeArticleQuantity}
                    article={article}
                    key={index}
                    isDisable={isLoading}/>
                )}
              </Articles>
            </div>
          )}

          {cart.options && cart.options.map((option, index) =>
            option.value &&
              <MetaInfo text={option.name} price={option.price} key={index} />
          )}

          {swPriceHt > 0 &&
            <MetaInfo text={"Couverts"} price={swPriceHt} />
          }
          <MetaInfo text={"Livraison"} price={cart.deliveryInfo.deliveryCostHt} />
          <MetaInfo text={"TOTAL"} price={cart.priceInfo.totalPriceHt} priceTtc={cart.priceInfo.totalPriceTtc} big/>

          <Sticky>
            <Validate
              handleValideCart={handleValideCart}
              isOpen={modalMinAmount.isOpen}
              text={modalMinAmount.text}
              isLoading={isLoading}
              closeModalMinAmount={closeModalMinAmount}
            />
          </Sticky>
        </>
        : cart?.deliveryAddress?.available === false ?
          <NoAvailableAddress />
        :
          <NoCart handleStateCartClose={handleStateCartClose}/>
      }

    </Menu>
  );

};

const InfoEvent = styled.div`
  background-color: ${props => props.theme.colors.primary};
  text-align: center;
  padding: 0.5rem 0.5rem;
  color:  white;
  font-size: ${props => props.theme.fonts.size.xs};
`;

const ArticleRange = styled.div`
  border-top: 1px #ddd solid;
  font-weight: bold;
  padding: 0.3rem 0.5rem;
  color: ${props => props.theme.colors.inherit};
  font-family: '${props => props.theme.fonts.family.main}';
  text-align: center;
  background-color: white;
`

const Articles = styled.div`
  border-top: 1px #ddd solid;
  background-color: white;
`

const Close = styled.div`
  position: absolute;
  color: white;
  right: 0rem;
  top: 0rem;
  padding: 0.8rem;
  cursor: pointer;
  font-size: 1.2rem;
  transition: 300ms;
  background-color: white;
  color: #111;
  &:hover{
    opacity: 0.7;
  }
`

const Sticky = styled.div`
  padding: 0.5rem;
  position: absolute;
  bottom: 0rem;
  width: 100%;
`;

SidebarCart.propTypes = {
  isOpen: PropTypes.bool,
  handleStateCartChange: PropTypes.func,
  cart : PropTypes.object,
  silverwares: PropTypes.array,
  handleValideCart: PropTypes.func,
  handleChangeArticleQuantity: PropTypes.func,
  handleChangeOption: PropTypes.func,
  handleChangeSilverwares: PropTypes.func,
  modalMinAmount: PropTypes.object,
  isLoading: PropTypes.bool,
  handleStateCartClose: PropTypes.func,
  closeModalMinAmount: PropTypes.func,
  swPriceHt: PropTypes.number
};

export default SidebarCart;
