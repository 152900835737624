import { combineReducers } from "redux";

import { default as productRangeReducers } from "./productRange";
import { default as cartReducers } from "./cart";
import { default as customerReducers } from "./customer";
import { default as notificationsReducers } from "./notifications";
import { default as deliveryCostReducers } from "./deliveryCost";
import { default as businessReducers } from "./business";
import { default as marketingReducers } from "./marketing";

export default combineReducers({
  productRange : productRangeReducers,
  cart         : cartReducers,
  customer     : customerReducers,
  notifications: notificationsReducers,
  deliveryCost : deliveryCostReducers,
  business     : businessReducers,
  marketing    : marketingReducers
});
