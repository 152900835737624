import cartModel from './cartModel'
import * as rangeSelectors from 'state/ducks/api/productRange/selectors'
import { selectors as cartApiSelectors } from "state/ducks/api/cart";
import moment from "moment";

export const getDeliveryDate = state => state.client.cart.deliveryInfo.deliveryDate;
export const getProductRanges = state => state.client.cart.productRanges;
export const getSilverwareInfo = state => state.client.cart.silverwareInfo;
export const getDeliveryInfo = state => state.client.cart.deliveryInfo;

export const getCart = (state) => {
  let cart = state.client.cart;
  if (!cart?.deliveryInfo) {
    cart.deliveryInfo = cartModel.deliveryInfo;
  }
  if (!cart?.customerInfo) {
    cart.customerInfo = cartModel.customerInfo;
  }
  if (!cart?.billingInfo) {
    cart.billingInfo = cartModel.billingInfo;
  }
  if (!cart?.priceInfo) {
    cart.priceInfo = cartModel.priceInfo;
  }
  if (!cart?.silverwareInfo) {
    cart.silverwareInfo = cartModel.silverwareInfo;
  }

  return cart;
}

/**
 * Si l'adresse est livrable
 */
export const isAddressDeliverable = (state) => {
  if (state?.client?.cart?.deliveryAddress?.available && state?.client?.cart?.deliveryAddress?.available === false) {
    return false;
  }
  return true;
}

/**
 * Si la date est dispo pour la gamme actuelle
 */
export const isDateAvailable = (state) => {
  if (state?.client?.cart?.deliveryInfo?.deliveryDate) {
    let currentRange = rangeSelectors.getCurrentRange(state);
    let deliveryDate = moment(state.client.cart.deliveryInfo.deliveryDate).format('YYYY-MM-DD');
    if (currentRange?.availableDates) {
      return currentRange.availableDates.includes(deliveryDate);
    }

    return false;
  }
  return true;
}

export const getArticleFromItemAndRange = (state, range, item) => {
  let article = null;
  if (range && item && state?.client?.cart?.productRanges) {
    let rangeIndex = state.client.cart.productRanges.findIndex((element) => element.slug === range.slug);
    if (rangeIndex > -1) {
      article = state.client.cart.productRanges[rangeIndex].articles.find((element) => element.id === item.id && element.type === item.type);
    }
  }
  if (article === undefined) {
    return null;
  }
  return article;
}

/**
 * Retourne le total ht des couverts
 */
export const getSilverwaresTotalHt = (state) => {
  let cart                  = getCart(state);
  let silverwareInfoTotalHT = 0;
  let person                = cartApiSelectors.getPerson(state);

  if (cart?.silverwareInfo?.length > 0 && person > 0) {
    cart?.silverwareInfo?.forEach(silverware => {
      if (silverware.value) {
        silverwareInfoTotalHT += silverware.price;
      }
    })
    silverwareInfoTotalHT = silverwareInfoTotalHT * person;
  }

  return silverwareInfoTotalHT;
}
