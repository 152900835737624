import React from 'react'
import DateChangeProduct from "./DateChangeProduct"
import { operations as modalsOperations } from 'state/ducks/client/modals'
import { useDispatch } from 'react-redux';

const DateChangeProductContainer = (props) => {

  const dispatch = useDispatch();

  const handleUpdateCart = () => {
    // On update le panier qu'avec les produits dispos
  }

  const handleRefuseUpdateCart = () => {
    // On ferme la modale si on ne veut pas update les produits à cette date
    dispatch(modalsOperations.setModalStatusDateChangeProduct(false));
  }

  return (
    <DateChangeProduct handleUpdateCart={handleUpdateCart} handleRefuseUpdateCart={handleRefuseUpdateCart}
    />
  )
}

export default DateChangeProductContainer;
