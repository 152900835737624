import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {Button, Modal as ModalBtsp} from 'react-bootstrap'

const Modal = (props) => {

  const {
    isOpen,
    onClose
  } = props

  return (
    <Popup
      show={isOpen}
      onHide={onClose}
      onEscapeKeyDown={onClose}
    >
        <CloseButton className='text-right overflow-auto'>
          <Button onClick={onClose} className = 'btn-success rounded-0'>X</Button>
        </CloseButton>
        {props.children}
    </Popup>
  )
}



const CloseButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;
`;

const Popup = styled(ModalBtsp)`
  font-size: ${props => props.theme.fonts.size.xs};
  height: auto;
  max-height: 90%;
  .modal-dialog{
    border: none;
    border-radius: none;
  }
  .modal-content{
    border: none;
    padding: 5% 5%;
  }
`;

Modal.propTypes = {
  isOpen: PropTypes.object,
  onClose: PropTypes.func
};

export default Modal;
