import React from "react";
import Layout from "views/commons/Layout/Layout";
import Menu from "views/pages/Account/commons/Menu"
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { css } from '@emotion/css'
import {Form} from 'react-bootstrap'
import { Formik } from 'formik';
import * as Yup from 'yup';
import Loader from "react-loader-spinner";
import Button from "styles/Button"
import styled from '@emotion/styled'

const Edit = (props) => {

  const {
    customer,
    onSubmitInfo,
    onSubmitPassword,
    isLoading,
    isLoadingEditPassword,
    isLoadingUpdateMe
  } = props;

  const schemaInfo = Yup.object().shape({
    company: Yup.string().required("Merci de renseigner votre nom de société"),
    lastname: Yup.string().required("Merci de renseigner votre nom"),
    firstname: Yup.string().required("Merci de renseigner votre prénom"),
    phone: Yup.string().matches(/^((\+|00)33\s?|0)[0123456789](\s?\d{2}){4}$/, 'Merci de renseigner un numéro de portable valide en 06 ou 07').required('Merci de renseigner votre numéro de téléphone'),
    email: Yup.string().email("Cet email n'est pas correct").required("Merci de renseigner votre email"),
  });

  const schemaPassword = Yup.object().shape({
    oldPassword: Yup.string().required('Merci de renseigner votre ancien mot de passe'),
    password: Yup.string().required('Merci de renseigner votre nouveau mot de passe'),
    passwordConfirmation: Yup.string().required('Merci de renseigner votre confirmation de nouveau mot de passe')
     .oneOf([Yup.ref('password'), null], 'La confirmation de nouveau mot de passe ne correspond pas'),
  });

  return (
    <Layout>
      <Menu />
      <Container>
        <Row className="my-5">
          <Col xs={12} sm={{ span: 4, offset: 2 }}>
            <Card>
              <h3>Information personnelle</h3>
              <Formik
                validationSchema={schemaInfo}
                onSubmit={(values) => onSubmitInfo(values)}
                initialValues={{
                  company: customer?.company,
                  lastname: customer?.lastname,
                  firstname: customer?.firstname,
                  phone: customer?.phone,
                  email: customer?.email,
                  newsletter : customer?.newsletter
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Control
                      type='text' size='m' className={inputFormStyle} placeholder='Entreprise'
                      name="company"
                      value={values.company}
                      onChange={handleChange}
                      isValid={touched.company && !errors.company}
                      isInvalid={!!errors.company}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.company}
                    </Form.Control.Feedback>
                    <Form.Control
                      type='text' size='m' className={inputFormStyle} placeholder='Nom'
                      name="lastname"
                      value={values.lastname}
                      onChange={handleChange}
                      isValid={touched.lastname && !errors.lastname}
                      isInvalid={touched.lastname && !!errors.lastname}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.lastname}
                    </Form.Control.Feedback>
                    <Form.Control
                      type='text' size='m' className={inputFormStyle} placeholder='Prénom'
                      name="firstname"
                      value={values.firstname}
                      onChange={handleChange}
                      isValid={touched.firstname && !errors.firstname}
                      isInvalid={touched.firstname && !!errors.firstname}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.firstname}
                    </Form.Control.Feedback>
                    <Form.Control
                      type='phone' size='m' className={inputFormStyle} placeholder='Téléphone'
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      isValid={touched.phone && !errors.phone}
                      isInvalid={touched.phone && !!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                    <Form.Control
                      type='email' size='m' className={inputFormStyle} placeholder='Email'
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isValid={touched.email && !errors.email}
                      isInvalid={touched.email && !!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>

                    <Form.Check
                      name="newsletter"
                      id="newsletter"
                      value={values.newsletter}
                      onChange={handleChange}
                      isInvalid={touched.newsletter && !!errors.newsletter}
                      feedback={errors.newsletter}
                    >
                      <Form.Check.Input isInvalid={touched.newsletter && !!errors.newsletter} onChange={handleChange} checked={values.newsletter}/>
                      <Form.Check.Label>Reçevoir la newsletter</Form.Check.Label>
                    </Form.Check>

                    <div className='text-center'>
                      <Button padding="0.8rem 2.0rem;" margin="1.0rem 0 0 0"type="submit" size="lg" className='text-uppercase px-5 py-3 mt-2' disabled={isLoading && "disabled"}>
                        {isLoadingUpdateMe ?
                          <Loader
                            type="ThreeDots"
                            color="#ffffff"
                            height={25}
                            width={25}
                          />
                        : "Modifier"}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Card>
          </Col>
          <Col xs={12} sm={4}>
            <Card>
              <h3>Modification de mot de passe</h3>
              <Formik
                validationSchema={schemaPassword}
                onSubmit={(values) => onSubmitPassword(values)}
                initialValues={{
                  oldPassword: '',
                  password: ''
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                <Form noValidate onSubmit={handleSubmit}>

                  <Form.Control
                    type='password' size='m' className={inputFormStyle} placeholder='Ancien mot de passe'
                    name="oldPassword"
                    value={values.oldPassword}
                    onChange={handleChange}
                    isValid={touched.oldPassword && !errors.oldPassword}
                    isInvalid={touched.oldPassword && !!errors.oldPassword}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.oldPassword}
                  </Form.Control.Feedback>

                  <Form.Control
                    type='password' size='m' className={inputFormStyle} placeholder='Nouveau mot de passe'
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    isValid={touched.password && !errors.password}
                    isInvalid={touched.password && !!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>

                  <Form.Control
                    type='password' size='m' className={inputFormStyle} placeholder='Confirmation du nouveau mot de passe'
                    name="passwordConfirmation"
                    value={values.passwordConfirmation}
                    onChange={handleChange}
                    isValid={touched.passwordConfirmation && !errors.passwordConfirmation}
                    isInvalid={touched.passwordConfirmation && !!errors.passwordConfirmation}
                  />
                  <Form.Control.Feedback type="invalid" className="pb-3">
                    {errors.passwordConfirmation}
                  </Form.Control.Feedback>

                    <div className='text-center'>
                      <Button padding="0.8rem 2.0rem;" margin="1.0rem 0 0 0"type="submit" size="lg" className='text-uppercase px-5 py-3 mt-2' disabled={isLoading && "disabled"}>
                        {isLoadingEditPassword ?
                          <Loader
                            type="ThreeDots"
                            color="#ffffff"
                            height={25}
                            width={25}
                          />
                        : "Modifier"}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  );

};

const inputFormStyle = css`
  background-color: #ececec;
  border: none;
  margin: 1em 0;
`;

const Card = styled.div`
  padding: 2.0rem;
  background-color: white;
  border-radius: 6px;

  text-align: center;
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.02);
`;

Edit.propTypes = {
  onSubmitInfo: PropTypes.func,
  onSubmitPassword: PropTypes.func,
  isLoading: PropTypes.bool,
  isLoadingEditPassword: PropTypes.bool,
  isLoadingUpdateMe: PropTypes.bool,
  customer: PropTypes.object
};

Edit.defaultProps = {
};

export default Edit;
