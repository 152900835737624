import * as actionTypes from './types'
import { createReducer } from '../../../utils'

const initialState = {
  isLoading: false,
  displayBanners: []
};

const reducers = createReducer( initialState )( {

  [actionTypes.GET_DISPLAY_BANNERS]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.GET_DISPLAY_BANNERS_COMPLETED]: (state, action) => {
    return {
      ...state,
      isLoading: false,
      displayBanners: action.payload.result,
    };
  },
  [actionTypes.GET_DISPLAY_BANNERS_FAILED]: (state, action) => {
    return { ...state, isLoading: false };
  },

});

export default reducers;
