import React from 'react'
import { useDispatch } from 'react-redux';
import Modal from "views/commons/Modals/Modal"
import { operations as modalsOperations } from 'state/ducks/client/modals'
import Connexion from "views/commons/Connexion/Connexion"
import ContactContainer from "views/commons/Contact/ContactContainer"
import ClearCartContainer from "views/commons/ClearCart/ClearCartContainer"
import DateChangeProductContainer from "views/commons/DateChangeProduct/DateChangeProductContainer"
import styled from '@emotion/styled'

const Modals = (props) => {

  const {
    modals
  } = props

  const dispatch = useDispatch();

  return (
    modals.connexion ?
    <Modal
      isOpen={modals.connexion}
      onClose={() => dispatch(modalsOperations.setModalStatusConnexion(false))}
    >
      <Connexion />
    </Modal>
    : modals.contact.isOpen ?
      <Modal
        isOpen={modals.contact}
        onClose={() => dispatch(modalsOperations.setModalStatusContact(false))}
      >
        <ContactContainer title={modals?.contact?.title} description={modals?.contact?.description} />
      </Modal>
    : modals.clearCart ?
      <Modal
        isOpen={modals.clearCart}
        onClose={() => dispatch(modalsOperations.setModalStatusClearCart(false))}
      >
        <ClearCartContainer />
      </Modal>
    : modals.dateChangeProduct ?
      <Modal
        isOpen={modals.dateChangeProduct}
        onClose={() => dispatch(modalsOperations.setModalStatusDateChangeProduct(false))}
      >
        <DateChangeProductContainer />
      </Modal>
    : modals.generic.isOpen ?
      <Modal
        isOpen={modals.generic.isOpen}
        onClose={() => dispatch(modalsOperations.setModalStatusGeneric(false))}
      >
        <>
          <Title>{modals?.generic?.title ? modals.generic.title : "Oups"}</Title>
          <Description>{modals?.generic?.description ? modals.generic.description : "Nous rencontrons un problème, merci de nous contacter directement."}</Description>
        </>
      </Modal>
    :
      null
  )
}


const Description = styled.p`
  text-align: center;
  margin-top: 1.0rem;
`;

const Title = styled.h3`
  font-family: '${props => props.theme.fonts.family.script}';
  text-align: center;
  color: ${(props) => props.theme.colors.primary};
  font-size: ${props => props.theme.fonts.size.m};
  margin-top: 1.0rem;
`;

export default Modals;
