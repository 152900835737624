import React from 'react'
import ClearCart from "./ClearCart"
import { operations as modalsOperations } from 'state/ducks/client/modals'
import { operations as cartOperations } from 'state/ducks/client/cart'
import { useDispatch } from 'react-redux';

const ClearCartContainer = (props) => {

  const dispatch = useDispatch();

  const handleClearCart = () => {
    // On vide le panier si les produits sont incompatible avec le panier en cours
    dispatch(cartOperations.resetArticles());
    dispatch(modalsOperations.setModalStatusClearCart(false));
  }

  const handleRefuseClearCart = () => {
    // On ferme la modale si on ne veut pas vider le panier
    dispatch(modalsOperations.setModalStatusClearCart(false));
  }

  return (
    <ClearCart handleClearCart={handleClearCart} handleRefuseClearCart={handleRefuseClearCart}
    />
  )
}

ClearCartContainer.propTypes = {
};


export default ClearCartContainer;
