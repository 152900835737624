import React from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShoppingBag } from "@fortawesome/free-solid-svg-icons"
import { NavLink, Link } from "react-router-dom";
import PropTypes from "prop-types";
import SelectDateAndDeliveryContainer from "views/commons/SelectDateAndDelivery/SelectDateAndDeliveryContainer"
import ContentLoader from 'react-content-loader'
import { selectors as customerSelectors } from "state/ducks/api/customer";
import { useSelector, shallowEqual } from "react-redux";

// Menu de choix de la gamme de produit
const MenuRange = (props) => {

  const {
    cart,
    ranges,
    handleCartClick
  } = props;

  const customer = useSelector(
    (state) => customerSelectors.getCustomer(state),
    shallowEqual
  );
  
  const urlClassique = process.env.REACT_APP_LEMOULIN_URL+process.env.REACT_APP_LEMOULIN_URL_BASENAME+'#plateau-classique';
  const urlSignature = process.env.REACT_APP_LEMOULIN_URL+process.env.REACT_APP_LEMOULIN_URL_BASENAME+'#plateau-signature';

  return (
    <>
      <Container>
        <Menu>
          <div className="hor-scroll">
            <ul className="item-grid">
              {ranges ?
                ranges && ranges.map((range, index) =>
                  (range.active && range.showForCustomer) &&
                    customer && customer.distributor ?
                      range.name === "Supercookies" &&
                        <li key={index}>
                          <NavLink className="navigation__link" to={{ pathname: `/plateaux-repas/gamme/${range.slug}`}}>
                            {range.name}
                          </NavLink>
                        </li>
                      :
                        range.name !== "Supercookies" &&
                          <li key={index}>
                            <NavLink className="navigation__link" to={{ pathname: `/plateaux-repas/gamme/${range.slug}`}}>
                              {range.name}
                            </NavLink>
                          </li>
                    )
                :
                <ContentLoader viewBox="0 0 400 52" style={{ width: '100%' }} width={500}
      height={52}>
                  <rect x="070" y="13" rx="4" ry="10" width="90" height="25" />
                  <rect x="200" y="13" rx="4" ry="10" width="70" height="25" />
                  <rect x="300" y="13" rx="4" ry="10" width="80" height="25" />
                </ContentLoader>
              }
              <li>
              
                <a href={urlClassique}>
                  Mon plateau classique
                </a>

              </li>
              <li>
              <a href={urlSignature}>
                  Mon plateau signature
                </a>
              </li>
            </ul>
          </div>
          <CartButton onClick={handleCartClick}>
            <FontAwesomeIcon icon={faShoppingBag} />
            {cart?.productRanges[0]?.articles?.length > 0 && <Badge>{cart?.productRanges[0]?.articles?.length}
            </Badge>}
            <CartTitle> Panier</CartTitle>

          </CartButton>
        </Menu>
        <SelectDateAndDeliveryContainer />
      </Container>
    </>
  );

};

const Menu = styled.div`
  position: relative;
  min-height: 53px;
  padding-right: 65px;
`

const Badge = styled.span`
  position: absolute;
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: 50%;
  font-size: ${(props) => props.theme.fonts.size.xxxs};
  color: black;
  padding: 0rem 0.3rem;
  left: 1.6rem;
  top: 0.8rem;
  @media (max-width: 767px){
    left: 2.0rem;
  }
`
const CartTitle = styled.span`
  margin-left: 0.5rem;
  @media (max-width: 767px){
    display: none;
  }
`

const CartButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.darkGreen};
  cursor: pointer;
  color: white;
  transition: 300ms;
  font-family: '${props => props.theme.fonts.family.main}';
  font-size: ${(props) => props.theme.fonts.size.s};
  @media (max-width: 767px){
    font-size: ${(props) => props.theme.fonts.size.xs};
    padding: 1.0rem 1.5rem;
    box-shadow: -3px 0 5px -1px rgb(0 0 0 / 50%);
  }
  text-transform: uppercase;
  font-weight: bold;
  padding: 1.0rem;
  &:hover{
    background-color: #111;
  }
`

const Container = styled.div`
    background-color:  ${(props) => props.theme.colors.primary};
    box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.10);
    -moz-box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.10);
    -webkit-box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.10);
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    z-index: 1;
    .hor-scroll {
      overflow-x: auto;
      position: relative;
      @media screen and (max-width: 767px){
        overflow-x: scroll;
      }
    }
    .item-grid {
      white-space: nowrap;
    }
    ul{
      text-align: center;
      margin: 0;
      padding: 0;
      list-style: none;
      li{
        display: inline-block;
        margin-left: -4px;
        border-right: solid 1px rgba(255,255,255,0.2);
        &:first-of-type{
          border-left: solid 1px rgba(255,255,255,0.2);
        }
        a{
          display: block;
          padding: 1rem 1.5rem 1rem;
          color: white;
          font-family: '${props => props.theme.fonts.family.main}';
          font-size: ${(props) => props.theme.fonts.size.sm};
          @media (max-width: 767px){
            font-size: ${(props) => props.theme.fonts.size.s};
          }
          text-transform: uppercase;
          font-weight: bold;
          text-align: center;
          text-decoration: none;
          &.active, &:hover{
            color: white;
            background-color:  ${(props) => props.theme.colors.darkGreen};
          }
        }
      }
    }
    .divider{
      border-right: 1px rgba(255,255,255,0.3) solid;
    }
`;

MenuRange.propTypes = {
  dataProductRange: PropTypes.array,
  cart: PropTypes.object,
  handleCartClick: PropTypes.func,
};

export default MenuRange;
