import * as actions from './actions'
import * as rangeSelectors from './selectors'
import { selectors as cartClientSelectors, operations as cartClientOperations } from "state/ducks/client/cart";
import { selectors as cartApiSelectors, operations as cartApiOperations } from "state/ducks/api/cart";
import { operations as marketingOperations } from "state/ducks/api/marketing";
import moment from "moment";
import { operations as modalsOperations } from 'state/ducks/client/modals';
import { selectors as customerSelectors, operations as customerOperations } from 'state/ducks/api/customer';

const getRanges           = actions.getRanges;
const loadRangesDates     = actions.getRangesDates;
const setCurrentRangeSlug = actions.setCurrentRangeSlug;

const initialize = ( slug ) => (dispatch, getState) => {

  let isAuthenticated = customerSelectors.isAuthenticated(getState());

  // Les gammes + le slug
  dispatch( actions.getRanges() ).then(
    success => {dispatch(actions.setCurrentRangeSlug(slug))},
    error => {}
  );

  if(isAuthenticated){
    dispatch(customerOperations.getMe());
  }

  // Les dates disponibles + date par défaut
  dispatch(actions.getRangesDates())
  .then(() => {
      let dates = rangeSelectors.getDates(getState());
      let deliveryDate = cartClientSelectors.getDeliveryDate(getState());
      if (dates && dates.length > 0 && !deliveryDate) {
        // 06/12/2021: permet d'afficher l'offre de noel (qui demande 48h et non 24h)
        if (dates[1] !== undefined) {
          return dispatch(cartClientOperations.setDeliveryDate(dates[1]));
        }
        return dispatch(cartClientOperations.setDeliveryDate(dates[0]));
      }
      // Vide le panier si la date n'est plus dispo et met la première date de dispo par défaut
      else if (!dates.includes(moment(deliveryDate).format("YYYY-MM-DD"))) {
        if (dates && dates.length > 0) {
          dispatch(cartClientOperations.clean());
          dispatch(cartApiOperations.clean());
          dispatch(modalsOperations.setModalStatusGeneric(true, "Cette date n'est plus disponible, votre panier a été vidé, merci de choisir une nouvelle date.", "Oups !"));

          // Temporaire
          // 06/12/2021: permet d'afficher l'offre de noel (qui demande 48h et non 24h)
          if (dates[1] !== undefined) {
            return dispatch(cartClientOperations.setDeliveryDate(dates[1]));
          }
          return dispatch(cartClientOperations.setDeliveryDate(dates[0]));
        }
        else {
          dispatch(cartClientOperations.clean());
          dispatch(modalsOperations.setModalStatusGeneric(true, "Cette date n'est plus disponible, votre panier a été vidé, merci de choisir une nouvelle date.", "Oups !"));
          return dispatch(cartApiOperations.clean());
        }
      }
  });

  // Vérification du panier si la dernière vérif date de plus d'un jour
  let apiCart = cartApiSelectors.getCart(getState());
  if (apiCart) {
    let diff = 0;
    if (apiCart.checkedAt) {
      diff = moment().diff(moment(apiCart.checkedAt), 'days');
    }
    if (diff >= 1 && apiCart.id) {
      dispatch(cartApiOperations.checkCart(apiCart.id));
    }
  }

  // Bannière marketing
  dispatch(marketingOperations.getDisplayBanners());

}

export {
    initialize,
    getRanges,
    loadRangesDates,
    setCurrentRangeSlug
};
