import React from "react";
import Layout from "views/commons/Layout/Layout";
import Menu from "views/pages/Account/commons/Menu"
import { Container, Row, Col } from 'react-bootstrap'
import ItemContainer from "views/pages/Account/commons/Item/ItemContainer"
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'
import styled from '@emotion/styled'
import ReactPaginate from 'react-paginate';

const Invoices = (props) => {

  const {
    invoices,
    isLoading,
    handlePageClick,
    pageCount,
    offset,
    perPage
  } = props;

  return (
    <Layout>
      <Menu />
      <Container>
        <Row className="my-5">
        {isLoading ?
          [...Array(3)].map((e, i) =>
            <ContentLoader viewBox="0 0 1000 40" className="block-loader" key={i}>
              <rect x="10" y="5" rx="4" ry="4" width="900" height="13" />
              <rect x="10" y="20" rx="4" ry="4" width={i === 1 ? "200" : "400"} height="13" />
            </ContentLoader>
          )
        : invoices && invoices.length > 0 ?
          invoices.slice(offset - perPage, offset).map((invoice, index) =>
              <Col xs={12} key={index}>
                <ItemContainer item={invoice} />
              </Col>
          )
        :
          <Card>Vous n'avez pas de facture</Card>
        }
        {invoices && invoices.length > 0 &&
          <ReactPaginate
            previousLabel={"<<"}
            nextLabel={">>"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        }
        </Row>
      </Container>
    </Layout>
  );

};

const Card = styled.div`
  padding: 2.0rem;
  background-color: white;
  border-radius: 6px;
  width: 100%;
  text-align: center;
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.02);
`;

Invoices.propTypes = {
  isLoading: PropTypes.bool,
  invoices: PropTypes.array,
  handlePageClick: PropTypes.func,
  pageCount: PropTypes.number,
  offset: PropTypes.number,
  perPage: PropTypes.number
};

export default Invoices;
