import React from "react";
import Layout from "views/commons/Layout/Layout";
import MenuRangeContainer from "views/commons/Menu/MenuRangeContainer"
import ContactContainer from "views/commons/Contact/ContactContainer"
import MainImage from "views/commons/Blocks/MainImage"
import Reassurance from "views/commons/Blocks/Reassurance"
import PropTypes from "prop-types";
import RangeContainer from "views/pages/Mealtray/Ranges/Range/RangeContainer"
import { Container, Row, Col } from 'react-bootstrap'
import ContentLoader from 'react-content-loader'
import styled from "@emotion/styled";
import { selectors as customerSelectors } from "state/ducks/api/customer";
import { useSelector, shallowEqual } from "react-redux";
import FakeSupercookiesBox from "views/pages/Mealtray/Ranges/Range/FakeSupercookiesBox"
import FakeCocktail from "views/pages/Mealtray/Ranges/Range/FakeCocktail"

const Ranges = (props) => {

  const {
    cart,
    ranges,
    unlessOneRangeAvailable,
    isLoading
  } = props

  // On récupere le customer pour voir si revendeur et limiter les gammes
  const customer = useSelector(
    (state) => customerSelectors.getCustomer(state),
    shallowEqual
  );

  return (
    <Layout cart={cart}>
      <MainImage title={"Toutes les grandes décisions<br/>se prennent à table"} subTitle={"(Ou autour d’un buffet)"} label/>
      <MenuRangeContainer noDateAndAddress />
      <Reassurance />

      {!isLoading && unlessOneRangeAvailable === true && ranges ?
        ranges.map((range, index) =>
          (range.active && range.showForCustomer) &&
            customer && customer.distributor ?
              range.name === "Supercookies" &&
                <RangeContainer range={range} key={index} />
            :
              range.name !== "Supercookies" &&
                <RangeContainer range={range} key={index} />
        )
      : !isLoading && unlessOneRangeAvailable === false ?
        <RowBtp>
          <Col xs={12} sm={{ span: 8, offset: 2 }} md={{ span: 4, offset: 4}}>
            <Background>
              <ContactContainer
                title="Nous n'avons pas d'offre pour cette date à vous proposer." subTitle={"Sophie & Julie vous rappellent pour vous proposer une solution sur-mesure."} />
            </Background>
          </Col>
        </RowBtp>
      :
        <Container>
          {[...Array(3)].map((e, i) =>
            <ContentLoader  preserveAspectRatio="none" style={{ width: '100%' }} width={1000}
  height={100} className="block-loader" key={i}>
              <rect x="0" y="0" rx="5" ry="5" width="100" height="100" />
              <rect x="110" y="17" rx="4" ry="4" width="80%" height="13" />
              <rect x="110" y="40" rx="4" ry="4" width={i === 1 ? "20%" : "40%"} height="13" />
            </ContentLoader>
          )}
        </Container>
      }

      <Container>
        <Row className="mb-4">
          <FakeCocktail />
          <FakeSupercookiesBox />
        </Row>
      </Container>
      <br/>
    </Layout>
  );
};

const RowBtp = styled(Row)`
  margin: 1.5rem auto;
  @media (max-width: 767px){
   margin: 1em auto;
  }
`
const Background = styled.div`
  background-color: white;
  padding: 1.0rem;
  margin: 1.0rem 0;
`;

Ranges.propTypes = {
  cart: PropTypes.object,
  ranges: PropTypes.array,
  unlessOneRangeAvailable: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default Ranges;
