import { LoginContainer, RangesContainer, RangeContainer, Page404, ConfirmationContainer, QuotationsContainer, InvoicesContainer, EditContainer, ThanksOrderContainer, ResetPasswordContainer, PublicPayment } from "../views/pages/";

import { withAuthentication } from '../views/commons/enhancers'

const routes = [
  {
    path: "/",
    component: RangesContainer,
    exact: true,
  },
  {
    path: "/plateaux-repas/gamme/:slug",
    component: RangeContainer,
  },
  {
    path: "/confirmation",
    component: ConfirmationContainer,
  },
  {
    path: "/connexion",
    component: LoginContainer,
  },
  {
    path: "/reinitialisation-mot-de-passe",
    component: ResetPasswordContainer,
  },
  {
    path: "/compte/devis",
    component: withAuthentication(QuotationsContainer),
    exact: true,
  },
  {
    path: "/compte/factures",
    component: withAuthentication(InvoicesContainer),
    exact: true,
  },
  {
    path: "/compte/modification",
    component: withAuthentication(EditContainer),
    exact: true,
  },
  {
    path: "/confirmation",
    component: ConfirmationContainer,
  },
  {
    path: "/merci-pour-votre-commande",
    component: ThanksOrderContainer,
  },
  {
    path: "/paiement/facture/:token",
    component: PublicPayment,
  },
  {
    path: "*",
    component: Page404,
  },
];

export default routes;
