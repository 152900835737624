import React from 'react'
import styled from "@emotion/styled";
import noVisual from "assets/images/no-visual-big.jpg"
import { Row, Col } from 'react-bootstrap'
import { SOURCE_URI } from "config/constants"
import AttributProductContainer from "views/commons/Blocks/AttributProduct/AttributProductContainer"
import PropTypes from "prop-types";

const ItemDetail = (props) => {

  const {
    item,
    color
  } = props

  return (
    <Row>
      <Col xs={12} sm={12} md={5} lg={6}>
        <Visual src={item?.mediaUris?.normal ? `${SOURCE_URI}/${item.mediaUris.normal}` : noVisual} alt={item.name} />
        {item.products &&
          item.products.map((item, index) =>
            <MoreVisual key={index}>
              <ItemVisual src={item?.mediaUris?.normal ? `${SOURCE_URI}/${item.mediaUris.normal}` : noVisual} alt={item.name} />
            </MoreVisual>
          )
        }
      </Col>
      <Col xs={12} sm={12} md={7} lg={6}>
        <Title>{item.name}</Title>

        <Price color={color}>{(item.price / 100).toFixed(2)} € <small>HT</small>{item.pricePerPerson && " par personne"}</Price>

        <AttributProductContainer characteristics={item?.characteristics} />
        <hr />

        {(item.description) &&
          <Description dangerouslySetInnerHTML={{ __html: item.description }} />
        }

        {item.products &&
          item.products.map((product, index) =>
            <div key={index}>
              <ItemTitle><span>{product.quantity > 1 && "x"+product.quantity}</span> {product.name}</ItemTitle>
              <ItemDescription dangerouslySetInnerHTML={{ __html: product?.description }}/>
            </div>
          )
        }

      </Col>
    </Row>
  )
}

const Visual = styled.img`
  margin-bottom: 1.0rem;
  max-width: 100%;
  border-radius: 6px;
`

const MoreVisual = styled.div`
  @media (max-width: 767px){
    display: none;
  }
`

const ItemVisual = styled.img`
  margin-bottom: 1.0rem;
  max-width: 100%;
  border-radius: 6px;
`

const Price = styled.div`
  text-align: left;
  color: ${props => props.color ? props.color : props.theme.colors.primary};
  font-size: ${props => props.theme.fonts.size.m};
`;

const Title = styled.div`
  text-align: left;
  font-weight: bold;
  font-family: '${props => props.theme.fonts.family.main}';
  font-size: ${props => props.theme.fonts.size.l};
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`;

const Description = styled.div`
  text-align: left;
  color: grey;
  font-family: '${props => props.theme.fonts.family.bo}';
  margin-bottom: 1.0rem;
  font-size: ${props => props.theme.fonts.size.s};
`;

const ItemTitle = styled.div`
  text-align: left;
  font-weight: bold;
  font-family: '${props => props.theme.fonts.family.main}';
  font-size: ${props => props.theme.fonts.size.m};
  span{
    color: ${props => props.theme.colors.primary};
  }
`;

const ItemDescription = styled.div`
  text-align: left;
  color: grey;
  font-family: '${props => props.theme.fonts.family.main}';
  margin-bottom: 1rem;
  p{
    margin-bottom: 0rem;
    font-size: ${props => props.theme.fonts.size.xs};
  }
`;

ItemDetail.propTypes = {
  item: PropTypes.object,
  color: PropTypes.string
};

export default ItemDetail;
