import * as actionTypes from './types'
import { createReducer } from '../../../utils'

const initialState = {
  isInit: false,
  isLoading: false,
  data: null
};

const reducers = createReducer( initialState )( {
  [actionTypes.GET_DELIVERY_COSTS]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.GET_DELIVERY_COSTS_COMPLETED]: (state, action) => {
    return { ...state,
      data: action.payload.result,
      isLoading: false,
      isInit: true
    };
  },
  [actionTypes.GET_DELIVERY_COSTS_FAILED]: (state, action) => {
    return { ...state, isLoading: false, isInit: true };
  },
});

export default reducers;
