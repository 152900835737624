import * as actionTypes from './types'
// eslint-disable-next-line
import { dataProductRange } from './fakeData.js'
import { createReducer } from '../../../utils'

const initialState = {
  isInit: false,
  isLoading: false,
  isLoadingDeliveryAddress: false,
  data: null,
  deliveryCost: null,
  order: null,
  invoiceUrl: null,
  isChecking: false // Si le panier est en train d'être vérifié
};

const reducers = createReducer( initialState )( {
  [actionTypes.CLEAN]: (state, action) => {
    return { ...state, ...initialState, invoiceUrl: state.invoiceUrl};
  },

  [actionTypes.GET_CART]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.GET_CART_COMPLETED]: (state, action) => {
    return { ...state,
      data: action.payload.result,
      isLoading: false,
      isInit: true
    };
  },
  [actionTypes.GET_CART_FAILED]: (state, action) => {
    return { ...state, isLoading: false, isInit: true };
  },

  [actionTypes.POST_CART]: (state, action) => {
    if(action.payload.type === "deliveryAddress"){
      return { ...state, isLoading: true,  isLoadingDeliveryAddress: true };
    }else{
      return { ...state, isLoading: true};
    }
  },
  [actionTypes.POST_CART_COMPLETED]: (state, action) => {
    return { ...state,
      data: action.payload.result,
      isLoading: false,
      isLoadingDeliveryAddress: false,
      isInit: true
    };
  },
  [actionTypes.POST_CART_FAILED]: (state, action) => {
    return { ...state, isLoading: false, isLoadingDeliveryAddress: false, isInit: true };
  },

  [actionTypes.PUT_CART]: (state, action) => {

    if(action.payload.type === "deliveryAddress"){
      return { ...state, isLoading: true,  isLoadingDeliveryAddress: true };
    }else{
      return { ...state, isLoading: true};
    }

  },
  [actionTypes.PUT_CART_COMPLETED]: (state, action) => {
    return { ...state,
      data: action.payload.result,
      isLoading: false,
      isLoadingDeliveryAddress: false,
      isInit: true
    };
  },

  [actionTypes.PUT_CART_FAILED]: (state, action) => {
    return { ...state, isLoading: false, isLoadingDeliveryAddress: false, isInit: true };
  },

  [actionTypes.POST_ORDER]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.POST_ORDER_COMPLETED]: (state, action) => {
    return { ...state,
      order: action.payload.result,
      isLoading: false,
      isInit: true
    };
  },
  [actionTypes.POST_ORDER_FAILED]: (state, action) => {
    return { ...state,
      order: null,
      isLoading: false,
      isInit: true
    };
  },

  [actionTypes.CHECK_CART]: (state, action) => {
    return { ...state, isLoading: true, isChecking: true };
  },
  [actionTypes.CHECK_CART_COMPLETED]: (state, action) => {
    return { ...state,
      isLoading: false, isChecking: false
    };
  },
  [actionTypes.CHECK_CART_FAILED]: (state, action) => {
    return { ...state,
      isLoading: false, isChecking: false
    };
  },

  [actionTypes.GET_INVOICE_URL]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.GET_INVOICE_URL_COMPLETED]: (state, action) => {
    return { ...state,
      invoiceUrl: action.payload.result,
      isLoading: false,
      isInit: true
    };
  },
  [actionTypes.GET_INVOICE_URL_FAILED]: (state, action) => {
    return { ...state,
      invoiceUrl: null,
      isLoading: false,
      isInit: true
    };
  },
});

export default reducers;
