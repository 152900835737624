let cartModel = {};
cartModel.customerInfo = {
  lastName       : null,
  firstName      : null,
  phone          : null,
  company        : null,
  fullAddress    : null,
  moreInformation: null
};
cartModel.deliveryInfo = {
  deliveryDate    : null,
  lastName        : null,
  firstName       : null,
  phone           : null,
  company         : null,
  fullAddress     : null,
  moreInformation : null,
  lat             : null,
  lng             : null,
  deliveryCostHt  : null,
  deliveryCostTtc : null,
};
cartModel.billingInfo = {
  lastName       : null,
  firstName      : null,
  phone          : null,
  company        : null,
  fullAddress    : null,
  moreInformation: null
};
cartModel.priceInfo = {
  totalPriceHt                  : 0,
  totalPriceHtWithoutPromotion  : 0,
  totalPriceTtc                 : 0,
  totalPriceTtcWithoutPromotion : 0
};
cartModel.productRanges = [];
cartModel.silverwareInfo = [];

export default cartModel;
