import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { SOURCE_URI } from "config/constants"
import ContentLoader from 'react-content-loader'
import OptionBadge from "views/commons/Blocks/OptionBadge"

// Bandeau d'image de gamme principal
const RangeImage = (props) => {

  const {
    range
  } = props

  return (
    range ?
      <Container background={range?.backgroundUris?.normal && `${SOURCE_URI}/${range.backgroundUris.normal}`}>
        <h1>
          {range?.logoUris?.normal ?
            <img src={range?.logoUris?.normal && `${SOURCE_URI}/${range.logoUris.normal}`} alt={range.name} />
          :
            null
          }
        </h1>
        <p dangerouslySetInnerHTML={{__html: range.description && range.description}}></p>
        <OptionBadge orderBeforeDays={range?.orderBeforeDays && range.orderBeforeDays} minAmount={range?.minAmountOrder && range.minAmountOrder} />
        {/* <Banner>
          <Tippy content={"Nous sommes certifiées AB50%"}>
            <a href="https://lemoulin.fr/blog/le-moulin-est-certifie-restauration-ab50/" target="_blank" rel="noreferrer"><img style={{ 'marginRight' : '0.5rem' }} src={ab50} alt="Le Moulin labelisé AB50" className="min"/></a>
          </Tippy>
          <Tippy content={"Nous sommes agréés Entreprise solidaire d'utilité sociale"}>
            <a href="https://www.economie.gouv.fr/cedef/economie-sociale-et-solidaire" target="_blank" rel="noreferrer"><img style={{ 'marginRight' : '0.5rem' }} src={esus} alt="Label entreprise solidaire d'utilité sociale"/></a>
          </Tippy>
          <Tippy content={"Nous sommes agréés Lyon ville équitable & durable"}>
            <a href="https://www.lyon.fr/economie/economie-sociale-et-solidaire/le-label-lyon-ville-equitable-et-durable" target="_blank" rel="noreferrer"><img src={equitable} alt="Label lyon ville équitable et durable"/></a>
          </Tippy>
        </Banner> */}
      </Container>
    :
      <Container>
        <ContentLoader viewBox="0 0 1000 70">
          <rect x="450" y="00" rx="4" ry="4" width="100" height="30" />
          <rect x="425" y="40" rx="4" ry="4" width="150" height="10" />
          <rect x="460" y="60" rx="4" ry="4" width="80" height="10" />
        </ContentLoader>
      </Container>
  );

};

const Container = styled.div`
  position: relative;
  overflow: hidden;
  padding: 3.0rem 0.5rem;
  @media (max-width: 767px){
    padding: 2.0rem 0.5rem;
  }
  background-image: url(${props => props.background});
  background-size: cover;
  background-position: center center;
  background-color: #ccc;
  text-align: center;
  h1{
    text-align: center;
    margin: 0;
    img{
      max-width: 100%;
      width: 300px;
      @media (max-width: 767px){
        width: 180px;
      }
    }
  }
  p{
    color: white;
    text-align:center;
    font-size: ${props => props.theme.fonts.size.m};
    @media (max-width: 767px){
      font-size: ${(props) => props.theme.fonts.size.m};
    }
  }
`;

RangeImage.propTypes = {
  range: PropTypes.object,
};

export default RangeImage;
