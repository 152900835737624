import React from "react";
import Layout from "views/commons/Layout/Layout";
import MenuRangeContainer from "views/commons/Menu/MenuRangeContainer"
import ContactContainer from "views/commons/Contact/ContactContainer"
import RangeImage from "views/commons/Blocks/RangeImage"
import PropTypes from "prop-types";
import CategoryContainer from "./Category/CategoryContainer"
import { Container, Row, Col } from 'react-bootstrap'
import ContentLoader from 'react-content-loader'
import styled from "@emotion/styled";
import Reassurance from "views/commons/Blocks/Reassurance"

const Range = (props) => {

  const {
    cart,
    range,
    dateAvailable,
    addressDeliverable,
    rangeAvailable,
    displayBanners
  } = props

  return (
    <Layout cart={cart}>
      <RangeImage range={range} />
      <MenuRangeContainer />
      <Reassurance />
        <ContainerBtp>
        {range ?
            (dateAvailable && addressDeliverable && rangeAvailable)  ?
                range?.categories?.map((category, index) =>
                  displayBanners ?
                  <CategoryContainer category={category} key={index} index={index} color={range.color ? range.color : 'grey'} banners={displayBanners.filter(banner => banner.categoryId === category.id || banner.categoryParentId === category.id)}/> :
                  <CategoryContainer category={category} key={index} index={index} color={range.color ? range.color : 'grey'} banners={[]}/>
                )
              :
              <RowBtp>
                <Col xs={12} sm={{ span: 8, offset: 2 }} md={{ span: 4, offset: 4}}>
                  <Background>
                    <ContactContainer
                      title={(!dateAvailable && addressDeliverable) ? "Nous n'avons pas d'offre pour cette date à vous proposer." : (dateAvailable && !addressDeliverable) ? "Nous ne livrons pas encore dans cette zone" : "Nous ne pouvons pas vous proposer d'offre."} subTitle={"Sophie & Julie vous rappellent pour vous proposer une solution sur-mesure."} />
                  </Background>
                </Col>
              </RowBtp>
          :
            <RowBtp>
              {[...Array(4)].map((e, i) =>
                <Col xs={6} sm={4} md={4} lg={3} key={i} className="padding-loader">
                    <ContentLoader viewBox="0 0 1000 1200" className="block-loader">
                      <rect x="0" y="0" rx="5" ry="5" width="1000" height="510" />
                      <rect x="200" y="570" rx="4" ry="4" width="600" height="50" />
                      <rect x="70" y="680" rx="4" ry="4" width="900" height="30" />
                      <rect x="70" y="740" rx="4" ry="4" width="900" height="30" />
                      <rect x="70" y="800" rx="4" ry="4" width="900" height="30" />
                      {i !== 2 &&
                        <rect x="70" y="860" rx="4" ry="4" width={i === 1 ? "200" : i === 3 ? "400" : "900"} height="30" />
                      }
                      <rect x="750" y="1080" rx="4" ry="4" width={"200"} height="60" />
                    </ContentLoader>
                </Col>
              )}
            </RowBtp>
          }
        </ContainerBtp>

    </Layout>
  );
};

const ContainerBtp = styled(Container)`
  @media (max-width: 767px){
   margin: 1.5rem auto;
  }
`

const RowBtp = styled(Row)`
  margin: 1.5rem auto;
  @media (max-width: 767px){
   margin: 1em auto;
  }
`
const Background = styled.div`
  background-color: white;
  padding: 1.0rem;
  margin: 1.0rem 0;
`;

Range.propTypes = {
  cart: PropTypes.object,
  range: PropTypes.object,
  dateAvailable: PropTypes.bool,
  addressDeliverable: PropTypes.bool,
  rangeAvailable: PropTypes.bool,
  displayBanners: PropTypes.array
};

export default Range;
