export const theme = {
  colors: {
    primary: '#689D71',
    secondary: '#EFD5B2',
    inherit: '#111',
    darkGreen: '#2E6E55',
    grey: 'grey',
    lightgrey: '#cdcdcd',
    red: '#E07A5F'
  },
  fonts: {
    family: {
      main: "BrandonGrotesqueWeb-Regular",
      script: "photoshootregular",
      body: "Noto Serif TC"
    },
    size: {
      xxxs: "0.7rem",
      xxs: "0.8rem",
      xs: "0.9rem",
      s: "1rem",
      sm: "1.1rem",
      m: "1.2rem",
      l: "1.4rem",
      xl: "1.6rem",
      xxl: "1.8rem",
      xxxl: "2.0rem",
    },
  },
  responsive: {

  }
};
