import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import moment from "moment";
import SelectDateAndDelivery from "./SelectDateAndDelivery"
import { selectors as cartApiSelectors } from "state/ducks/api/cart";
import { operations as cartOperations, selectors as cartSelectors } from "state/ducks/client/cart";
import { selectors as selectorsModals, operations as operationsModals  } from "state/ducks/client/modals";
import { selectors as rangeSelectors } from "state/ducks/api/productRange";
import { operations as deliveryCostOperations, selectors as deliveryCostSelectors } from "state/ducks/api/deliveryCost";

/**
 * S'occupe de la maj de l'adresse (avec un object google place) et de la date de livraison
 */
const SelectDateAndDeliveryContainer = (props) => {

  const dispatch = useDispatch();

  const cart = useSelector(
    (state) => cartSelectors.getCart(state),
    shallowEqual
  );

  // Les dates de dispo par défaut
  const dates = useSelector(
    (state) => rangeSelectors.getDates(state),
    shallowEqual
  );

  const modal = useSelector(
    (state) => selectorsModals.getModalDeliveryInput(state),
    shallowEqual
  );

  const deliveryCosts = useSelector(
    (state) => deliveryCostSelectors.getDeliveryCosts(state),
    shallowEqual
  );

  const isLoadingDeliveryAddress = useSelector(
    (state) => cartApiSelectors.getIsLoadingDeliveryAddress(state),
    shallowEqual
  );

  const isLoadingDeliveryCost = useSelector(
    (state) => deliveryCostSelectors.isLoading(state),
    shallowEqual
  );

  const isLoadingDate = useSelector(
    (state) => rangeSelectors.isLoadingDate(state),
    shallowEqual
  );

  const deliveryDate = useSelector(
    (state) => cartSelectors.getDeliveryDate(state),
    shallowEqual
  );

  const [date, setDate] = useState(null);
  const [address, setAddress] = useState(null);

  const handler = (name, value) => {
    if (name === 'date') {
      setDate(value);
      dispatch(cartOperations.updateDeliveryDate(value));
    }
    if (name === 'address') {
      setAddress(value);
    }
    if (name === 'googlePlace') {
      dispatch(cartOperations.updateAddressFromPlace(value, "deliveryAddress"));
    }
  };

  const handlerCloseModal = () => {
    dispatch(operationsModals.setModalStatusDeliveryInput(false, "Pour commencer votre panier, merci d’indiquer votre adresse et date de livraison"));
  }

  // Valeur de la date initialement
  useEffect(() => {
    if (deliveryDate) {
      setDate(deliveryDate);
    }
    else if (dates && dates.length > 0) {
      setDate(dates[0]);
    }
    if (cart?.deliveryAddress?.address?.complete) {
      setAddress(cart.deliveryAddress.address.complete);
    }
  }, [cart, dates, setDate, setAddress, deliveryDate]);

  useEffect(() => {
    if (deliveryCosts === null) {
      dispatch(deliveryCostOperations.getDeliveryCosts());
    }
  }, [dispatch, deliveryCosts]);

  let formattedDate = null;
  if (date) {
    formattedDate = moment(date).format("YYYY-MM-DD");
  }

  return (
    <SelectDateAndDelivery cart={cart} date={formattedDate} address={address} handler={handler} modal={modal} handlerCloseModal={handlerCloseModal} availableDates={dates} isLoadingDeliveryCost={isLoadingDeliveryAddress || isLoadingDeliveryCost} isLoadingDate={isLoadingDate}/>
  )
}

SelectDateAndDeliveryContainer.propTypes = {
};

export default SelectDateAndDeliveryContainer;
