import React from 'react'
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

const RouteChangeTracker = ({ history }) => {

    history.listen((location, action) => {
        ReactGA.set({ page: process.env.REACT_APP_LEMOULIN_URL_BASENAME+location.pathname });
        ReactGA.pageview(process.env.REACT_APP_LEMOULIN_URL_BASENAME+location.pathname);
    });

    return <div></div>;
};

export default withRouter(RouteChangeTracker);
