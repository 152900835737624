import React from "react";
import { Button as ButtonBtsp } from 'react-bootstrap'
import PropTypes from "prop-types";
import styled from "@emotion/styled";

const Button = (props) => {

  const {
    children,
    color,
    variant,
    full,
    padding,
    onClick,
    type,
    margin,
    disabled,
    fontSize,
    texttransform
  } = props

  return (
    variant === "outline" ?
      <MyButtonOutline texttransform={texttransform} fontSize={fontSize} disabled={disabled} margin={margin} color={color} type={type} padding={padding} variant={variant} full={full} onClick={onClick}>
        {children}
      </MyButtonOutline>
    :
      <MyButton texttransform={texttransform} fontSize={fontSize} disabled={disabled} margin={margin} color={color} type={type} padding={padding} variant={variant} full={full} onClick={onClick}>
        {children}
      </MyButton>
  );
};

const MyButton = styled(ButtonBtsp)`
  background-color: ${props => props.color} !important;
  color: "white";
  transition: 300ms;
  border: ${props => props.color ? props.color : "#689D71"} solid 1px !important;
  svg{
    /* margin-right: 0.3rem; */
  }
  font-size: ${props => props.fontSize && props.fontSize};
  padding: ${props => props.padding && props.padding};
  margin: ${props => props.margin && props.margin};
  width: ${props => props.full && "100%"};
  text-transform: ${props => props.texttransform && props.texttransform};
  &:hover, &:focus, &:active{
    background-color: ${props => props.color ? props.color : "#689D71"} !important;
    border: ${props =>  props.color} solid 1px;
    color: ${props => props.color === "white" ? "#111" : "white"} !important;
    opacity: 0.7};
    transition: 300ms;
  }
  &:disabled, &.disabled{
    transition: 300ms;
    opacity: 0.5;
    &:hover, &:focus, &:active{
      opacity: 0.5;
    }
  }
`

const MyButtonOutline = styled(ButtonBtsp)`
  background-color: ${props => props.color ? "inherit" : props.color};
  color: ${props => props.color ? props.color : "#689D71" };
  transition: 300ms;
  border: ${props => props.color ? props.color : "#689D71"} solid 1px;
  svg{
    /* margin-right: 0.3rem; */
  }
 font-size: ${props => props.fontSize && props.fontSize};
  padding: ${props => props.padding && props.padding};
  margin: ${props => props.margin && props.margin};
  width: ${props => props.full && "100%"};
  text-transform: ${props => props.texttransform && props.texttransform};
  &:hover, &:focus, &:active{
    background-color: ${props => props.color ? props.color : "#689D71"} !important;
    border: ${props => props.color ? props.color : "#689D71"} solid 1px;
    color: ${props => props.color === "white" ? "#111" : "white"} !important;
    opacity:  1;
    transition: 300ms;
  }
  &:disabled, &.disabled{
    transition: 300ms;
    background-color: ${props => props.color ? "inherit" : props.color};
    border: ${props => props.color ? props.color : "#689D71"} solid 1px;
    color: ${props => props.color ? props.color : "#689D71"};
    opacity: 0.5;
    &:hover, &:focus, &:active{
      background-color: white !important;
      color: ${props => props.color ? props.color : "#689D71"} !important;
      opacity: 0.5;
    }
  }

`

Button.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  full: PropTypes.bool,
  textTransform: PropTypes.string
};

export default Button;
