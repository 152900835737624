import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import defaultImage from "assets/images/product-range-other.jpg"
import { SOURCE_URI } from "config/constants"
import Button from "styles/Button"
import { Col } from 'react-bootstrap'

const Item = (props) => {

  const {
    item,
    link,
    color,
    rangeAble
  } = props

  return (
    !item.group ?
      <Col xs={12} sm={12} md={3} >
        <Card>
          <>
            <Link to={{ pathname: `/plateaux-repas/gamme/${link}`}}><Image><img src={item.product.mediaUris?.square ? `${SOURCE_URI}/${item.product.mediaUris.square}` : defaultImage } alt={item.product.name}/></Image></Link>
            <Content>
              <Title>{item.product.name}</Title>
              <Description dangerouslySetInnerHTML={{__html: item.product.description}}></Description>
            </Content>
            <Link to={{ pathname: `/plateaux-repas/gamme/${link}`}}><Button variant="outline" padding="0.5rem 1.5rem" color={color}> Découvrir</Button></Link>
          </>
        </Card>
      </Col>
    :
    !item.group.fake ?
      <Col xs={12} sm={12} md={3} >
        <Card>
          {item.group != null &&
            <>
              <Link to={{ pathname: `/plateaux-repas/gamme/${link}`}}><Image><img src={item.group?.mediaUris?.square ? `${SOURCE_URI}/${item.group.mediaUris.square}` : defaultImage } alt={item.name}/></Image></Link>
              <Content>
                <Title>{item.group.name}</Title>
                <Description>{(item.group.description).replace(/<[^>]+>/g, '')}</Description>
              </Content>
              <Link to={{ pathname: `/plateaux-repas/gamme/${link}`}}><Button variant="outline" padding="0.5rem 1.5rem" color={color}> Découvrir</Button></Link>
            </>
          }

        </Card>
      </Col>
    : item.group.fake ?
        <Card>
            <>
              <Link to={{ pathname: `/plateaux-repas/gamme/${link}`}}><Image><img src={item.group?.mediaUris?.square ? `${SOURCE_URI}/${item.group.mediaUris.square}` : defaultImage } alt={item.name}/></Image></Link>
              <Content>
                <Title>{item.group.name}</Title>
                <Description>{(item.group.description).replace(/<[^>]+>/g, '')}</Description>
              </Content>
              {rangeAble ?
                <Link to={{ pathname: `/plateaux-repas/gamme/${link}`}}><Button variant="outline" padding="0.5rem 1.5rem" color={color}> Découvrir</Button></Link>
                :
                <div>Indisponible</div>
              }
            </>
        </Card>
    : null
  );
};

const Card = styled.div`
  text-align: center;
  border-radius: 4px;
  background-color: white;
  padding: 1.0rem;
  margin: 0.5rem 1rem;
  @media (max-width: 767px){
    margin: 0.5rem;
  }
  height: 100%;
  :last-child{
    @media (max-width: 767px){
      margin-right: 0.5rem;
    }
    margin-right: 0rem;
  }
`;

const Content = styled.div`
  min-height: 120px;
  @media (max-width: 767px){
    min-height: auto;
  }
`
const Title = styled.div`
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-size: ${props => props.theme.fonts.size.sm};
  @media (max-width: 767px){
    font-size: ${props => props.theme.fonts.size.s};
  }
  font-family: '${props => props.theme.fonts.family.main}';
  font-weight: bold;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`;

const Description = styled.div`
  margin-bottom: 1.0rem;
  font-size: ${props => props.theme.fonts.size.xs};
  @media (max-width: 767px){
    font-size: ${props => props.theme.fonts.size.xxs};
  }
  color: grey;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  min-height: 60px;
  @media (max-width: 767px){
    min-height: auto;
  }
`;

const Image = styled.div`
  img{
    border-radius: 50%;
    max-width: 100%;
    width: 120px;
    @media (max-width: 767px){
      width: 100px;
    }
    margin-bottom: 1.0rem;
    &:hover{
      opacity: 0.7;
      transition: 300ms;
    }
  }
`;

Item.propTypes = {
  item: PropTypes.object,
  link: PropTypes.string,
  rangeaAble: PropTypes.bool
};

export default Item;
