import React, {useState} from "react";
import { Row, Col } from 'react-bootstrap'
import styled from "@emotion/styled";
import moment from "moment";
import ItemContainer from "./Item/ItemContainer"
import DisplayBannerContainer from "./DisplayBanner/DisplayBannerContainer"
import PropTypes from "prop-types";
import Modal from "views/commons/Modals/Modal"

const Category = (props) => {

  const {
    category,
    index,
    color,
    banners,
    deliveryDate
  } = props;

  const [popup, setPopup] = useState(false);

  const Categories = ({category, notFirst}) => {

    // Filtre les produits, nécessaire de la faire avant pour les bannières marketing
    const filteredItems = category?.items?.filter((item) =>
      item.active &&
      item?.availableDates.includes(moment(deliveryDate).format('YYYY-MM-DD'))
    );

    // Permet d'afficher les bannières des catégories sans produits mais avec des sous catégorie
    const parentCategoryWithoutItem = category.items.length === 0 && category.children.length > 0;

    // Change la position si la position d'une bannière est supérieure au nombre de produits affichées
    let filteredBanners = banners.filter(banner => banner.categoryId === category.id);
    if (banners.length > 0 && filteredItems.length > 0) {
      filteredBanners.forEach(banner => {
        if (banner.position > filteredItems.length) {
          banner.position = filteredItems.length + 1;
        }
      })
    }

    return (
      category.active &&
        <>
          {(notFirst !== 0 && category?.items.length > 0) &&
            <Col xs={12} className={"text-center"}>
              <Block>
                <Title>{category?.name}</Title>

                {/*Temporaire à supprimer apres supercookies */}
                {category.name === "Supercookies" ?
                  <>
                    <Button onClick={() => setPopup(true)}>Je découvre</Button>
                    <Modal
                      isOpen={popup}
                      onClose={() => setPopup(false)}
                    >
                      <img src="https://lemoulin.fr/dl/supercookies/infographie-supercookies.png" alt="Supercookies c'est quoi ?" />
                    </Modal>
                  </>
                  :
                  category?.description && <Description color={color}>{category?.description}</Description>
                }

              </Block>
            </Col>
           }

           {parentCategoryWithoutItem && filteredBanners.length > 0 && filteredBanners.map((banner, index) => (
             <DisplayBannerContainer banner={banner} key={index}/>
           ))}

          {filteredItems.map((item, index) => (
            <React.Fragment key={index}>
              {filteredBanners.length > 0 && filteredBanners.map((banner) => (
                (banner.position - 1 === index && <DisplayBannerContainer banner={banner} key={index}/>)
              ))}

              <ItemContainer item={item?.group ? item.group : item.product} color={color} key={index} categoryName={category.name}/>

              {filteredBanners.length > 0 && filteredBanners.map((banner) => (
                (filteredItems.length - 1 === index && banner.position === filteredItems.length + 1 && <DisplayBannerContainer banner={banner} key={index}/>)
              ))}

            </React.Fragment>
          ))}

          {category?.children?.map((categoryItem, index) =>
              <Categories category={categoryItem} key={index} />
          )}
        </>
    )
  }

  return (
    <Margin>
      {category &&
        <Row>
          <Categories category={category} notFirst={index}/>
        </Row>
      }
    </Margin>
  );
};


const Margin = styled.div`
  margin: 0rem auto ;
  @media (max-width: 767px){
   margin: 0rem auto 0;
  }
`

const Button = styled.div`
  background-color: #689D71;
  border: #689D71;
  color: white;
  cursor: pointer;
  -webkit-transition: 300ms;
  transition: 300ms;
  padding: 0.5rem 1.5rem;
  display: inline-block;
  margin-top: 0.5rem;
  border-radius: 4px;
  &:hover{
    opacity: 0.7;
    text-decoration: none;
  }
`

const Block = styled.div`
  display: inline-block;
  border-top: 2px black solid;
  border-bottom: 2px black solid;
  padding: 0.5rem 0;
  text-align: center;
  margin: 1.0rem auto 2.0rem;
  @media (max-width: 767px){
   margin: 1.0rem auto 2.0rem;
  }
`;

const Title = styled.p`
  margin-bottom: 1.0rem;
  font-family: '${props => props.theme.fonts.family.main}';
  text-transform: uppercase;
  font-weight: bold;
  font-size: ${(props) => props.theme.fonts.size.m};
  margin-bottom: 0rem;
`;

const Description = styled.p`
  margin-bottom: 1.0rem;
  font-size: ${(props) => props.theme.fonts.size.s};
  color: ${(props) => props.color ? props.color : props.theme.colors.darkGreen};
  font-weight: bold;
  margin-bottom: 0rem;
`;

Category.propTypes = {
  index: PropTypes.number,
  category: PropTypes.object,
  color: PropTypes.string,
  banners: PropTypes.array,
  deliveryDate: PropTypes.string
};

export default Category;
