import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import Range from "views/pages/Mealtray/Ranges/Range/Range"
import { selectors } from "state/ducks/api/cart";
import { selectors as cartSelectors } from "state/ducks/client/cart";

const RangeContainer = (props) => {

  const {
    range
  } = props

  const cart = useSelector(
    (state) => selectors.getCart(state),
    shallowEqual
  );

  const deliveryDate = useSelector(
    (state) => cartSelectors.getDeliveryDate(state),
    shallowEqual
  );

  let rangeAble = false;

  if (range?.availableDates) {
    rangeAble = range?.availableDates.includes(moment(deliveryDate).format('YYYY-MM-DD'));
  }


  // On veut afficher que les produits de groupe dispo aujourd'hui
  let sortItems = []

  range?.categories?.map((category, index) => {
    if(category.active){
      return category?.items?.map((item, indexCat) => {
        if(item.active && item.group !== null && item?.availableDates.includes(moment(deliveryDate).format('YYYY-MM-DD'))){
          return sortItems = [...sortItems, item]
        }
        //Temporaire à enlever après supercookies
        else if(
          item.active && item?.availableDates.includes(moment(deliveryDate).format('YYYY-MM-DD')) && (item.product.name === "Assortiment : 6 cookies" || item.product.name === "Choco-noisettes : 6 cookies" || item.product.name === "Noir intense : 6 cookies" || item.product.name === "Trio choco : 6 cookies")
        ){
          return sortItems = [...sortItems, item]
        }
        return null;
      })
    }
    return null;
  })

  return (
    <Range range={range} cart={cart} rangeAble={rangeAble} deliveryDate ={deliveryDate} sortItems={sortItems}/>
  );

};

RangeContainer.propTypes = {
  range: PropTypes.object,
};

export default RangeContainer;
