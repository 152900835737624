import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import defaultBackground from "assets/images/offre-pro-ambiance2.jpg";
import { Container, Row, Col } from 'react-bootstrap';

// Bandeau d'image principal
const MainImage = (props) => {

  const {
    background,
    title,
    subTitle,
  } = props

  return (
    <ContainerBtp background={background ? background : defaultBackground}>
      <Container>
        <Row className="align-items-center">
          <Col xs={12} sm={12}>
            <h1 dangerouslySetInnerHTML={{__html: title}}></h1>
            <p dangerouslySetInnerHTML={{__html: subTitle}}></p>
          </Col>
          {/* <Col xs={12} sm={4}>
            <Center>
              <Supercookies background={bgSupercookies}>

                <img src={ab50} alt="Le Moulin labelisé AB50" title="Le Moulin labelisé AB50" />

                <>
                  <h2>Le Moulin est certifié<br/>restauration AB50%</h2>
                  <a href="https://lemoulin.fr/blog/le-moulin-est-certifie-restauration-ab50/" target="_blank">QUEZAKO ?</a>
                </>
              </Supercookies>
            </Center>
          </Col>*/}
        </Row>
      </Container>

      {/* {label &&
        <Banner>
          <Tippy content={"Nous sommes certifiées AB50%"}>
            <a href="https://lemoulin.fr/blog/le-moulin-est-certifie-restauration-ab50/" target="_blank" rel="noreferrer"><img style={{ 'marginRight' : '0.5rem' }} src={ab50} alt="Le Moulin labelisé AB50" className="min"/></a>
          </Tippy>
          <Tippy content={"Nous sommes agréés Entreprise solidaire d'utilité sociale"}>
            <a href="https://www.economie.gouv.fr/cedef/economie-sociale-et-solidaire" target="_blank" rel="noreferrer"><img style={{ 'marginRight' : '0.5rem' }} src={esus} alt="Label entreprise solidaire d'utilité sociale"/></a>
          </Tippy>
          <Tippy content={"Nous sommes agréés Lyon ville équitable & durable"}>
            <a href="https://www.lyon.fr/economie/economie-sociale-et-solidaire/le-label-lyon-ville-equitable-et-durable" target="_blank" rel="noreferrer"><img src={equitable} alt="Label lyon ville équitable et durable"/></a>
          </Tippy>
        </Banner>
      } */}
    </ContainerBtp>
  );

};

const ContainerBtp = styled.div`
  position: relative;
  overflow: hidden;
  padding: 4.0rem 0.5rem 4rem 0.5rem;
  background-image: url(${props => props.background});
  background-size: cover;
  background-position: center center;
  @media (max-width: 767px){
    padding: 2.5rem 0.5rem;
  }
  h1{
    text-align: center;
    color: white;
    margin: 0;
    font-family: '${props => props.theme.fonts.family.script}';
    font-size: ${props => props.theme.fonts.size.xxl};
    line-height: 1.7;
    @media (max-width: 767px){
      font-size: ${props => props.theme.fonts.size.m};
    }
  }
  p{
    text-align: center;
    color: white;
    font-size: ${props => props.theme.fonts.size.m};
    margin-bottom: 0rem;
  }
`;

MainImage.propTypes = {
  background: PropTypes.string,
  title: PropTypes.string,
};

export default MainImage;
