import React from "react";
import { operations as modalsOperations } from 'state/ducks/client/modals'
import { useDispatch } from 'react-redux';
import CallFooter from "views/commons/Blocks/CallFooter/CallFooter"

const CallFooterContainer = (props) => {

  const dispatch = useDispatch();

  const handleContactModal = () => {
    dispatch(modalsOperations.setModalStatusContact(true))
  }

  return (
    <CallFooter handleContactModal={handleContactModal} />
  );

};

export default CallFooterContainer;
