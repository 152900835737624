import React from 'react'
import { useDispatch } from 'react-redux';
import Contact from './Contact'
import { CALL_FOOTER } from "config/constants"
import PropTypes from 'prop-types'
import { useSelector, shallowEqual } from "react-redux";
import { selectors as customerSelectors } from "state/ducks/api/customer";
import { operations as businessOperations, selectors as businessSelectors } from "state/ducks/api/business";

const ContactContainer = (props) => {

  const{
    title,
    description
  } = props;

  const dispatch = useDispatch();

  const customer = useSelector(
    (state) => customerSelectors.getCustomer(state),
    shallowEqual
  );

  const isLoading = useSelector(
    (state) => businessSelectors.isLoading(state),
    shallowEqual
  );

  const onSubmit = (values) => {
    dispatch(businessOperations.sendContact(values.lastname, values.phone, values.message, values.email))
  }

  return(
    <Contact onSubmit={onSubmit} text={CALL_FOOTER} title={title} description={description} customer={customer} isLoading={isLoading}/>
  )
}

ContactContainer.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

export default ContactContainer;
