import React from 'react'

const Translate = (props) => {

  const {
    item
  } = props


  function translateType() {
    if(item === 'breakfast'){
      return 'Petit-déjeuner'
    }
    else if(item === 'v2'){
      return 'Plateaux-repas'
    }
    else if(item === 'mealtray'){
      return 'Plateaux-repas'
    }
    else if(item === 'caterer'){
      return 'Traiteur'
    }
    else if(item === '101'){
      return 'En attente de validation'
    }
    else if(item === '102'){
      return 'Devis validé'
    }
    else if(item === '103'){
      return 'Devis validé'
    }
    else if(item === '201'){
      return 'En attente de réglement'
    }
    else if(item === '203'){
      return 'Réglé'
    }
    else if(item === '400'){
      return 'Refusée'
    }
    else if(item === '401'){
      return 'Refusée'
    }
    else if(item === '402'){
      return 'Refusée'
    }
    else if(item === '411'){
      return 'Refusée'
    }
    else if(item === '412'){
      return 'Refusée'
    }
    else if(item === '413'){
      return 'Annulée'
    }
    return 'Problème de trad'
  }

  return (
    <span>
      {translateType()}
    </span>
  )

}

export default Translate
