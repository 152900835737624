import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Row, Col } from 'react-bootstrap'
import Translate from 'utils/Translate'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileDownload, faTimes, faEdit } from "@fortawesome/free-solid-svg-icons"
import Button from "styles/Button"
import moment from "moment";
import { Link } from "react-router-dom";
import Tippy from '@tippyjs/react';

const Item = (props) => {

  const {
    item,
    handleEditOrder,
    handleCancelOrder,
    handleValidateQuotation,
    handleGenerateQuotation
  } = props

  return (
    <Card>
      <Row className="align-items-center">
        <Event xs={12} sm={4}>
          <Type>
          <Translate item={item.type} /> - {item.reference}</Type><br/>
          Evènement le {moment(item.eventDate).format("DD/MM/Y")}
        </Event>
        <Status xs={12} sm={4}>
          {(item.price / 100).toFixed(2)+"€ HT"} / {(item.priceTTC / 100).toFixed(2)+"€ TTC"}<br/>
          {moment() > moment(item.orderModifiableTill) &&
            ["101"].includes(item.status) ?
              <div>Devis expiré</div>
              :
              <Translate item={item.status} />
            }
        </Status>
        <Actions xs={12} sm={4}>

          {["101", "102", "103", "201", "203"].includes(item.status) &&
            item.invoiceDownloadUrl &&
            <a href={item.invoiceDownloadUrl} target="_blank" rel="noreferrer">
              <Button
                margin="0 0.2rem"
              >
                <FontAwesomeIcon icon={faFileDownload} />
              </Button>
            </a>
          }

          {["201"].includes(item.status) &&
            <>
            <span dangerouslySetInnerHTML={{__html: item.paymentForm}}></span>
              <Tippy content={"Cliquer pour télécharger notre RIB afin d'effectuer votre virement."}>
                <Link to="/files/rib-lemoulinasalades.pdf" target="_blank" download>
                  <Button
                    margin="0 0.2rem"
                    texttransform="inherit"
                  >
                    Payer par virement
                  </Button>
                </Link>
              </Tippy>
            </>
          }

          {moment() <= moment(item.orderModifiableTill) &&
            ["101", "102", "103"].includes(item.status) &&
              <>


                <Button
                  color="#E07A5F"
                  margin="0 0.2rem"
                  onClick={() => handleCancelOrder(item.type, item.id)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
                {item.isAdminEdited === false  &&
                  <Button
                    color="#bbb"
                    margin="0 0.2rem"
                    onClick={() => handleEditOrder("Modification de commande", "Appelez-nous au 06 38 13 35 24 ou écrivez nous ci-dessous pour votre demande. Merci de préciser votre numéro de commande.")}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>

                }

                {["101"].includes(item.status) &&
                  <Button
                    color="#111"
                    margin="0 0.2rem"
                    onClick={() => handleValidateQuotation(item.type, item.id)}
                  >
                    Valider
                  </Button>
                }

              </>
          }

          {item.missingDocument &&
            <Button
              color="#111"
              margin="0 0.2rem"
              onClick={() => handleGenerateQuotation(item.type, item.id)}
            >
              Génerer document
            </Button>
          }

        </Actions>
      </Row>
    </Card>
  );

};

const Event = styled(Col)`
  text-align: left;
  @media (max-width: 767px){
   text-align: center;
  }
`;

const Status = styled(Col)`
  text-align: left;
  @media (max-width: 767px){
   text-align: center;
  }
`;

const Actions = styled(Col)`
  text-align: right;
  @media (max-width: 767px){
   text-align: center;
  }
`;

const Card = styled.div`
  padding: 1.0rem;
  background-color: white;
  border-radius: 6px;
  margin-bottom: 1rem;
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.02);
`;

const Type = styled.span`
  text-transform: uppercase;
  font-weight: bold;
  font-family: '${props => props.theme.fonts.family.main}';
  font-size: ${props => props.theme.fonts.size.xs};
`;

Item.propTypes = {
  item: PropTypes.object,
  handleEditOrder: PropTypes.func,
  handleCancelOrder: PropTypes.func,
  handleValidateQuotation: PropTypes.func,
  handleGenerateQuotation: PropTypes.func
};

export default Item;
