import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {Row, Col, Container, InputGroup} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import Autocomplete from "react-google-autocomplete";
import Tippy from '@tippyjs/react';
import Loader from "react-loader-spinner";

const SelectDateAndDelivery = (props) => {

  const {
    date,
    address,
    handler,
    modal,
    handlerCloseModal,
    availableDates,
    isLoadingDeliveryCost,
    isLoadingDate,
    cart
  } = props

  return (
    <Banner>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={{ span: 5, offset: 1 }}>
            <Tippy
              content={
                <>
                  <p className="mb-1">{modal.text}</p>
                </>
              }
              visible={modal.isOpen}
              interactive={true}
              placement="bottom"
              className="tippy-error"
            >
              <InputGroup onClick={handlerCloseModal}>
                <InputGroup.Text>
                  {!isLoadingDeliveryCost ?

                    cart?.deliveryAddress?.available === true ?
                      <FontAwesomeIcon icon={faCheck} />
                    : cart?.deliveryAddress?.available === false ?
                      <FontAwesomeIcon icon={faTimes} />
                    :
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                    :
                    <Loader
                      type="ThreeDots"
                      color="#ffffff"
                      height={20}
                      width={20}
                    />
                  }

                </InputGroup.Text>

                <Autocomplete
                  className={"form-control"}
                  onPlaceSelected={(place) => {
                    handler('googlePlace', place);
                  }}
                  options={{
                    types: ["address"],
                    componentRestrictions: { country: "fr" },
                    fields: ["address_components", "geometry.location", "formatted_address"]
                  }}
                  defaultValue={address}
                  placeholder="Mon lieu de livraison ?"
                  disabled={isLoadingDeliveryCost}
                />
              </InputGroup>
            </Tippy>
          </Col>
          <Col xs={12} sm={12} md={5}>
            <InputGroupDate>

              <InputGroup.Text>
                {!isLoadingDate ? "le"
                  :
                  <Loader
                    type="ThreeDots"
                    color="#ffffff"
                    height={20}
                    width={20}
                  />
                }
              </InputGroup.Text>
              <div className="full">
                <DatePickerCustomer
                  selected={date && new Date(date)}
                  onChange={(dateSelected) => {
                      (moment(dateSelected).format("YYYY-MM-DD") !== moment(date).format("YYYY-MM-DD")) &&
                      handler('date', moment(dateSelected).format("YYYY-MM-DD"))
                    }
                  }
                  dateFormat="dd/MM/yyyy"
                  name="startDate"
                  locale="fr"
                  filterDate={(d) => availableDates.includes(moment(d).format("YYYY-MM-DD"))}
                  className="form-control"
                  placeholderText="Date de votre évènement"
                  disabled={isLoadingDate}
                />
              </div>
            </InputGroupDate>
          </Col>
        </Row>
      </Container>
    </Banner>
  )
}


const InputGroupDate = styled(InputGroup)`
  @media (max-width: 767px){
    margin-top: 0.5rem;
  }
  .full{
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
  }
  .react-datepicker-wrapper{
    width: 100%;
  }
`
const DatePickerCustomer = styled(DatePicker)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`
const Banner = styled.div`
  background-color: white;
  padding: 1rem;
  @media (max-width: 767px){
    padding: 0.8rem 0.5rem;
  }
  .form-control{
    font-size: ${props => props.theme.fonts.size.xs};
  }
  .input-group-text{
    background-color: ${(props) => props.theme.colors.primary};
    color: white;
    border-radius: 4px 0 0 4px;
    border: none;
  }
`;

SelectDateAndDelivery.propTypes = {
  date: PropTypes.string,
  address: PropTypes.string,
  handler: PropTypes.func,
  handlerCloseModal: PropTypes.func,
  availableDates: PropTypes.array,
  isLoadingDeliveryCost: PropTypes.bool,
  isLoadingDate: PropTypes.bool
};

export default SelectDateAndDelivery;
