import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from 'react-bootstrap'
import {HEADER} from "config/constants"
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons"
import styled from "@emotion/styled";
import ModalsContainer from "views/commons/Modals/ModalsContainer"
import CallFooterContainer from "views/commons/Blocks/CallFooter/CallFooterContainer"
import InfoDelivery from "./Partials/InfoDelivery"
import CartContainer from "views/commons/Cart/CartContainer"
import Connexion from "views/commons/Connexion/Connexion"
import NotificationContainer from "views/commons/Notification/NotificationContainer"
import { SwitchTransition, CSSTransition, Transition } from 'react-transition-group';
import Loader from "react-loader-spinner";

const Confirmation = (props) => {

  const {
    cart,
    handleSubmitOrder,
    customer,
    isLoading,
    cartIsChecking,
    modalMinAmount,
    closeModalMinAmount
  } = props

  let [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, [])

  const transitionToTheLeft = {
    entering: { opacity: 0, left: "-30px", transition: "500ms" },
    entered:  { opacity: 1, left: "0px", transition: "500ms" },
    exiting:  { opacity: 1, left: "0px", transition: "500ms" },
    exited:  { opacity: 0, left: "-30px", transition: "500ms" },
  };


  return (
    <Layout>

      {customer && customer.distributor &&
        <div className={"alert-danger text-center p-3 h6 mb-4"}>
          <p className="mb-0">
            <b>Compte revendeur</b>, votre <b>remise de -10%</b> sur les Supercookies et les <b>frais de livraison offerts</b> à partir de 300 € HT s'appliqueront automatiquement <b>après votre commande</b>.
          </p>
        </div>
      }

      {(cartIsChecking || isLoading.value) ?
        <Load className="align-items-center justify-content-center">
          <Col>
            <Loader type="TailSpin" color="#689D71" /><br/>
            {isLoading.type === "102" ?
                <p>Validation en cours de votre commande !</p>
              : isLoading.type === "101" ?
                <p>Génération en cours de votre devis !</p>
              :
                <p>Vérification de la fraîcheur de nos produits !</p>
            }

          </Col>
        </Load>
        :
        <>
          <Container>
            <Transition in={show} timeout={300}>
              {state => (
              <Row className="align-items-center" style={{...transitionToTheLeft[state]}}>
                <Back xs={12} sm={4}><Link to="/"><FontAwesomeIcon icon={faArrowAltCircleLeft} /> Modifier votre commande</Link></Back>
                <Logo xs={12} sm={4}>
                  <Link to="/"><img src={HEADER.logo.img} alt={HEADER.logo.name} /></Link>
                </Logo>
                <Col></Col>
              </Row>
              )}
            </Transition>
              <RowBtp>
                <Transition in={show} timeout={300}>
                  {state => (
                    <Col xs={12} sm={12} md={customer !== null ? 4 : { span: 5, offset: 1 }} lg={customer !== null ? 4 : { span: 4, offset: 2 }}  className={"mb-3"} style={{...transitionToTheLeft[state]}}>
                      <CartContainer cart={cart} type="mini" />
                    </Col>
                  )}
                </Transition>

                    <SwitchTransition>
                      <CSSTransition
                        key={
                          (show && customer === null) ?
                            "connect"
                          : (show && customer !== null) ?
                            "notconnect"
                          : "undefined"
                        }
                        timeout={400}
                        unmountOnExit
                        mountOnEnter
                        classNames="fade"
                      >
                      {(show && customer === null) ?
                          <Col xs={12} sm={12} md={5} lg={4} className={"mb-3"} >
                            <Card>
                              <Connexion />
                            </Card>
                          </Col>

                      : (show && customer !== null) ?
                          <Col xs={12} sm={12} md={7} lg={8} className={"mb-3"}>
                            <InfoDelivery handleSubmitOrder={handleSubmitOrder} deliveryInfo={cart.deliveryInfo} invoiceAddress={cart.invoiceAddress} customerInfo={cart.customerInfo} billingInfo={cart.billingInfo} customer={customer} isLoading={isLoading}
                            modalMinAmount={modalMinAmount}
                            closeModalMinAmount={closeModalMinAmount}
                            />
                          </Col>
                        :
                          <div></div>
                      }
                      </CSSTransition>
                    </SwitchTransition>

              </RowBtp>
          </Container>
          <Transition in={show} timeout={300}>
            {state => (
              <div style={{...transitionToTheLeft[state]}}>
                <CallFooterContainer />
              </div>
            )}
          </Transition>
          <ModalsContainer />
          <NotificationContainer />
        </>
      }
    </Layout>
  );
};

const Card = styled.div`
  padding: 1.0rem;
  background-color: white;
  border-radius: 6px;
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.02);
`;

const RowBtp = styled(Row)`
  margin-top: 3.0rem;
  margin-bottom: 3.0rem;
  @media (max-width: 767px){
    margin-top: 1rem;
    margin-bottom: 1.0rem;
  }
`

const Logo = styled(Col)`
  text-align:center;
  img{
    max-width: 160px;
  }
`;

const Back = styled(Col)`
  color: ${(props) => props.theme.colors.primary};
  font-family: '${props => props.theme.fonts.family.main}';
  font-size: ${props => props.theme.fonts.size.m};
  @media (max-width: 767px){
    text-align: center;
    margin-bottom: 1.0rem;
`;

const Layout = styled.div`
  padding: 2.0rem;
  @media (max-width: 767px){
   padding: 0.5rem 0rem;
  }
`;

const Load = styled(Row)`
  height: 100vh;
  text-align: center;
  font-size: ${props => props.theme.fonts.size.m};
  @media (max-width: 767px){
   font-size: ${props => props.theme.fonts.size.s};
  }
`;

Confirmation.propTypes = {
  cart: PropTypes.object,
  handleSubmitOrder: PropTypes.func,
  isLoading: PropTypes.object,
  cartIsChecking: PropTypes.bool,
  modalMinAmount: PropTypes.object,
  closeModalMinAmount: PropTypes.func
};

export default Confirmation;
