import React from "react";
import { Container, Row, Col, Button } from 'react-bootstrap'
import styled from "@emotion/styled";
import Layout from "views/commons/Layout/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ThanksOrder = (props) => {

  const {
    type,
    invoiceUrl
  } = props

  return (
    <Layout>
      <Container>
        <RowBtp>
            <Col xs={12} sm={12} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }} className={"mb-3"}>
              <Card>
                {type === 'commande' ?
                  <>
                    <Title>Merci ! </Title>
                    <p>
                      <b>Votre commande a bien été prise en compte.</b>
                    </p>

                    <p>
                    Vous pouvez y accéder dès maintenant depuis <Link to="/compte/devis"><u>votre compte client.</u></Link>
                    </p>

                    <p>
                      Vous recevrez également un email dans les prochaines minutes (merci de vérifier vos spams).
                    </p>

                    <p>
                      On a hâte de vous régaler!
                    </p>

                    <p>
                      Vous avez une question?<br/>
                      Sophie et Julie vous répondent au <a href="tel:0638133524"><u>06 38 13 35 24</u></a>
                    </p>
                    <p>
                      <i>A très vite !</i>
                    </p>
                  </>
                  :
                  <>
                    <Title>Votre Devis</Title>
                    <p><b>Votre devis est accessible depuis votre <Link to="/compte/devis"><u>compte client</u></Link>.</b></p>
                    <p>
                      Vous pouvez le valider jusqu'à la veille (avant midi) de votre prestation.
                    </p>
                    <p>
                    N'hésitez plus, on a hâte de vous régaler!
                    </p>
                  </>
                }

              </Card>
            </Col>
            {invoiceUrl &&
              <Col xs={12} sm={12} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }} className={"mb-3"}>
                <a href={invoiceUrl} target="_blank" rel="noreferrer">
                  <Action size="lg" variant="dark" type="submit">
                    <FontAwesomeIcon icon={faArrowRight} /> Télécharger mon devis
                    <br/><span>Vous pourrez modifier votre commande jusqu'à 48h avant votre évènement</span>
                  </Action>
                </a>
              </Col>
            }

        </RowBtp>
      </Container>
    </Layout>
  );
};


const Card = styled.div`
  padding: 2.0rem ;
  background-color: white;
  border-radius: 6px;
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.02);
  text-align: center;
  p{
    font-size: ${props => props.theme.fonts.size.xs};
    span{
      color: ${props => props.theme.colors.primary};
      cursor: pointer;
      transition: 500ms;
      &:hover{
        color: black;
      }
    }
  }
`;

const RowBtp = styled(Row)`
  margin-top: 3.0rem;
  margin-bottom: 3.0rem;
  @media (max-width: 767px){
    margin-top: 1rem;
    margin-bottom: 1.0rem;
  }
`
const Title = styled.h3`
  color: ${props => props.theme.colors.primary};
  font-family: '${props => props.theme.fonts.family.script}';
  font-size: ${props => props.theme.fonts.size.m};
`

const Action = styled(Button)`
  font-family: '${props => props.theme.fonts.family.main}';
  font-size: ${props => props.theme.fonts.size.s};
  color: ${props => props.theme.colors.black};
  width: 100%;
  text-align: left;
  padding: 1.0rem 1.0rem;
  svg{
    margin-right: 0.5rem;
  }
  span{
    text-transform: none;
    font-weight: normal;
  }
`;

ThanksOrder.propTypes = {
  type: PropTypes.string,
  invoiceUrl: PropTypes.string
};

export default ThanksOrder;
