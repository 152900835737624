import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

export default function withAuthentication( WrappedComponent ) {
    const WithAuthentication = ( props ) => {
        if ( !props.isAuthenticated ) {
            return <Redirect to="/connexion" />;
        }

        return ( <WrappedComponent { ...props } /> );
    };

    const { bool } = PropTypes;
    WithAuthentication.propTypes = {
        isAuthenticated: bool.isRequired,
    };

    const mapStateToProps = ( state ) => ( {
        isAuthenticated: state.api.customer.isAuthenticated,
    } );

    return connect( mapStateToProps )( WithAuthentication );
}
