import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { operations as customerOperations, selectors as customerSelectors } from "state/ducks/api/customer";
import Invoices from "views/pages/Account/Invoices/Invoices"
import queryString from 'query-string';
import { operations as modalsOperations } from 'state/ducks/client/modals'
import { useHistory } from "react-router-dom";

const InvoicesContainer = (props) => {

  const dispatch = useDispatch();
  const orders = useSelector(
    (state) => customerSelectors.getInvoices(state),
    shallowEqual
  );

  const isLoading = useSelector(
    (state) => customerSelectors.isLoading(state),
    shallowEqual
  );

  // Gestion retour payzen
  const payzen = queryString.parse(props.location.search).payzen;

  let history = useHistory();

  useEffect(() => {
    dispatch(customerOperations.getOrders());
    if(payzen === "success"){
      dispatch(modalsOperations.setModalStatusGeneric(true, "Merci pour votre règlement, votre facture est consultable depuis le compte client.", "Paiement effectué !"));
    }else if(payzen === "error"){
      dispatch(modalsOperations.setModalStatusGeneric(true, "Notre service a rencontré une erreur, merci de ressayer ou de contacter notre équipe au 06 38 13 35 24", "Oups..."));
    }else if(payzen === "refused"){
      dispatch(modalsOperations.setModalStatusGeneric(true, "Votre paiement a été refusé, merci de ressayer ou de contacter notre équipe au 06 38 13 35 24", "Oups..."));
    }
    history.push('/compte/factures');
  }, [dispatch, payzen, history]);

  // Système de pagination
  const perPage = 5;
  const [offset, setOffset] = useState(perPage);
  const pageCount = orders.length / perPage;

  const handlePageClick = (e) => {
      let selectedPage = e.selected;
      let offset = (selectedPage + 1) * perPage;
      setOffset(offset);
  };

  return (
    <Invoices invoices={orders} isLoading={isLoading} handlePageClick={handlePageClick} pageCount={pageCount} perPage={perPage} offset={offset} />
  );

};

export default InvoicesContainer;
