import axios from 'axios'
import oauth from 'axios-oauth-client'
import { fetch } from '../utils'
import {store} from "state/store";
import { operations as notificationsOperations } from 'state/ducks/api/notifications';
import { operations as customerOperations } from 'state/ducks/api/customer';

const boUrl        = process.env.REACT_APP_LEMOULIN_BO_URL;
const baseUrl      = process.env.REACT_APP_LEMOULIN_API_URL;
const apiKey       = process.env.REACT_APP_LEMOULIN_API_KEY;
const clientId     = process.env.REACT_APP_LEMOULIN_API_CLIENT_ID;
const clientSecret = process.env.REACT_APP_LEMOULIN_API_CLIENT_SECRET;

const apiService = ( ) => ( next ) => ( action ) => {

    const result = next( action );
    if ( !action.meta || !action.meta.async ) {
        return result;
    }

    // Juste pour l'authentification
    if (action.meta.auth) {
      const url = `${boUrl}/oauth/v2/token?apiKey=${apiKey}`;
      const body = action.meta.body;
      const getOwnerCredentials = oauth.client(axios.create(), {
        url: url,
        grant_type: 'password',
        client_id: clientId,
        client_secret: clientSecret,
        username: body.username,
        password: body.password,
        user_type: 'pro'
      })
      const credentials = getOwnerCredentials();
      credentials.then(
          res => {
            window.localStorage.setItem("token", res.access_token)
            handleResponse( {data: {
              result: res.access_token
            }}, action, next )
          },
          err => {
            handleErrors( err, action, next )
          },
      );
      return credentials;
    }
    else {
      const { path, method, body, params } = action.meta;
      const formatParams = new URLSearchParams(params);

      if ( !path ) {
          throw new Error( `'path' not specified for async action ${ action.type }` );
      }

      let url = '';
      if(params){
        url = `${ baseUrl }${ path }?apiKey=${ apiKey }&${formatParams}`;
      }else{
        url = `${ baseUrl }${ path }?apiKey=${ apiKey }`;
      }

      return fetch( url, method, body ).then(
          res => handleResponse( res, action, next ),
          err => handleErrors( err, action, next ),
      );
    }
};

export default apiService;

function handleErrors( err, action, next ) {
    let payload = {};
    if (action.payload) {
      payload = action.payload;
    }

    // Le token de connexion n'est plus valide, déconnecte l'utilisateur et recharge la page
    if (err?.response?.data?.code && err.response.data.code === 401) {
      store.dispatch(customerOperations.logout());
      document.location.reload();
      return;
    }

    payload.error = err;

    next( {
        type: `${ action.type }_FAILED`,
        payload: payload,
        meta: action.meta,
    } );

    if (err?.response?.data?.error === 'invalid_grant') {
      store.dispatch(notificationsOperations.setNotification({error:err?.response?.data?.error, message:"Mauvaise combinaison mail / mot de passe !", toDisplay:true}));
    }
    else if (err?.response?.data?.result && err.response.data.result.success === false) {
      store.dispatch(notificationsOperations.setNotification({error:err?.response?.data?.result?.message, message:err?.response?.data?.result?.message, toDisplay:true}));
    }
    else {
      store.dispatch(notificationsOperations.setNotification({error:err?.response?.data?.error, message:err?.response?.data?.message, toDisplay:true}));
    }

    return Promise.reject( err );
}

function handleResponse( res, action, next ) {
    let payload = {};
    if (action.payload) {
      payload = action.payload;
    }
    payload.result = res.data.result;

    next( {
        type: `${ action.type }_COMPLETED`,
        payload: payload,
        meta: action.meta,
    } );
    return res;
}
