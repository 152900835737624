import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import SignUp from 'views/commons/Connexion/Tabs/SignUp'
import { selectors as customerSelectors, operations as customerOperations } from "state/ducks/api/customer";

const SignUpContainer = (props) => {

  const dispatch = useDispatch();

  const isLoading = useSelector(
    (state) => customerSelectors.isLoading(state),
    shallowEqual
  );

  const onSubmit = (values) => {
    dispatch(customerOperations.createCustomer({customer:values})).then((success) => {
      dispatch(customerOperations.login(values.email, values.plainPassword.first));
    })
  }

  return(
    <SignUp onSubmit={onSubmit} isLoading={isLoading}/>
  )
}

export default SignUpContainer;
