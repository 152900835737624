import React from "react";
import AttributProduct from "./AttributProduct"
import PropTypes from "prop-types";

const AttributProductContainer = (props) => {

  const {
    characteristics
  } = props

  return (
    <AttributProduct characteristics={characteristics}/>
  );

};

AttributProductContainer.propTypes = {
  characteristics: PropTypes.array,
};

export default AttributProductContainer;
