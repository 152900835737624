import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "@emotion/styled";
import { faShoppingBag } from "@fortawesome/free-solid-svg-icons"
import { Col } from 'react-bootstrap'
import PropTypes from "prop-types";
import Button from "styles/Button"
import Select from 'react-select'
import chroma from 'chroma-js';
import {OPTIONS} from "config/constants"

const QuantityItem = (props) => {

  const {
    quantity,
    handler,
    colorRange,
    isDisable
  } = props


  const dot = (color = colorRange) => ({
    alignItems: 'center',
    display: 'flex',
    fontSize: '1.0rem',
    color: color,
    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(colorRange ? colorRange : 'grey');
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? colorRange
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : colorRange,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? colorRange : color.alpha(0.3).css()),
      },
    };
  },
    input: styles => ({ ...styles, ...dot() }),
    placeholder: styles => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(colorRange) }),
  };

  return (
    quantity === 0 ?
      <Quantity>
        <Button variant="outline" onClick={() => handler('add')} disabled={isDisable} color={colorRange} ><FontAwesomeIcon icon={faShoppingBag} /> Ajouter</Button>
      </Quantity>
    :
      <>
        <Quantity>

            <Select
              isDisabled={isDisable}
              options={OPTIONS}
              value={ OPTIONS[quantity] }
              onChange={(value) => (value.value !== OPTIONS[quantity].value) && handler('change', value)}
              styles={colourStyles}
            />

        </Quantity>
      </>

  )
}


const Quantity = styled(Col)`
  text-align: right;
  @media (max-width: 767px){
    text-align: center;
  }
  padding: 0rem;
  p{
    margin-bottom: 0rem;
  }
`

QuantityItem.propTypes = {
  quantity: PropTypes.number,
  handler: PropTypes.func,
  able: PropTypes.bool,
  color: PropTypes.string,
  isDisable: PropTypes.bool,
};


export default QuantityItem;
