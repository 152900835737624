import React from "react";
import PropTypes from "prop-types";
import styled from '@emotion/styled'

const Options = (props) => {

  const {
    options,
    handleChangeOption,
    type
  } = props

  return (
      <Container>
        {options.map((option, index) =>
          option.active &&
            <Option key={index}>
              <Title>
                <Switch>
                  <input type="checkbox" checked={option.value && "checked"} onChange={() => handleChangeOption(option.id, option.value)}/>
                  <div className="slider"></div>
                </Switch>
                {option.name}
                <Price>
                  {option.price > 0 && " / "+(option.price / 100).toFixed(2)+" €"}
                </Price>
              </Title>
              {type !== "mini" &&
                <Descr>{option.description}</Descr>
              }
            </Option>
        )}
      </Container>
  );

};

const Container = styled.div`
  background-color: white;
  border-radius: 4px 0 0 4px;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
`

const Price = styled.span`
  font-family: '${props => props.theme.fonts.family.main}';
  font-size: ${props => props.theme.fonts.size.s};
  color: ${props => props.theme.colors.primary};
`

const Title = styled.div`
  font-family: '${props => props.theme.fonts.family.main}';
  font-size: ${props => props.theme.fonts.size.m};
  color: ${props => props.theme.colors.inherit};
`

const Descr = styled.div`
  font-size: ${props => props.theme.fonts.size.xxs};
  color: ${props => props.theme.colors.grey};
  p{
    margin-bottom: 0rem;
  }
  @media (max-width: 640px){
    display: none;
  }
`
const Option = styled.div`
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ccc;
  position: relative;
  &:last-child{
    border-bottom: none;
    margin-bottom: 0rem;
    padding-bottom: 0rem;
  }
`

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  outline: none;
  margin-bottom: 0;
  margin-right: 5px;
  input {
    position: absolute;
    top: -99999px;
    left: -99999px;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  input:checked + .slider {
    background-color: ${props => props.theme.colors.darkGreen};
  }
  input:focus + .slider {
    box-shadow: 0 0 1px ${props => props.theme.colors.darkGreen};
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }
`

Options.propTypes = {
  option: PropTypes.array,
  handleChangeOption: PropTypes.func,
  type: PropTypes.string
};

export default Options;
