import * as actionTypes from './types'

export const getRanges = () => ({
  type: actionTypes.GET_PRODUCT_RANGES,
  meta: {
    async: true,
    blocking: true,
    path: `/v3/pro/offer/ranges.json`,
    method: "GET"
  },
  payload: {
  },
});

export const setCurrentRangeSlug = ( slug ) => ( {
    type: actionTypes.SET_CURRENT_PRODUCT_RANGES_SLUG,
    payload: {
        slug
    },
} );

export const getRangesDates = () => ({
  type: actionTypes.GET_RANGES_DATES,
  meta: {
    async: true,
    blocking: true,
    path: `/v3/pro/offer/ranges/dates.json`,
    method: "GET"
  },
  payload: {
  },
});
