import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import styled from '@emotion/styled'

const NoCart = (props) => {

  const {
    handleStateCartClose
  } = props;

  return(
    <Style noGutters>
      <Col xs={12}>
        <MapMarker>
          <Title>VOTRE PANIER EST VIDE</Title>
        </MapMarker>
        <Action>
          Découvrez nos créations culinaires maison, vos papilles vont en redemander 😉<br/>
          <Button onClick={() => handleStateCartClose()} className="mt-4">Découvrir</Button>
        </Action>
      </Col>
    </Style>
  )
}

const Style = styled(Row)`
  text-align: center;
  padding: 1.0rem;
  font-size: ${props => props.theme.fonts.size.m};
  height: 100vh;
  p{
    line-height: 1.1;
  }
  div{
    display: flex;
    webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`;

const Title = styled.p`
  font-family: '${props => props.theme.fonts.family.main}'
`

const MapMarker = styled(Row)`
  color: ${props => props.theme.colors.inherit};
  font-size: 2.5rem;
  margin-bottom: 1.0rem;
  p{
    font-size: ${props => props.theme.fonts.size.xl};
    text-transform: uppercase;
    margin-top: 1.0rem;
    font-family: '${props => props.theme.fonts.family.main}'
    font-weight: bold;
  }
`;

const Action = styled.p`
  text-align: center;
  font-size: ${props => props.theme.fonts.size.sm};
  button{
    padding: 1rem;
  }
`;

export default NoCart;
