import { createStore, applyMiddleware, compose, combineReducers  } from 'redux'
import thunkMiddleware from 'redux-thunk'
import apiReducer from './ducks/api'
import clientReducer from './ducks/client'
import { apiService, createLogger } from './middlewares'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

    const persistConfig = {
      key: 'root',
      storage,
      blacklist: ['api', 'client'],
      stateReconciler: autoMergeLevel2
    }

    const apiPersistConfig = {
      key: 'api',
      storage: storage,
      blacklist: ['productRange', 'deliveryCost', 'business']
    }

    const clientPersistConfig = {
      key: 'client',
      storage: storage,
      blacklist: ['modals']
    }

    const reducers = combineReducers({
      api: persistReducer(apiPersistConfig, apiReducer),
      client: persistReducer(clientPersistConfig, clientReducer)
    })

    const persistedReducer = persistReducer(persistConfig, reducers);

    const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    export const store = createStore(
        persistedReducer,
        storeEnhancers(applyMiddleware(
            thunkMiddleware,
            apiService,
            createLogger( true ),
        )),
    );

    export const persistor = persistStore(store);
