import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { selectors as rangeSelectors } from "state/ducks/api/productRange";
import { operations as cartOperations, selectors as cartSelectors } from "state/ducks/client/cart";

import MenuRanges from "./MenuRanges"

// Menu de choix de la gamme de produit
const MenuRangeContainer = (props) => {

  const{
    noDateAndAddress
  } = props;

  const dispatch = useDispatch();

  const cart = useSelector(
    (state) => cartSelectors.getCart(state),
    shallowEqual
  );

  const ranges = useSelector(
    (state) => rangeSelectors.getRanges(state),
    shallowEqual
  );

  const handleCartClick = () => dispatch(cartOperations.setIsOpen(!cart.isOpen));

  return (
    <MenuRanges ranges={ranges} cart={cart} handleCartClick={handleCartClick} noDateAndAddress={noDateAndAddress}/>
  );

};


MenuRangeContainer.propTypes = {
  noDateAndAddress: PropTypes.bool
};

export default MenuRangeContainer;
