import { injectGlobal } from "@emotion/css";
import { theme } from "./Theme.js";


const Global = (props) => {};

injectGlobal`
  body{
    background-color: #F2F2F2 !important;
    font-family: '${theme.fonts.family.body}';
    font-size: ${theme.fonts.size.s};

  }
  p{
    font-family: '${theme.fonts.family.body}';
  }
  a{
    cursor: pointer;
    transition: 300ms;
    color: ${theme.colors.primary};
    :hover{
      color: #111;
    }
  }
  .btn{
    font-family: '${theme.fonts.family.main}';
    font-weight: bold;
    text-transform: uppercase;
    @media (max-width: 767px){
      font-size: ${theme.fonts.size.xs};
    }
  }
  .btn-primary {
    background-color: ${theme.colors.primary};
    border: ${theme.colors.primary};
    color: white;
    transition: 300ms;
    &:hover, &:focus, &:active, &.active{
      background-color: ${theme.colors.primary} !important;
      color: white !important;
      opacity: 0.8;
    }
    &:disabled, &.disabled{
      opacity : 0.5;
      background-color: ${theme.colors.primary};
      border: ${theme.colors.primary};
      color: white;
      &:hover{
        opacity: 0.5;
      }
    }
  }
  .btn-outline-primary {
    border: ${theme.colors.primary} solid 1px;
    color: ${theme.colors.primary};
    transition: 300ms;
    &:hover, &:focus, &:active{
      background-color: ${theme.colors.primary};
      border: ${theme.colors.primary} solid 1px;
      color: white;
    }
    &:disabled, &.disabled{
      opacity : 0.5;
      border: ${theme.colors.primary} solid 1px;
      color: ${theme.colors.primary};
      &:hover{
        opacity: 0.5 !important;
      }
    }
  }

  .btn-secondary {
    background-color: ${theme.colors.secondary};
    border: ${theme.colors.secondary};
    color: white;
    transition: 300ms;
  }
  .btn-outline-secondary {
    border: ${theme.colors.secondary} solid 1px;
    color: ${theme.colors.secondary};
    transition: 300ms;
    &:hover, &:focus, &:active{
      background-color: ${theme.colors.secondary};
      border: ${theme.colors.secondary} solid 1px;
      color: white;
    }
  }

  // Burger menu / Cart
  /* Position and sizing of burger button */
  #menu-nav{
    max-width: 100%;
    width: 300px !important;
    .bm-burger-button {
      position: fixed;
      width: 36px;
      height: 30px;
      left: 36px;
      top: 36px;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
      background: #373a47;
    }

    /* Color/shape of burger icon bars on hover*/
    .bm-burger-bars-hover {
      background: #a90000;
    }

    /* Position and sizing of clickable cross button */
    .bm-cross-button {
      height: 24px;
      width: 24px;
    }

    /* Color/shape of close button cross */
    .bm-cross {
      background: #bdc3c7;
    }

    .bm-menu-wrap {
      position: fixed;
      height: 100%;
      z-index: 9998 !important;
    }

    /* General sidebar styles */
    .bm-menu {
      background: #EEEEEE;
      padding: 0;
      font-size: 1.15em;
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
      fill: #373a47;
    }

    /* Wrapper for item list */
    .bm-item-list {
      color: #b8b7ad;
      padding: 0;
    }

    /* Individual item */
    .bm-item {
      display: inline-block;
    }
    /* Styling of overlay */
    .bm-overlay {
      background: rgba(0, 0, 0, 0.3);
      z-index: 9998 !important;
    }
  }
  #cart{
    width: 420px !important;
    max-width: 100%;
    .bm-burger-button {
      position: fixed;
      width: 36px;
      height: 30px;
      left: 36px;
      top: 36px;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
      background: #373a47;
    }

    /* Color/shape of burger icon bars on hover*/
    .bm-burger-bars-hover {
      background: #a90000;
    }

    /* Position and sizing of clickable cross button */
    .bm-cross-button {
      height: 24px;
      width: 24px;
    }

    /* Color/shape of close button cross */
    .bm-cross {
      background: #bdc3c7;
    }

    .bm-menu-wrap {
      position: fixed;
      height: 100%;
      z-index: 9998 !important;
    }

    /* General sidebar styles */
    .bm-menu {
      background: #EEEEEE;
      padding: 0;
      font-size: 1.15em;
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
      fill: #373a47;
    }

    /* Wrapper for item list */
    .bm-item-list {
      color: #b8b7ad;
      padding: 0;
    }

    /* Individual item */
    .bm-item {
      display: inline-block;
    }
    /* Styling of overlay */
    .bm-overlay {
      background: rgba(0, 0, 0, 0.3);
      z-index: 9998 !important;
    }
  }

  @media (min-width: 1200px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1320px;
    }
  }

  .block-loader{
    padding: 0.5rem;
    max-width: 100%;
    background-color: white;
    margin-bottom: 1.0rem;
  }
  .padding-loader{
    @media (max-width: 767px){
      padding-right: 5px;
      padding-left: 5px;
    }
  }
  .form-control {
    font-size: 0.9rem;
  }

  .tippy-box {
    position: relative;
    background-color: #333;
    padding: 0.8rem;
    color: #fff;
    border-radius: 4px;
    line-height: 1.4;
    outline: 0;
    transition-property: transform,visibility,opacity;
}

  .dropdown-menu{
    padding: 0 0;
  }
  .dropdown-item{
    padding: 0.7rem 1.0rem;
    text-transform: none;
    border-bottom: 1px solid #689D71;
    font-family: '${theme.fonts.family.body}' !important;
    font-size: ${theme.fonts.size.xs} !important;
    &:hover{
      background-color: #689D71;
      color: white !important;
    }
  }
  .fade-enter{
   opacity: 0;
   right: -30px;
   transition: 500ms;
  }
  .fade-exit{
     opacity: 1;
  }
  .fade-enter-done{
     opacity: 1;
     right: 0px;
     transition: 500ms;
  }
  .fade-exit-active{
     opacity: 0;
  }
  .fade-enter-active,
  .fade-exit-active{
     transition: opacity 500ms;
  }

  .pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
  }
  .pagination > .active > a{
    background-color: ${theme.colors.primary} ;
    border-color: ${theme.colors.primary} ;
    color: #fff;
  }
  .pagination > li > a{
    border: none;
    background-color: white;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
  }
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: ${theme.colors.primary} ;
    border-color: ${theme.colors.primary};
    outline: none ;
  }
  .pagination > li > a, .pagination > li > span{
    color: ${theme.colors.primary}
  }
  .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset
  }
`;

export default Global;
