import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { SOURCE_URI } from "config/constants"
import * as utils from "utils/utils"
import Select from 'react-select'
import {OPTIONS} from "config/constants"

const Article = (props) => {

  const {
    article,
    range,
    handleChangeArticleQuantity,
    isDisable
  } = props

  const [showMore, setShowMore] = useState(false);

  return(
    <Container>
      <Row noGutters className="align-items-center">
        <Image>
          <img src={article?.mediaUris ? `${SOURCE_URI}/${article.mediaUris.normal}` : null } alt={article.name} />
        </Image>
        <Info xs={6}>
          <Name pointer={article.products && true} onClick={() => article.products ? setShowMore(!showMore) : null }>{article.products && <FontAwesomeIcon icon={faAngleDown}/>} {article.name}</Name>
        </Info>
        <Quantity>
            <Select
              isDisabled={isDisable}
              options={OPTIONS}
              value={ OPTIONS[article.quantity] }
              onChange={(value) => (value.value !== OPTIONS[article.quantity].value) && handleChangeArticleQuantity(range, article, value.value)}
            />
        </Quantity>
        <Price>
          {article.price > 0 ? utils.centToEuros(article.quantity * article.price) : "0 €"}
        </Price>
      </Row>
      {showMore &&
        article.products &&
          <Row noGutters>
            <More>
              {article.products.map((product, index) =>
                <p key={index}>
                  <span>{product.quantity > 1 && "x"+product.quantity}</span> {product.name}
                </p>
              )}
            </More>
          </Row>
      }
    </Container>
  )
}

const Container = styled.div`
  border-bottom: solid 1px #ccc;
`

const Image = styled(Col)`
  max-width: 70px;
  img{
    max-width: 100%;
  }
`

const Info = styled(Col)`
  padding: 0.5rem !important;
`

const More = styled(Col)`

  padding: 0.5rem !important;
  p{
    font-family: '${props => props.theme.fonts.family.main}';
    font-size: ${props => props.theme.fonts.size.s};
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: ${props => props.theme.colors.inherit};
    span{
      color: ${props => props.theme.colors.primary};
    }
  }
`

const Name = styled.p`
  font-family: '${props => props.theme.fonts.family.main}';
  font-size: ${props => props.theme.fonts.size.sm};
  margin-bottom: 0;
  font-weight: bold;
  color: ${props => props.theme.colors.inherit};
  svg{
    color: ${props => props.theme.colors.primary};
  }
  cursor: ${props => props.pointer ? "pointer" : "inherit" };
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
`
const Quantity = styled(Col)`
  p{
    margin-bottom: 0rem;
  }
`

const Price = styled(Col)`
  text-align: right;
  font-size: ${props => props.theme.fonts.size.s};
  color: ${props => props.theme.colors.primary};
  font-family: '${props => props.theme.fonts.family.main}';
  font-weight: bold;
`

Article.propTypes = {
  article: PropTypes.object,
  productRange: PropTypes.number,
  handleChangeArticleQuantity: PropTypes.func,
  isDisable: PropTypes.bool,
};

export default Article;
