import logo from "assets/images/logo.svg"
import noVisualMealtray from "assets/images/product-range-other.jpg"

import customer1 from "assets/images/clients/metropole-grand-lyon.jpg"
import customer2 from "assets/images/clients/gl-event.jpg"
import customer3 from "assets/images/clients/sncf.jpg"
import customer4 from "assets/images/clients/sport-dans-la-ville.jpg"
import customer5 from "assets/images/clients/maison-environnement.jpg"
import customer6 from "assets/images/clients/seb.jpg"
import customer7 from "assets/images/clients/conges-intemperies-btp.jpg"
import customer8 from "assets/images/clients/cyclable.jpg"
import customer9 from "assets/images/clients/vitalaire.jpg"
import customer10 from "assets/images/clients/ronalpia.jpg"
import customer11 from "assets/images/clients/grdf.jpg"
import customer12 from "assets/images/clients/mbway.jpg"

let options = []
Array.from(Array(100)).map((_, i) => {
  return options = [...options, { value: i, label: i }];
});

export let OPTIONS = options;
export const SOURCE_URI = process.env.REACT_APP_LEMOULIN_BO_URL
export const HOME_URL = process.env.PUBLIC_URL
export const HEADER = {
  logo: {
    name: "Offre professionnelle",
    img: logo
  },
  leftNav: [
    {
      name: "Plateaux-repas",
      url: "/"
    },
  ]
}

export const CALL_FOOTER ={
  name: "Besoin d'un conseil ?",
  sub: "Sophie & Julie vous rappellent pour organiser votre évènement.",
  button: '06 38 13 35 24'
}
export const MEALTRAY_OTHER ={
  group: {
    name: "… et bien d'autres",
    description: "Des jus bio, des thermos de thé et café, du vin des environs ...",
    media: { square: noVisualMealtray},
    fake: true
  }
}
export const PARTNERS_LOGOS = [
  {
    name: "Métropole Grand Lyon",
    img: customer1
  },
  {
    name: "GL Event",
    img: customer2
  },
  {
    name: "Sncf",
    img: customer3
  },
  {
    name: "Sport dans la ville",
    img: customer4
  },
  {
    name: "Maison de l'environnement",
    img: customer5
  },
  {
    name: "Seb",
    img: customer6
  },
  {
    name: "Congés Intemperies BTP",
    img: customer7
  },
  {
    name: "Cyclable",
    img: customer8
  },
  {
    name: "Vitalaire",
    img: customer9
  },
  {
    name: "Ronalpia",
    img: customer10
  },
  {
    name: "GRDF",
    img: customer11
  },
  {
    name: "MB way",
    img: customer12
  }
]
export const REVIEWS = [
  {
    title: "Plateau repas festif très très bon, au top.",
    description: "Ravis de nos plateaux-repas sans plastique, avec des bons produits.",
    author: "FLORETTE - décembre 2021"
  },
  {
    title: "L’ensemble des participants a souligné la qualité du buffet",
    description: "Je tenais à vous remercier de la part de toute l’équipe PERLE du FOYER pour la qualité et la fluidité de nos échanges. L’ensemble des participants a souligné la qualité du buffet. Nous n’hésiterons pas à faire appel à vous pour nos prochains évènements.",
    author: "Dispositif PERLE chez le Foyer Notre Dames des sans-abris, Ombeline - novembre 2021"
  },
  {
    title: "Je vous remercie pour la qualité de la prestation dont les retours ont été très élogieux",
    description: "Je profite de ce mail pour remercier vos services, tant la partie commerciale pour l'élaboration du devis et la personne présente sur place lors de notre évènement, pour leurs gentillesses et professionnalisme. Il en va de même pour la qualité de la prestation, dont les retours que nous avons eu ont été très élogieux. Bien cordialement",
    author: "Ecole des Mines, Céline - octobre 2021"
  },
  {
    title: "Nous nous sommes régalés et tous nos convives ont apprécié la qualité du buffet",
    description: "Je viens d'échanger avec l'une de vos collègues pour vous partager notre entière satisfaction sur le buffet que vous nous avez proposé hier chez Dimo. Nous nous sommes régalés et tous nos convives ont apprécié la qualité du buffet (produits bio et du terroir) en toute simplicité. Nous n'hésiterons pas à vous recommander et à vous solliciter de nouveau. Bonne semaine et merci.",
    author: "60 000 rebonds, Christine - juin 2021"
  },
  {
    title: "Les prestations ont été très appréciées",
    description: "Les prestations ont été très appréciées. Je vous remercie aussi pour votre professionnalisme et votre efficacité.",
    author: "Université de Lyon, Aliénor - novembre 2021"
  },
  {
    title: "Nous avons été très contents de la prestation",
    description: "C'était très bien, nous avons été très contents de la prestation, les participants nous ont même fait des retours très positifs sur le déjeuner dans leur questionnaire de satisfaction !",
    author: "GRAIE, Léane - novembre 2021"
  },
  {
    title: "Nous avons été très contents des plateaux repas",
    description: "Nous avons été très contents des plateaux repas, particulièrement la personne qui avait commandé un plateau spécial sans lactose",
    author: "CS GROUP, Véronique - octobre 2021"
  },
  {
    title: "Super équipe, super produits !",
    description: "Super équipe, super produits ! Je commande régulièrement des plateaux repas pour le boulot : service impeccable !",
    author: "Atelier Emmaüs, Guillaume - septembre 2021"
  },
  {
    title: "La qualité et la fraicheur des produits est toujours présentes",
    description: "Nous faisons régulièrement appel aux services du Moulin (évènements ou réunions), la qualité et la fraîcheur des produits est toujours présentes, la réactivité des équipes également (commande ; livraison ; service). Un super partenaire !",
    author: "Ronalpia, Enora - octobre 2021"
  },
  {
    title: "Les plateaux repas ont fait l’unanimité",
    description: "Nous sommes très satisfaits de la prestation apportée par Le Moulin Traiteur. Les plateaux repas ont fait l'unanimité auprès de nos invités. Expérience à renouveler. Encore merci. Je vous recommande ce traiteur les yeux fermés !",
    author: "SNCF, Margot - septembre 2021"
  },
]
