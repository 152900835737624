import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import Category from "./Category"
import PropTypes from "prop-types";
import { selectors as cartSelectors } from "state/ducks/client/cart";

const CategoryContainer = (props) => {

  const {
    category,
    index,
    color,
    banners
  } = props

  const deliveryDate = useSelector(
    (state) => cartSelectors.getDeliveryDate(state),
    shallowEqual
  );

  return (
    <Category
      index={index}
      category={category}
      color={color}
      banners={banners}
      deliveryDate={deliveryDate}
    />
  );

};

CategoryContainer.propTypes = {
  index: PropTypes.number,
  category: PropTypes.object,
  color: PropTypes.string,
  banners: PropTypes.array
};

export default CategoryContainer;
