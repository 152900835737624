import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import SidebarCart from "./SidebarCart"
import MiniCart from "./MiniCart"
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { operations as cartOperations, selectors as cartSelectors } from "state/ducks/client/cart";
import { selectors as modalsSelectors } from "state/ducks/client/modals";
import * as modalsActions from 'state/ducks/client/modals/actions'
import { selectors as cartApiSelectors } from "state/ducks/api/cart";

// Logique du panier
const CartContainer = (props) => {

  const {
    cart,
    type
  } = props

  let history = useHistory();
  const dispatch = useDispatch();

  const frontCart = useSelector(
    (state) => cartSelectors.getCart(state),
    shallowEqual
  );

  const silverwares = useSelector(
    (state) => cartSelectors.getSilverwareInfo(state),
    shallowEqual
  );

  const modalMinAmount = useSelector(
    (state) => modalsSelectors.getModalMinAmount(state),
    shallowEqual
  );

  const swPriceHt = useSelector(
    (state) => cartSelectors.getSilverwaresTotalHt(state),
    shallowEqual
  );

  const closeModalMinAmount = () => {
    dispatch(modalsActions.setModalStatusMinAmount(false));
  }

  const isLoading = useSelector(
    (state) => cartApiSelectors.getIsLoading(state),
    shallowEqual
  );

  // Ouverture fermeture du panier
  const handleStateCartChange = (state) => {
    dispatch(cartOperations.setIsOpen(state.isOpen))
  }

  // Ouverture fermeture du panier
  const handleStateCartClose = () => {
    dispatch(cartOperations.setIsOpen(false))
  }

  // Changement de quantité d'un article depuis le panier + GTM
  const handleChangeArticleQuantity = (range, article, quantity) => {

    dispatch(cartOperations.changeQuantityArticle(article, range, quantity)).then((response) => {
      let eventName, qty;
      if (quantity > article.quantity ) {
        eventName = 'addToCart';
        qty = quantity - article.quantity;
      }
      else if (quantity < article.quantity) {
        eventName = 'removeFromCart';
        qty = article.quantity - quantity;
      }
      else {
        return;
      }
      window.dataLayer.push({
        event : eventName,
        ecommerce: {
          add: {
            actionField: {
              list: 'CartContainer'
            }
          },
          products:[{
            name    :article.name,
            id      :article.genericId,
            price   :article.price / 100,
            category:article.categoryName,
            brand   :'Le Moulin B2B',
            variant : range.name,
            quantity: qty
          }]
        }
      });
    });
  }

  // Changement d'une option
  const handleChangeOption = (optionId, optionValue) => {

  }

  const handleChangeSilverwares = (silverwareId, value) => {
    dispatch(cartOperations.changeSilverwareValue(silverwareId, value));
  }

  // Validation d'un panier + GTM checkout
  const handleValideCart = () => {
    dispatch(cartOperations.confirmCart()).then((response) => {
      if (response === true) {
        handleStateCartClose();
        if (cart?.productRanges[0]?.articles && cart?.productRanges[0]?.articles.length > 0) {
          // Produits pour GTM
          let gtmProducts = [];
          cart.productRanges.forEach((productRange, index) => {
            productRange.articles.forEach((article, index) => {
              return gtmProducts = [...gtmProducts, {
                name    :article.name,
                id      :article.genericId,
                price   :article.price / 100,
                category:article.categoryName,
                brand   :'Le Moulin B2B',
                quantity:article.quantity,
                variant: productRange.name
              }]
            })
          })

          window.dataLayer.push({
            event : 'checkout',
            ecommerce: {
              checkout: {
                actionField : {
                  step:1
                }
              },
              products: gtmProducts
            }
          });
        }
        history.push("/confirmation");
      }
    });
  }

  return (
    type === "mini" ?
      <MiniCart
        cart={cart}
        silverwares={silverwares}
        handleChangeArticleQuantity={handleChangeArticleQuantity}
        handleChangeOption={handleChangeOption}
        handleChangeSilverwares={handleChangeSilverwares}
        type={type}
        isLoading={isLoading}
        swPriceHt={swPriceHt}
      />
    :
    <SidebarCart
      isOpen={frontCart.isOpen}
      handleStateCartChange={handleStateCartChange}
      handleStateCartClose={handleStateCartClose}
      cart={cart}
      silverwares={silverwares}
      handleValideCart={handleValideCart}
      handleChangeArticleQuantity={handleChangeArticleQuantity}
      handleChangeOption={handleChangeOption}
      handleChangeSilverwares={handleChangeSilverwares}
      type={type}
      modalMinAmount={modalMinAmount}
      isLoading={isLoading}
      closeModalMinAmount={closeModalMinAmount}
      swPriceHt={swPriceHt}
    />
  );

};

CartContainer.propTypes = {
  cart: PropTypes.object,
  type: PropTypes.string
};

export default CartContainer;
