import * as actionTypes from './types'
// eslint-disable-next-line
import { dataProductRange } from './fakeData.js'
import { createReducer } from '../../../utils'

const initialState = {
  isInit: false,
  isLoading: false,
  data: null,
  currentRangeSlug: null,
  dates:null,
  isInitDate: false,
  isLoadingDate: false
};

const reducers = createReducer( initialState )( {
  [actionTypes.GET_PRODUCT_RANGES]: (state, action) => {
    return { ...state, isLoading: true };
  },
  [actionTypes.GET_PRODUCT_RANGES_COMPLETED]: (state, action) => {
    return { ...state,
      data: action.payload.result,
      isLoading: false,
      isInit: true
    };
  },
  [actionTypes.GET_PRODUCT_RANGES_FAILED]: (state, action) => {
    return { ...state, isLoading: false, isInit: true };
  },

  [actionTypes.SET_CURRENT_PRODUCT_RANGES_SLUG]: (state, action) => {
    return { ...state, currentRangeSlug: action.payload.slug };
  },

  [actionTypes.GET_RANGES_DATES]: (state, action) => {
    return { ...state, isLoading: true, isLoadingDate: true };
  },
  [actionTypes.GET_RANGES_DATES_COMPLETED]: (state, action) => {
    return { ...state,
      dates: action.payload.result,
      isLoading: false,
      isLoadingDate: false,
      isInitDate: true
    };
  },
  [actionTypes.GET_RANGES_DATES_FAILED]: (state, action) => {
    return { ...state, isLoading: false, isLoadingDate: false, isInitDate: true };
  },
});

export default reducers;
