import React from "react";
import {Button, Modal as ModalBtsp} from 'react-bootstrap'
import styled from '@emotion/styled'
import ItemDetailContainer from "views/commons/ItemDetail/ItemDetailContainer"
import QuantityItemContainer from "views/commons/QuantityItem/QuantityItemContainer"
import PropTypes from "prop-types";

const ModalItemDetail = (props) => {

  const {
    item,
    isOpen,
    onClose,
    range,
    color,
    article,
    able,
    categoryName
  } = props

  return (
    <Popup
      show={isOpen}
      onHide={onClose}
      size="lg"
      onEscapeKeyDown={onClose}
    >
        <CloseButton color={color} onClick={onClose} className='text-right overflow-auto'>
          <Button className='btn-success rounded-0'>X</Button>
        </CloseButton>
        <ItemDetailContainer item={item} color={color}/>
        <ModalBtsp.Footer>
          <Quantity>
            <QuantityItemContainer item={item} range={range} color={color} article={article} able={able} onClose={onClose} categoryName={categoryName}/>
          </Quantity>
        </ModalBtsp.Footer>
    </Popup>
  );

};

const Popup = styled(ModalBtsp)`
  font-size: ${props => props.theme.fonts.size.xs};
  height: auto;
  max-height: 90%;
  .modal-dialog{
    border: none;
    border-radius: none;
  }
  .modal-content{
    border: none;
    padding: 2% 2%;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  z-index: 9998;
  right: 0;
  top: 0;
  button{
    background-color: ${props => props.color && props.color};
  }
`;

const Quantity = styled.div`
  text-align: right;
  max-width: 150px;
  width: 130px;
  @media (max-width: 767px){
    max-width: 100%;
    width: 100%;
  }
`;

ModalItemDetail.propTypes = {
  item: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  article: PropTypes.object,
  able: PropTypes.bool,
  categoryName: PropTypes.string
};

export default ModalItemDetail;
