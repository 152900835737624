import * as actionTypes from './types'

export const getDeliveryCosts = () => ({
  type: actionTypes.GET_DELIVERY_COSTS,
  meta: {
    async: true,
    blocking: true,
    path: `/v3/pro/offer/deliverycosts.json`,
    method: "GET"
  },
  payload: {
  },
});
